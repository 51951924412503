import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "swiper/css";


import { useDispatch, useSelector } from "react-redux";
import { getAllServicesApi } from "../../../store/Slicers/CServices/getAllServicesSlicer";
import CustomGrowSpinner from "../Ui/CustomGrowSpinner";
import IMG from "../Ui/IMG";
import { deleteServiceByIdApi } from "../../../store/Slicers/CServices/deteteServicesSlicer";
import swal from "sweetalert";


function DisplayServices() {
  const { loading, error } = useSelector((state) => state.getAllServicesSlicer);
  const { loading:deleteLoading, error:deleteError } = useSelector((state) => state.deteteServicesSlicer);
  const dispatch = useDispatch();
  const [services, setServices] = useState([]);

  const getAllServices = () => {
    dispatch(getAllServicesApi()).then((res) => {
      if (res.payload.message === "Data Fetched successfully") {
        setServices(res.payload.data);
      }
    });
  };

  const deteteService = (serviceId)=>{
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover data again",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        dispatch(deleteServiceByIdApi(serviceId)).then((res) => {
          if (res.payload.message === "Data Deleted successfully") {
            swal("Good job!", res.payload.message, "success");
            getAllServices();
          }else{
            swal("Error!", deleteError, "error");
          }
        });
      } else {
        swal("The user data is safe!");
      }
    });
  
  }
  useEffect(() => {
    //get All Services
    getAllServices();
  }, []);

  return (
    <>
      {loading ? (
        <>
          <div className="col-12 d-flex justify-content-center gap-3">
            <CustomGrowSpinner />
            <CustomGrowSpinner />
            <CustomGrowSpinner />
          </div>
        </>
      ) : (
        <>
          {error ? (
            <>
              <div className="col-12 d-flex justify-content-center gap-3">
                <h2>{error}</h2>
              </div>
            </>
          ) : (
            <>
              <div className="widget-heading d-flex justify-content-between align-items-center">
                <h3 className="m-0">All Services</h3>
                <Link to={"/add-services"} className="btn btn-primary btn-sm">
                  Add New Service
                </Link>
              </div>
              {services && (
                <>
                  {services.length > 0 ? (
                    <>
                      <div className="row">
                        {services.map((data, index) => (
                          <div className="col-xl-4 col-md-6" key={index}>
                          <div>
                          <div className="card all-crs-wid">
                              <div className="card-body">
                                <div className="courses-bx">
                                  <div className="dlab-media">
                                    <IMG url={data.image} />
                                  </div>
                                  <div className="dlab-info">
                                    <div className="dlab-title d-flex justify-content-between">
                                      <div>
                                        <h4>
                                          <Link to={`./service-details/${data._id}`}>
                                            {data.en.name}
                                          </Link>
                                        </h4>
                                        <p className="m-0">
                                          {data.en.description}
                                        </p>
                                      </div>
                                    </div>
                                    <div className="d-flex justify-content-between content align-items-center">
                                      <span>
                                        <svg
                                          className="me-2"
                                          width="24"
                                          height="25"
                                          viewBox="0 0 24 25"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M21.2 18.6C20.5 18.5 19.8 18.4 19 18.4C16.5 18.4 14.1 19.1 12 20.5C9.90004 19.2 7.50005 18.4 5.00005 18.4C4.30005 18.4 3.50005 18.5 2.80005 18.6C2.30005 18.7 1.90005 19.2 2.00005 19.8C2.10005 20.4 2.60005 20.7 3.20005 20.6C3.80005 20.5 4.40005 20.4 5.00005 20.4C7.30005 20.4 9.50004 21.1 11.4 22.5C11.7 22.8 12.2 22.8 12.6 22.5C15 20.8 18 20.1 20.8 20.6C21.3 20.7 21.9 20.3 22 19.8C22.1 19.2 21.7 18.7 21.2 18.6ZM21.2 2.59999C20.5 2.49999 19.8 2.39999 19 2.39999C16.5 2.39999 14.1 3.09999 12 4.49999C9.90004 3.09999 7.50005 2.39999 5.00005 2.39999C4.30005 2.39999 3.50005 2.49999 2.80005 2.59999C2.40005 2.59999 2.00005 3.09999 2.00005 3.49999V15.5C2.00005 16.1 2.40005 16.5 3.00005 16.5C3.10005 16.5 3.10005 16.5 3.20005 16.5C3.80005 16.4 4.40005 16.3 5.00005 16.3C7.30005 16.3 9.50004 17 11.4 18.4C11.7 18.7 12.2 18.7 12.6 18.4C15 16.7 18 16 20.8 16.5C21.3 16.6 21.9 16.2 22 15.7C22 15.6 22 15.6 22 15.5V3.49999C22 3.09999 21.6 2.59999 21.2 2.59999Z"
                                            fill="#c7c7c7"
                                          />
                                        </svg>
                                        50 Project
                                      </span>
                                 
                                    </div>
                                    <div className="d-flex justify-content-between content align-items-center mt-2">
                                    
                                      <Link
                                        to={`./service-details/${data._id}`}
                                        className="btn btn-primary btn-sm"
                                      >
                                        View
                                      </Link>
                                      <button
                                      onClick={()=>deteteService(data._id)}
                                       
                                        className="btn btn-danger btn-sm"
                                      >
                                        Delete
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          </div>
                        ))}
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="col-12 d-flex justify-content-center gap-3">
                        <h2>No Services Add </h2>
                      </div>
                    </>
                  )}
                </>
              )}
            </>
          )}
        </>
      )}

      {/* pagenation  */}
      <>
        {/*
       <div className="pagination-down">
        <div className="d-flex align-items-center justify-content-between flex-wrap">
          <h4 className="sm-mb-0 mb-3">
            Showing <span>1-6 </span>from <span>100 </span>data
          </h4>
          <ul>
            <li>
              <Link to={"#"}>
                <i className="fas fa-chevron-left"></i>
              </Link>
            </li>
            <li>
              <Link to={"#"} className="active">
                1
              </Link>
            </li>
            <li>
              <Link to={"#"}>2</Link>
            </li>
            <li>
              <Link to={"#"}>3</Link>
            </li>
            <li>
              <Link to={"#"}>
                <i className="fas fa-chevron-right"></i>
              </Link>
            </li>
          </ul>
        </div>
      </div> 
      */}
      </>
    </>
  );
}
export default DisplayServices;
