import { configureStore } from "@reduxjs/toolkit";

//users
import LoginSlicer from "./Slicers/Users/loginSlicer";
import createUserSlicer from "./Slicers/Users/createUser";
import getAllUserSlicer from "./Slicers/Users/getAllUsersSlicer";
import deleteUserSlicer from "./Slicers/Users/deleteUserSlicer";
import updateUserSlicer from "./Slicers/Users/updateUserSlicer";

//contact us
import getAllMessageSlicer from "./Slicers/Contact/getAllMessagesSlicer";
import DeleteMessageSlicer from "./Slicers/Contact/DeleteMessageSlicer";
import getByIdMessageSlicer from "./Slicers/Contact/getByIdMessageSlicer";

// internship
import getAllIntershipMessagesSlicer from "./Slicers/Intership/getAllMessagesSlicer";
import getIntershipMessagesByIdSlicer from "./Slicers/Intership/getByIdMessageSlicer";
import DeleteIntershipMessagesByIdSlicer from "./Slicers/Intership/DeleteMessageSlicer";

//Team
import createTeamMember from "./Slicers/Team/createTeamMember";
import getAllTeamMembersSlicer from "./Slicers/Team/getAllTeamMembersSlicer";
import deleteTeamMemberSlicer from "./Slicers/Team/deleteTeamMemberSlicer";
import updateVisableTeamMemberSlicer from "./Slicers/Team/updateVisableTeamMemberSlicer";
import updateTeamMemberSlicer from "./Slicers/Team/updateTeamMemberSlicer";

//Messages
import messegesCreateSlicer from "./Slicers/messeges/messegesCreateSlicer";
import getAllMessegesSlicer from "./Slicers/messeges/getAllMessegesSlicer";

//Home video
import getAllHomeVideoSlicer from "./Slicers/homeVideo/getAllHomeVideoSlicer";
import createVideoHomeSlicer from "./Slicers/homeVideo/createVideoHomeSlicer";
import editHomeVideoSlicer from "./Slicers/homeVideo/editHomeVideoSlicer";

//Home Sliders
import getAllHomeSLidersSlicer from "./Slicers/homeSliders/getAllHomeSLidersSlicer";
import createHomeSliderSlicer from "./Slicers/homeSliders/createHomeSliderSlicer";
import deteteHomeSlidersSlicer from "./Slicers/homeSliders/deteteHomeSlidersSlicer";
import getSingleSliderSlicer from "./Slicers/homeSliders/getSingleSliderSlicer";
import updateHomeSliderSlicer from "./Slicers/homeSliders/updateHomeSliderSlicer";
import updateVisableSingleHomeSliderSlicer from "./Slicers/homeSliders/updateVisableSingleHomeSliderSlicer";

//Services
import getAllServicesSlicer from "./Slicers/CServices/getAllServicesSlicer";
import createServiceSlicer from "./Slicers/CServices/createServiceSlicer";
import getSingleServicesSlicer from "./Slicers/CServices/getSingleServicesSlicer";
import updateVisableSingleServicesSlicer from "./Slicers/CServices/updateVisableSingleServicesSlicer";
import updateServiceSlicer from "./Slicers/CServices/updateServiceSlicer";
import deteteServicesSlicer from "./Slicers/CServices/deteteServicesSlicer";

//Projects
import createProjectSlicer from "./Slicers/Project/createProjectSlicer";
import getAllProjetcsSlicer from "./Slicers/Project/getAllProjetcsSlicer";
import deteteProjectSlicer from "./Slicers/Project/deteteProjectSlicer";
import getSingleProjectSlicer from "./Slicers/Project/getSingleProjectSlicer";
import updateVisableSingleProjectSlicer from "./Slicers/Project/updateVisableSingleProjectSlicer";
import updateDisplayLayoutSingleProjectSlicer from "./Slicers/Project/updateDisplayLayoutSingleProjectSlicer";
import deleteImagesSlicer from "./Slicers/Project/deleteImagesSlicer";
import showInHomeSlicer from "./Slicers/Project/ShowInHomeSlicer";
import deleteVideoSlicer from "./Slicers/Project/deleteVideoSlicer";

//carrers
import getAllCarrersSlicer from "./Slicers/carrers/getAllCarrersSlicer";
import CreateCarrersSlicer from "./Slicers/carrers/CreateCarrersSlicer";
import deletePostionsSlicer from "./Slicers/carrers/deletePostionsSlicer";
import updateCarrersSlicer from "./Slicers/carrers/updateCarrersSlicer";
import updateVisabilityCarrersSlicer from "./Slicers/carrers/updateVisabilityCarrersSlicer";

const mySrore = configureStore({
  reducer: {
    // User Login Slicer
    LoginSlicer,
    createUserSlicer,
    getAllUserSlicer,
    deleteUserSlicer,
    updateUserSlicer,

    //contact forms
    getAllMessageSlicer,
    DeleteMessageSlicer,
    getByIdMessageSlicer,

    //intership forms
    getAllIntershipMessagesSlicer,
    getIntershipMessagesByIdSlicer,
    DeleteIntershipMessagesByIdSlicer,

    //team
    createTeamMember,
    getAllTeamMembersSlicer,
    deleteTeamMemberSlicer,
    updateVisableTeamMemberSlicer,
    updateTeamMemberSlicer,

    //homeVideo
    getAllHomeVideoSlicer,
    createVideoHomeSlicer,
    editHomeVideoSlicer,

    //homeSliders
    getAllHomeSLidersSlicer,
    createHomeSliderSlicer,
    deteteHomeSlidersSlicer,
    getSingleSliderSlicer,
    updateHomeSliderSlicer,
    updateVisableSingleHomeSliderSlicer,
    // createServiceSlicer,

    //services
    getAllServicesSlicer,
    createServiceSlicer,
    getSingleServicesSlicer,
    updateVisableSingleServicesSlicer,
    updateServiceSlicer,
    deteteServicesSlicer,

    //Messages
    messegesCreateSlicer,
    getAllMessegesSlicer,

    //Projects
    createProjectSlicer,
    getAllProjetcsSlicer,
    deteteProjectSlicer,
    getSingleProjectSlicer,
    updateVisableSingleProjectSlicer,
    updateDisplayLayoutSingleProjectSlicer,
    showInHomeSlicer,
    deleteImagesSlicer,
    deleteVideoSlicer,

    //Carrers
    getAllCarrersSlicer,
    CreateCarrersSlicer,
    deletePostionsSlicer,
    updateCarrersSlicer,
    updateVisabilityCarrersSlicer,
  },
});

export default mySrore;
