import React, { useEffect, useRef, useState } from "react";
import { Editor } from "@tinymce/tinymce-react";

const App = (props) => {
  const [editorkey, seteditorkey] = useState(4);

  const cleareditor = () => {
    const newkey = editorkey * 43;
    seteditorkey(newkey);
  };
  const ref = useRef();
  const [intialState, setIntialState] = useState(
    "<p>This is the initial content of the editor</p>"
  );

  const handleEditorChange = (content, editor) => {
    props.handleSubmit(content);
  };

  useEffect(() => {
    console.log(props.targetClear);
    if (props.targetClear) {
      cleareditor();
    }
  }, [props.targetClear]);

  return (
    <Editor
      key={editorkey}
      ref={ref}
      initialValue={intialState}
      init={{
        height: 500,
        menubar: false,
        plugins: [
          "advlist autolink lists link image code charmap print preview anchor",
          "searchreplace visualblocks code fullscreen",
          "insertdatetime media table paste code help wordcount",
        ],
        toolbar:
          "undo redo | formatselect | code |link | image | bold italic backcolor |  alignleft aligncenter alignright alignjustify | \n" +
          "bullist numlist outdent indent | removeformat | help ",
        content_style: "body { color: #828282 }",
      }}
      onEditorChange={handleEditorChange}
    />
  );
};

export default App;
