import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { TimerloggedOut } from "../../../services/AuthServices";
import { checkErrorMessage } from "../../../services/ErrorMessage";

export const LoginApi = createAsyncThunk(
  "user/login",
  async (userCredintials) => {
    const request = await fetch(`${process.env.REACT_APP_PUBLIC_API}/api/user/login`, {
      method: "POST",
      headers: {
        "Content-Type":"application/json"
      },
      body: JSON.stringify(userCredintials),
    })
      .then((res) => res.json())
      .then((result) => result)
      .catch((err) => err);

      return request;
  }
);



const LoginSlicer = createSlice({
  name: "login",
  initialState: {
    loading: false,
    user: null,
    error: null,
  },
  extraReducers: (builder)=>{
    builder.addCase(LoginApi.pending, (state) => {
        state.loading = true;
    });
    builder.addCase(LoginApi.fulfilled,(state,acttion)=>{
        state.loading = false;
        if(acttion.payload.message === "LoggedIn"){
            state.user = acttion.payload;
            localStorage.setItem("userData",JSON.stringify(acttion.payload));
            TimerloggedOut(acttion.payload.expiresIn);
        }else{
            state.error = checkErrorMessage(acttion.payload.message);
        }
    });
    builder.addCase(LoginApi.rejected,(state,acttion)=>{
        state.loading = false;
        state.error = checkErrorMessage(acttion.payload.message);
    });
    },
});

export default LoginSlicer.reducer;
