import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { checkErrorMessage } from "../../../services/ErrorMessage";


export const updatePostionApi = createAsyncThunk(
  "carrers/update",
  async (data) => {
    const token = JSON.parse(localStorage.getItem("userData")).token;
    const request = await fetch(
      `${process.env.REACT_APP_PUBLIC_API}/api/dashbord/carrers/update/${data.id}`,
      {
        method: "POST",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data.data),
      }
    )
      .then((response) => response.json())
      .then((result) => result)
      .catch((error) => error);

    return request;
  }
);

const updateCarrersSlicer = createSlice({
  name: "updateCarrer",
  initialState: {
    loading: false,
    data: null,
    error: null,

  },
  extraReducers: (builder) => {
    
     builder.addCase(updatePostionApi.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updatePostionApi.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload.message === "Postion updated successfully") {
        state.data = action.payload.message;
      } else {
        state.error = checkErrorMessage(action.payload.message);
      }
    });
    builder.addCase(updatePostionApi.rejected, (state, action) => {
      state.loading = false;
      state.error = checkErrorMessage(action.payload.message);
    });
   
  },
});

export default updateCarrersSlicer.reducer;
