import React, { Fragment, useEffect, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import swal from "sweetalert";
import CustomSpinner from "../../Ui/Spinner";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { useRef } from "react";
import { createProjectsApi } from "../../../../store/Slicers/Project/createProjectSlicer";
import axios from "axios";

const loginSchema = Yup.object().shape({
  categoryId: Yup.string()
    .required("Please enter a category")
    .matches(/^\S*$/, "category name must not have spaces"),
  release_date: Yup.string()
    .min(3, "Your relese date must consist of at least 3 characters ")
    .max(200, "Your relese date must consist of at most 200 characters ")
    .required("Please enter a relese date"),
  enclient: Yup.string()
    .min(3, "Your name must consist of at least 3 characters ")
    .max(200, "Your name must consist of at most 200 characters ")
    .required("Please enter a client name"),
  enname: Yup.string()
    .min(3, "Your name must consist of at least 3 characters ")
    .max(200, "Your name must consist of at most 200 characters ")
    .required("Please enter a name"),
  entitle: Yup.string()
    .min(3, "Your description must consist of at least 3 characters ")
    .max(200, "Your description must consist of at most 200 characters ")
    .required("Please enter a description"),
  endescription: Yup.string()
    .min(3, "Your phragraph must consist of at least 3 characters ")
    .max(3000, "Your phragraph must consist of at most 3000 characters ")
    .required("Please enter a phragraph"),
  arclient: Yup.string()
    .min(3, "Your name must consist of at least 3 characters ")
    .max(200, "Your name must consist of at most 200 characters ")
    .required("Please enter a client name"),
  arname: Yup.string()
    .min(3, "Your name must consist of at least 3 characters ")
    .max(200, "Your name must consist of at most 200 characters ")
    .required("Please enter a name"),
  artitle: Yup.string()
    .min(3, "Your description must consist of at least 3 characters ")
    .max(200, "Your description must consist of at most 200 characters ")
    .required("Please enter a description"),
  ardescription: Yup.string()
    .min(3, "Your phragraph must consist of at least 3 characters ")
    .max(3000, "Your phragraph must consist of at most 3000 characters ")
    .required("Please enter a phragraph"),
});

const AddProjectForm = () => {
  const [categories, setCategories] = useState([]);
  const fileRef = useRef(null);
  const [tempimg, setTempImg] = useState([]);
  const [progress, setProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);

  const [formIntilaData, setFormIntilaData] = useState({
    categoryId: "",
    release_date: "",

    enname: "",
    entitle: "",
    endescription: "",
    enclient: "",

    arname: "",
    artitle: "",
    ardescription: "",
    arclient: "",

    // images: [],
    // imagesnames: "",
  });

  const handleFormSubmite = async (value, { resetForm }) => {
    setIsUploading(true);
    const projectFormData = new FormData();
    projectFormData.append("category", value.categoryId);
    projectFormData.append("release_date", value.release_date);
    projectFormData.append("en[name]", value.enname);
    projectFormData.append("en[title]", value.entitle);
    projectFormData.append("en[description]", value.endescription);
    projectFormData.append("en[client_name]", value.enclient);
    projectFormData.append("ar[name]", value.arname);
    projectFormData.append("ar[title]", value.artitle);
    projectFormData.append("ar[description]", value.ardescription);
    projectFormData.append("ar[client_name]", value.arclient);

    // Array.from(value.images).map((element) => {
    //   projectFormData.append("images", element);
    // });

    const token = JSON.parse(localStorage.getItem("userData")).token;
    await axios
      .request(
        `${process.env.REACT_APP_PUBLIC_API}/api/dashbord/project/create`,
        {
          method: "POST",
          maxBodyLength: Infinity,
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data: projectFormData,
          onUploadProgress: (e) => {
            const uploadedRateData = Math.floor(
              Math.round(100 * e.loaded) / e.total
            );
            setProgress(uploadedRateData);
          },
        }
      )
      .then((result) => {
        setIsUploading(false);
        if (result.data.message === "Project created successfully") {
          swal("Done!", result.data.message, "success");
          resetForm();
          setTempImg([]);
          setProgress(0);
          setIsUploading(false);
        } else {
          swal("Error", result.data.message, "error");
          setProgress(0);
          setIsUploading(false);
        }
      })
      .catch((error) => {
        swal("Oops", `${error}`, "error");
        setProgress(0);
        setIsUploading(false);
      });

    // dispatch(createProjectsApi(value)).then((res) => {
    //   if (res.payload.message === "Project created successfully") {
    //     swal("Done!", res.payload.message, "success");
    //     resetForm();
    //     setTempImg([]);
    //   } else {
    //     swal("Error!", error, "error");
    //   }
    // });
  };

  const openFileUploader = () => {
    fileRef.current.click();
  };

  const getAllCate = async () => {
    await fetch(`${process.env.REACT_APP_PUBLIC_API}/api/services/categoris`, {
      method: "GET",
    })
      .then((res) => {
        return res.json();
      })
      .then((ressult) => {
        setCategories(ressult.data);
      })
      .catch((error) => error);
  };

  useEffect(() => {
    getAllCate();
  }, []);

  return (
    <>
      {categories && (
        <>
          {categories.length > 0 ? (
            <>
              <div className="row">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-header">
                      <h4 className="card-title">Add Service From</h4>
                    </div>
                    <div className="card-body">
                      <div className="basic-form">
                        <Formik
                          initialValues={{
                            categoryId: formIntilaData.categoryId,
                            release_date: formIntilaData.release_date,

                            enname: formIntilaData.enname,
                            entitle: formIntilaData.entitle,
                            endescription: formIntilaData.endescription,
                            enclient: formIntilaData.enclient,

                            arname: formIntilaData.arname,
                            artitle: formIntilaData.artitle,
                            ardescription: formIntilaData.ardescription,
                            arclient: formIntilaData.arclient,

                            // images: formIntilaData.images,
                            // imagname: formIntilaData.imagname,
                          }}
                          validationSchema={loginSchema}
                          onSubmit={handleFormSubmite}
                        >
                          {({
                            values,
                            errors,
                            setValues,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            handleReset,
                          }) => (
                            <form onSubmit={handleSubmit}>
                              <div className="row">
                                <div
                                  className={`col-12 form-group mb-3 ${
                                    values.categoryId
                                      ? errors.categoryId
                                        ? "is-invalid"
                                        : "is-valid"
                                      : ""
                                  }`}
                                >
                                  <label className="text-label">
                                    Select Service Of Project
                                  </label>
                                  <div className="input-group transparent-append mb-2">
                                    <select
                                      className="form-control"
                                      name="categoryId"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values.categoryId}
                                      placeholder="Chose Project Service"
                                    >
                                      <option defaultValue={""}>
                                        Please select
                                      </option>
                                      {categories.map((data, index) => (
                                        <option key={index} value={data._id}>
                                          {data.en.name}
                                        </option>
                                      ))}
                                    </select>
                                    <div
                                      id="val-categoryId-error"
                                      className="invalid-feedback animated fadeInUp"
                                      style={{ display: "block" }}
                                    >
                                      {errors.categoryId && errors.categoryId}
                                    </div>
                                  </div>
                                </div>
                              </div>

                              {values.categoryId &&
                                values.categoryId !== "" && (
                                  <>
                                    {/* Cleint And Date  Inputs Data */}
                                    <div className="row">
                                      <div
                                        className="card-header"
                                        style={{
                                          border: "transparent",
                                        }}
                                      >
                                        <h4 className="card-title">
                                          Client Data
                                        </h4>
                                      </div>
                                      {/* Englich Name  */}
                                      <div
                                        className={` col-lg-6 col-sm-12 form-group mb-3 ${
                                          values.enclient
                                            ? errors.enclient
                                              ? "is-invalid"
                                              : "is-valid"
                                            : ""
                                        }`}
                                      >
                                        <label className="text-label">
                                          English Client Name
                                        </label>
                                        <div className="input-group">
                                          <span className="input-group-text">
                                            <i className="fa-solid fa-file-signature"></i>
                                          </span>
                                          <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter a english client name.."
                                            name="enclient"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.enclient}
                                          />
                                          <div
                                            id="val-enclient-error"
                                            className="invalid-feedback animated fadeInUp"
                                            style={{ display: "block" }}
                                          >
                                            {errors.enclient && errors.enclient}
                                          </div>
                                        </div>
                                      </div>
                                      {/* Arabic Name  */}
                                      <div
                                        className={`col-lg-6 col-sm-12 form-group mb-3 ${
                                          values.arclient
                                            ? errors.arclient
                                              ? "is-invalid"
                                              : "is-valid"
                                            : ""
                                        }`}
                                      >
                                        <label className="text-label">
                                          Arabic Client Name
                                        </label>
                                        <div className="input-group">
                                          <span className="input-group-text">
                                            <i className="fa-solid fa-audio-description"></i>
                                          </span>
                                          <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter a english client name .."
                                            name="arclient"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.arclient}
                                          />
                                          <div
                                            id="val-arclient-error"
                                            className="invalid-feedback animated fadeInUp"
                                            style={{ display: "block" }}
                                          >
                                            {errors.arclient && errors.arclient}
                                          </div>
                                        </div>
                                      </div>
                                      {/* Relase Date */}
                                      <div
                                        className={`col-lg-12 col-sm-12 form-group mb-3 ${
                                          values.release_date
                                            ? errors.release_date
                                              ? "is-invalid"
                                              : "is-valid"
                                            : ""
                                        }`}
                                      >
                                        <label className="text-label">
                                        Release date
                                        </label>
                                        <div className="input-group">
                                          <span className="input-group-text">
                                            <i className="fa-solid fa-audio-description"></i>
                                          </span>
                                          <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter Relesed Date Ex Desamper 2022"
                                            name="release_date"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.release_date}
                                          />
                                          <div
                                            id="val-release_date-error"
                                            className="invalid-feedback animated fadeInUp"
                                            style={{ display: "block" }}
                                          >
                                            {errors.release_date &&
                                              errors.release_date}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    {/* English Inputs Data */}
                                    <div className="row">
                                      <div
                                        className="card-header"
                                        style={{
                                          border: "transparent",
                                        }}
                                      >
                                        <h4 className="card-title">
                                          Englich Data
                                        </h4>
                                      </div>
                                      {/* Englich Name  */}
                                      <div
                                        className={` col-lg-6 col-sm-12 form-group mb-3 ${
                                          values.enname
                                            ? errors.enname
                                              ? "is-invalid"
                                              : "is-valid"
                                            : ""
                                        }`}
                                      >
                                        <label className="text-label">
                                          English Project Name
                                        </label>
                                        <div className="input-group">
                                          <span className="input-group-text">
                                            <i className="fa-solid fa-file-signature"></i>
                                          </span>
                                          <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter a english name.."
                                            name="enname"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.enname}
                                          />
                                          <div
                                            id="val-enname-error"
                                            className="invalid-feedback animated fadeInUp"
                                            style={{ display: "block" }}
                                          >
                                            {errors.enname && errors.enname}
                                          </div>
                                        </div>
                                      </div>
                                      {/* Englich Title  */}
                                      <div
                                        className={`col-lg-6 col-sm-12 form-group mb-3 ${
                                          values.entitle
                                            ? errors.entitle
                                              ? "is-invalid"
                                              : "is-valid"
                                            : ""
                                        }`}
                                      >
                                        <label className="text-label">
                                          English Project Title
                                        </label>
                                        <div className="input-group">
                                          <span className="input-group-text">
                                            <i className="fa-solid fa-audio-description"></i>
                                          </span>
                                          <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter a english title.."
                                            name="entitle"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.entitle}
                                          />
                                          <div
                                            id="val-entitle-error"
                                            className="invalid-feedback animated fadeInUp"
                                            style={{ display: "block" }}
                                          >
                                            {errors.entitle && errors.entitle}
                                          </div>
                                        </div>
                                      </div>
                                      {/* Englich Descriptions  */}
                                      <div
                                        className={`col-12 form-group mb-3 ${
                                          values.endescription
                                            ? errors.endescription
                                              ? "is-invalid"
                                              : "is-valid"
                                            : ""
                                        }`}
                                      >
                                        <label className="text-label">
                                          English Project Description
                                        </label>
                                        <div className="input-group">
                                          <textarea
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter a english description.."
                                            name="endescription"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.endescription}
                                            rows={8}
                                            style={{ resize: "none" }}
                                          ></textarea>

                                          <div
                                            id="val-endescription-error"
                                            className="invalid-feedback animated fadeInUp"
                                            style={{ display: "block" }}
                                          >
                                            {errors.endescription &&
                                              errors.endescription}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    {/* Arabic Inputs Data */}
                                    <div className="row" dir="rtl">
                                      <div
                                        className="card-header"
                                        style={{
                                          border: "transparent",
                                        }}
                                      >
                                        <h4 className="card-title">
                                          البيانات بالعربي
                                        </h4>
                                      </div>
                                      {/* Arabic Name  */}
                                      <div
                                        className={`col-lg-6 col-sm-12 form-group mb-3 ${
                                          values.arname
                                            ? errors.arname
                                              ? "is-invalid"
                                              : "is-valid"
                                            : ""
                                        }`}
                                      >
                                        <label className="text-label">
                                          اسم المشروع باللغة العربية
                                        </label>
                                        <div className="input-group">
                                          <span
                                            className="input-group-text"
                                            style={{
                                              borderRadius: "0.5rem",
                                              borderTopLeftRadius: "0",
                                              borderBottomLeftRadius: "0",
                                            }}
                                          >
                                            <i className="fa-solid fa-file-signature"></i>
                                          </span>
                                          <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter a arabic name.."
                                            name="arname"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.arname}
                                          />
                                          <div
                                            id="val-arname-error"
                                            className="invalid-feedback animated fadeInUp"
                                            style={{ display: "block" }}
                                          >
                                            {errors.arname && errors.arname}
                                          </div>
                                        </div>
                                      </div>
                                      {/* Arabic Title  */}
                                      <div
                                        className={`col-lg-6 col-sm-12 form-group mb-3 ${
                                          values.artitle
                                            ? errors.artitle
                                              ? "is-invalid"
                                              : "is-valid"
                                            : ""
                                        }`}
                                      >
                                        <label className="text-label">
                                          عنوان قصير للمشروه باللغة العربية
                                        </label>
                                        <div className="input-group">
                                          <span
                                            className="input-group-text"
                                            style={{
                                              borderRadius: "0.5rem",
                                              borderTopLeftRadius: "0",
                                              borderBottomLeftRadius: "0",
                                            }}
                                          >
                                            <i className="fa-solid fa-audio-description"></i>
                                          </span>
                                          <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter a arabic title.."
                                            name="artitle"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.artitle}
                                          />
                                          <div
                                            id="val-artitle-error"
                                            className="invalid-feedback animated fadeInUp"
                                            style={{ display: "block" }}
                                          >
                                            {errors.artitle && errors.artitle}
                                          </div>
                                        </div>
                                      </div>
                                      {/* Arabic Description  */}
                                      <div
                                        className={`col-12 form-group mb-3 ${
                                          values.ardescription
                                            ? errors.ardescription
                                              ? "is-invalid"
                                              : "is-valid"
                                            : ""
                                        }`}
                                      >
                                        <label className="text-label">
                                          اكتب وصف تفصيلي للمسشروع باللغة
                                          العربية
                                        </label>
                                        <div className="input-group">
                                          <textarea
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter a arabic description.."
                                            name="ardescription"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.ardescription}
                                            rows={8}
                                            style={{ resize: "none" }}
                                          ></textarea>
                                          <div
                                            id="val-ardescription-error"
                                            className="invalid-feedback animated fadeInUp"
                                            style={{ display: "block" }}
                                          >
                                            {errors.ardescription &&
                                              errors.ardescription}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    {/* Image Inputs Data */}
                                    {/* <div className="row">
                                      <div
                                        className="card-header"
                                        style={{
                                          border: "transparent",
                                        }}
                                      >
                                        <h4 className="card-title">
                                          Uploade images
                                        </h4>
                                      </div>
                                      <div
                                        className={`col-lg-6 col-sm-12 form-group mb-3`}
                                      >
                                        <label
                                          onClick={openFileUploader}
                                          className="text-label"
                                          style={{
                                            cursor: "pointer",
                                            width: "100%",
                                            padding: "2rem",
                                            backgroundColor: "#1f88d9",
                                            opacity: "0.5",
                                            border: "3px dashed blue",
                                            borderRadius: "10px",
                                            color: "#111",
                                            fontSize: "1rem",
                                            textAlign: "center",
                                            overflow: "hidden",
                                          }}
                                        >
                                          {values.imagname
                                            ? values.imagname
                                            : "  Upload Images"}
                                        </label>
                                        <div className="input-group">
                                          <input
                                            multiple
                                            accept="image/x-png,image/jpeg,image/jpg"
                                            type="file"
                                            className="form-control"
                                            name="image"
                                            hidden
                                            ref={fileRef}
                                            onChange={(e) => {
                                              setTempImg([]);

                                              setValues({
                                                ...values,
                                                imagname:
                                                  e.target.files.length +
                                                  " files selected",
                                                images: e.target.files,
                                              });
                                              Array.from(e.target.files).map(
                                                (data) => {
                                                  setTempImg((preVal) => [
                                                    ...preVal,
                                                    URL.createObjectURL(data),
                                                  ]);
                                                }
                                              );
                                            }}
                                          />
                                        </div>
                                      </div>
                                      <div
                                        className={`col-lg-6 col-sm-12 form-group mb-3`}
                                      >
                                        {tempimg && (
                                          <>
                                            {tempimg.map((data, index) => {
                                              return (
                                                <img
                                                  className="m-1"
                                                  key={index}
                                                  src={data}
                                                  width={50}
                                                />
                                              );
                                            })}
                                          </>
                                        )}
                                      </div>
                                    </div> */}

                                    <div className="row">
                                      {isUploading && (
                                        <>
                                          <h5>
                                            uploading video now please waite...
                                          </h5>
                                          {/* <div className="d-flex justify-content-center align-items-center gap-1 w-100">
                      <Spinner animation="grow" variant="warning" />
                      <Spinner animation="grow" variant="warning" />
                      <Spinner animation="grow" variant="warning" />
                      <Spinner animation="grow" variant="warning" />
                      <span>{progress} %</span>
                    </div> */}
                                          <div className="progress-box">
                                            <div className="d-flex align-items-center justify-content-between">
                                              <h5 className="mb-0 fs-14 font-w600">
                                                Uploading Images now.., please
                                                don't refresh
                                              </h5>
                                              <span
                                                className="font-w600"
                                                id="progress-span"
                                              >
                                                {progress}%
                                              </span>
                                            </div>
                                            <div className="progress ">
                                              <div
                                                className="progress-bar bg-primary"
                                                id="progress-inner-width"
                                                style={{
                                                  width: `${progress}%`,
                                                  height: "12px",
                                                  borderRadius: "4px",
                                                }}
                                                role="progressbar"
                                              ></div>
                                            </div>
                                          </div>
                                        </>
                                      )}
                                    </div>

                                    {isUploading ? (
                                      <>
                                        <button
                                          className="btn me-2 btn-primary"
                                          disabled
                                        >
                                          <CustomSpinner />
                                        </button>
                                      </>
                                    ) : (
                                      <>
                                        <button
                                          type="submit"
                                          className="btn me-2 btn-primary"
                                        >
                                          Submit
                                        </button>
                                      </>
                                    )}
                                  </>
                                )}
                            </form>
                          )}
                        </Formik>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="col-12 d-flex justify-content-center gap-3">
                <h2>No Services Add </h2>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default AddProjectForm;
