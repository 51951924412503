import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { checkErrorMessage } from "../../../services/ErrorMessage";

export const getAllMembersApi = createAsyncThunk(
  "team/getAll",
  async () => {
    const token = JSON.parse(localStorage.getItem("userData")).token;


    const request = await fetch(
      `${process.env.REACT_APP_PUBLIC_API}/api/team/`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        }
      }
    )
      .then((res) => res.json())
      .then((result) => result)
      .catch((err) => err);

    return request;
  }
);

const getAllTeamMembersSlicer = createSlice({
  name: "getAll",
  initialState: {
    loading: false,
    user: null,
    error: null,
  },
  extraReducers: (builder) => {
    builder.addCase(getAllMembersApi.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllMembersApi.fulfilled, (state, acttion) => {
      state.loading = false;
      if (acttion.payload.message === "Data fetched successfully") {
        state.user = acttion.payload.data;
      } else {
        state.error = checkErrorMessage(acttion.payload.message);
      }
    });
    builder.addCase(getAllMembersApi.rejected, (state, acttion) => {
      state.loading = false;
      state.error = checkErrorMessage(acttion.payload.message);
    });
  },
});

export default getAllTeamMembersSlicer.reducer;
