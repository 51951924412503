import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { checkErrorMessage } from "../../../services/ErrorMessage";

export const getAllMessageApi = createAsyncThunk(
  "intership/getAllMessage",
  async () => {
    const token = JSON.parse(localStorage.getItem("userData")).token;
    const request = await fetch(
      `${process.env.REACT_APP_PUBLIC_API}/api/dashbord/intership/getAllMessages`,
      {
        method: "GET",
        headers: {
        "Authorization": `Bearer ${token}`
      }
      }
    )
      .then((response) => response.json())
      .then((result) => result)
      .catch((error) => error);

    return request;
  }
);

const getAllMessageSlicer = createSlice({
  name: "getAllMessages",
  initialState: {
    loading: false,
    data: null,
    error: null,
  },
  extraReducers: (builder) => {
    builder.addCase(getAllMessageApi.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllMessageApi.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload.message === "Data fetched successfully") {
        state.data = action.payload.data;
      } else {
        state.error = checkErrorMessage(action.payload.message);
      }
    });
    builder.addCase(getAllMessageApi.rejected, (state,action) => {
        state.loading = false;
        state.error = checkErrorMessage(action.payload.message);
      });
  },
});

export default getAllMessageSlicer.reducer;
