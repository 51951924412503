import avatarServiceImage from "../../../../images/avatar/service-avatar-img.jpg";


export default function CUSIMG({ url, title }) {
    return (
      <>
        {url && url !== "" ? (
          <>
            <img
              style={{ width: "100%" }}
              src={`${process.env.REACT_APP_PUBLIC_API}/${url}`}
              title={title ? title : "avatar service image"}
            />
          </>
        ) : (
          <>
            <img
              style={{ width: "100%" }}
              src={avatarServiceImage}
              title={"avatar service image"}
            />
          </>
        )}
      </>
    );
  }