import { Fragment } from "react";
import PageTitle from "../../layouts/PageTitle";
import MessageContacTable from "./component/MessageContacTable";

export default function Contactus() {
  return (
    <>
      <Fragment>
        <PageTitle activeMenu="Contact" motherMenu="Forms" />

        <MessageContacTable />
      </Fragment>
    </>
  );
}
