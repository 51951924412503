import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { checkErrorMessage } from "../../../services/ErrorMessage";


export const createNewCarrerApi = createAsyncThunk(
  "carrers/create",
  async (data) => {
    const token = JSON.parse(localStorage.getItem("userData")).token;
    const request = await fetch(
      `${process.env.REACT_APP_PUBLIC_API}/api/dashbord/carrers/create`,
      {
        method: "POST",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      }
    )
      .then((response) => response.json())
      .then((result) => result)
      .catch((error) => error);

    return request;
  }
);

const CreateCarrersSlicer = createSlice({
  name: "createCarrers",
  initialState: {
    loading: false,
    data: null,
    error: null,

  },
  extraReducers: (builder) => {
    
     builder.addCase(createNewCarrerApi.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createNewCarrerApi.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload.message === "Postion created successfully") {
        state.data = action.payload.message;
      } else {
        state.error = checkErrorMessage(action.payload.message);
      }
    });
    builder.addCase(createNewCarrerApi.rejected, (state, action) => {
      state.loading = false;
      state.error = checkErrorMessage(action.payload.message);
    });
   
  },
});

export default CreateCarrersSlicer.reducer;
