import { Fragment, useEffect, useRef, useState } from "react";
import PageTitle from "../../layouts/PageTitle";
import { useDispatch, useSelector } from "react-redux";
import { getHomeVideoApi } from "../../../store/Slicers/homeVideo/getAllHomeVideoSlicer";
import CustomGrowSpinner from "../Ui/CustomGrowSpinner";
import { createHomeVideoApi } from "../../../store/Slicers/homeVideo/createVideoHomeSlicer";
import { Formik } from "formik";
import * as Yup from "yup";
import swal from "sweetalert";
import CustomSpinner from "../Ui/Spinner";
import axios from "axios";
import IMG from "../Ui/IMG";
import { editHomeDataApi } from "../../../store/Slicers/homeVideo/editHomeVideoSlicer";

const createHomeVidoe = Yup.object().shape({
  entitle: Yup.string()
    .min(3, "Your title must consist of at least 3 characters ")
    .max(200, "Your title must consist of at most 200 characters ")
    .required("Please enter a title"),
  artitle: Yup.string()
    .min(3, "Your title must consist of at least 3 characters ")
    .max(200, "Your title must consist of at most 200 characters ")
    .required("Please enter a title"),
  video_link: Yup.string().required("Please enter youtube video link"),
});

// .matches(
//   /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
//   "Enter correct url!"
// )
export default function HomeVideo() {
  const { loading, error } = useSelector(
    (state) => state.getAllHomeVideoSlicer
  );
  const { loading:visabilityLoader,error:visabiityError } = useSelector(
    (state) => state.editHomeVideoSlicer
  );
  const [progress, setProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const dispatch = useDispatch();
  const [homeVideo, setHomeVideo] = useState([]);
  const fileRef = useRef(null);
  const videoRef = useRef(null);
  const openFileUploader = () => {
    fileRef.current.click();
  };
  const openFileVideoUploader = () => {
    videoRef.current.click();
  };
  const handleFormSubmite = async (value, { resetForm }) => {
    setIsUploading(true);
    const formData = new FormData();
    formData.append("en_title", value.entitle);
    formData.append("ar_title", value.artitle);
    formData.append("video_link", value.video_link);

    if (value.image) {
      formData.append("image", value.image);
    }

    const token = JSON.parse(localStorage.getItem("userData")).token;
    await axios
      .request(
        `${process.env.REACT_APP_PUBLIC_API}/api/home_video/update-home-video/${homeVideo[0]._id}`,
        {
          method: "POST",
          maxBodyLength: Infinity,
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data: formData,
          onUploadProgress: (e) => {
            const uploadedRateData = Math.floor(
              Math.round(100 * e.loaded) / e.total
            );
            setProgress(uploadedRateData);
          },
        }
      )
      .then((result) => {
        setIsUploading(false);
        if (result.data.message === "Data updated successfully") {
          swal("Done!", result.data.message, "success");
          resetForm();
          setProgress(0);
          setIsUploading(false);
          getAllHomeVideo();
        } else {
          swal("Error", result.data.message, "error");
          setProgress(0);
          setIsUploading(false);
        }
      })
      .catch((error) => {
        swal("Oops", `${error}`, "error");
        setProgress(0);
        setIsUploading(false);
      });
  };

  const getAllHomeVideo = () => {
    setHomeVideo([]);
    dispatch(getHomeVideoApi()).then((res) => {
      if (res.payload.message === "Data fetched successfully") {
        setHomeVideo(res.payload.data);
      }
    });
  };

  const createData = () => {
    dispatch(createHomeVideoApi()).then((res) => {
      if (res.payload.message === "Data created successfully") {
        getAllHomeVideo();
      }
    });
  };

  const updateVisability = () => {
    console.log(homeVideo[0]._id);
    dispatch(editHomeDataApi(homeVideo[0]._id)).then((res) => {
      if (res.payload.message === "Data updated successfully") {
        swal("Done!", res.payload.message, "success");
        getAllHomeVideo();
      }else{
        swal("Oops", `${visabiityError}`, "error");
      }
    });
  };

  useEffect(() => {
    setHomeVideo([]);
    getAllHomeVideo();
  }, []);

  return (
    <>
      <Fragment>
        <PageTitle activeMenu="Edit Home Video" motherMenu="Home Video" />
        {loading ? (
          <>
            <div className="col-12 d-flex justify-content-center gap-3">
              <CustomGrowSpinner />
              <CustomGrowSpinner />
              <CustomGrowSpinner />
            </div>
          </>
        ) : (
          <>
            {error ? (
              <>
                <div className="col-12 d-flex justify-content-center gap-3">
                  <h2>{error}</h2>
                </div>
              </>
            ) : (
              <>
                {homeVideo && !homeVideo[0] ? (
                  <>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="card">
                          <div className="card-header">
                            <h4 className="card-title">
                              No video added yet , Click to add
                            </h4>
                            <button
                              className="btn btn-primary"
                              onClick={createData}
                            >
                              Add Video
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="card">
                          <div className="card-header">
                            <h4 className="card-title">
                              Add & Edit Home Vidoe
                            </h4>
                            <span>
                              {homeVideo[0].visable ? (
                                <>
                                  <h4
                                    style={{
                                      color: "green",
                                    }}
                                  >
                                    {visabilityLoader ? (
                                      <>
                                        <CustomGrowSpinner />
                                      </>
                                    ) : (
                                      <>Visable</>
                                    )}
                                  </h4>
                                </>
                              ) : (
                                <>
                                  <h4
                                    style={{
                                      color: "red",
                                    }}
                                  >
                                    {visabilityLoader ? (
                                      <>
                                        <CustomGrowSpinner />
                                      </>
                                    ) : (
                                      <>Not Visable</>
                                    )}
                                  </h4>
                                </>
                              )}
                            </span>
                          </div>
                          <div className="card-body">
                            <div className="basic-form">
                              <Formik
                                initialValues={{
                                  entitle: homeVideo[0].en_title,
                                  artitle: homeVideo[0].ar_title,
                                  video_link: homeVideo[0].video_link,
                                  image: "",
                                  image_name: "",
                                  video: "",
                                  video_name: "",
                                }}
                                validationSchema={createHomeVidoe}
                                onSubmit={handleFormSubmite}
                              >
                                {({
                                  values,
                                  errors,
                                  setValues,
                                  handleChange,
                                  handleBlur,
                                  handleSubmit,
                                  handleReset,
                                }) => (
                                  <form onSubmit={handleSubmit}>
                                    {/* Ebglish Inputs Data */}
                                    <div className="row">
                                      <div
                                        className="card-header"
                                        style={{
                                          border: "transparent",
                                        }}
                                      >
                                        <h4 className="card-title">
                                          Home Video Data
                                        </h4>
                                      </div>
                                      {/* English Data */}
                                      <div
                                        className={` col-lg-6 col-sm-12 form-group mb-3 ${
                                          values.entitle
                                            ? errors.entitle
                                              ? "is-invalid"
                                              : "is-valid"
                                            : ""
                                        }`}
                                      >
                                        <label className="text-label">
                                          English video title
                                        </label>
                                        <div className="input-group">
                                          <span className="input-group-text">
                                            <i class="fa-solid fa-file-signature"></i>
                                          </span>
                                          <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter a english title.."
                                            name="entitle"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.entitle}
                                          />
                                          <div
                                            id="val-entitle-error"
                                            className="invalid-feedback animated fadeInUp"
                                            style={{ display: "block" }}
                                          >
                                            {errors.entitle && errors.entitle}
                                          </div>
                                        </div>
                                      </div>
                                      {/* Arabic Data */}
                                      <div
                                        className={` col-lg-6 col-sm-12 form-group mb-3 ${
                                          values.artitle
                                            ? errors.artitle
                                              ? "is-invalid"
                                              : "is-valid"
                                            : ""
                                        }`}
                                      >
                                        <label className="text-label">
                                          Arabic video title
                                        </label>
                                        <div className="input-group">
                                          <span className="input-group-text">
                                            <i class="fa-solid fa-file-signature"></i>
                                          </span>
                                          <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter a arabic title.."
                                            name="artitle"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.artitle}
                                          />
                                          <div
                                            id="val-artitle-error"
                                            className="invalid-feedback animated fadeInUp"
                                            style={{ display: "block" }}
                                          >
                                            {errors.artitle && errors.artitle}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    {/* Files Data */}
                                    <div className="row">
                                      <div
                                        className="card-header"
                                        style={{
                                          border: "transparent",
                                        }}
                                      >
                                        <h4 className="card-title">
                                          Files Data
                                        </h4>
                                      </div>

                                      {/* Video Link */}
                                      <div
                                        className={` col-lg-6 col-sm-12 form-group mb-3 ${
                                          values.video_link
                                            ? errors.video_link
                                              ? "is-invalid"
                                              : "is-valid"
                                            : ""
                                        }`}
                                      >
                                        <label className="text-label">
                                          {" "}
                                          Video Link{" "}
                                        </label>
                                        <div className="input-group">
                                          <span className="input-group-text">
                                            <i class="fa-solid fa-file-signature"></i>
                                          </span>
                                          <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter a youtube link.."
                                            name="video_link"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.video_link}
                                          />
                                          <div
                                            id="val-video_link-error"
                                            className="invalid-feedback animated fadeInUp"
                                            style={{ display: "block" }}
                                          >
                                            {errors.video_link &&
                                              errors.video_link}
                                          </div>
                                        </div>
                                      </div>

                                      {/* Image Data */}
                                      <div
                                        className={`col-6 form-group mb-3 ${
                                          values.image
                                            ? errors.image
                                              ? "is-invalid"
                                              : "is-valid"
                                            : ""
                                        }`}
                                      >
                                        <label
                                          onClick={openFileUploader}
                                          className="text-label"
                                          style={{
                                            cursor: "pointer",
                                            width: "100%",
                                            padding: "2rem",
                                            backgroundColor: "#1f88d9",
                                            opacity: "0.5",
                                            border: "3px dashed blue",
                                            borderRadius: "10px",
                                            color: "#111",
                                            fontSize: "1rem",
                                            textAlign: "center",
                                            overflow: "hidden",
                                          }}
                                        >
                                          {values.image_name
                                            ? values.image_name
                                            : "  Upload Images image 1920 X 1023"}
                                        </label>
                                        <div className="input-group">
                                          <input
                                            accept="image/x-png,image/jpeg,image/jpg"
                                            type="file"
                                            className="form-control"
                                            name="image"
                                            hidden
                                            ref={fileRef}
                                            onChange={(e) => {
                                              setValues({
                                                image_name:
                                                  e.target.files[0].name,
                                                image: e.target.files[0],
                                              });
                                            }}
                                          />
                                          <div
                                            id="val-image-error"
                                            classname="invalid-feedback animated fadeInUp"
                                            style={{
                                              display: "block",
                                              color: "#dc3545",
                                            }}
                                          >
                                            {errors.image && errors.image}
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="row">
                                      {isUploading && (
                                        <>
                                          <h5>
                                            uploading Data now please waite...
                                          </h5>

                                          <div className="progress-box">
                                            <div className="d-flex align-items-center justify-content-between">
                                              <h5 className="mb-0 fs-14 font-w600">
                                                Uploading Data now.., please
                                                don't refresh
                                              </h5>
                                              <span
                                                className="font-w600"
                                                id="progress-span"
                                              >
                                                {progress}%
                                              </span>
                                            </div>
                                            <div className="progress ">
                                              <div
                                                className="progress-bar bg-primary"
                                                id="progress-inner-width"
                                                style={{
                                                  width: `${progress}%`,
                                                  height: "12px",
                                                  borderRadius: "4px",
                                                }}
                                                role="progressbar"
                                              ></div>
                                            </div>
                                          </div>
                                        </>
                                      )}
                                    </div>

                                    {isUploading ? (
                                      <>
                                        <button
                                          className="btn me-2 btn-primary"
                                          disabled
                                        >
                                          <CustomSpinner />
                                        </button>
                                      </>
                                    ) : (
                                      <>
                                        <button
                                          type="submit"
                                          className="btn me-2 btn-primary"
                                        >
                                          Update
                                        </button>
                                      </>
                                    )}
                                    {homeVideo[0] &&
                                      homeVideo[0].video_link && (
                                        <>
                                          <a
                                            href={homeVideo[0].video_link}
                                            className="btn me-2 btn-danger"
                                            target="_blank"
                                          >
                                            watch video
                                          </a>
                                        </>
                                      )}
                                    <button
                                      type="button"
                                      className="btn me-2 btn-secondary"
                                      onClick={updateVisability}
                                    >
                                      change visabiity
                                    </button>
                                  </form>
                                )}
                              </Formik>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-4">
                        <div className="card ">
                          <IMG url={homeVideo[0].image} />
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
          </>
        )}
      </Fragment>
    </>
  );
}
