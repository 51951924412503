import React, { Fragment, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import swal from "sweetalert";
import CustomSpinner from "../../Ui/Spinner";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { useRef } from "react";
import { createServicesApi } from "../../../../store/Slicers/CServices/createServiceSlicer";
import axios from "axios";

const createHomeSliders = Yup.object().shape({
  enname: Yup.string()
    .min(3, "Your name must consist of at least 3 characters ")
    .max(200, "Your name must consist of at most 200 characters ")
    .required("Please enter a name"),
  endescription: Yup.string()
    .min(3, "Your description must consist of at least 3 characters ")
    .max(200, "Your description must consist of at most 200 characters ")
    .required("Please enter a description"),
  arname: Yup.string()
    .min(3, "Your name must consist of at least 3 characters ")
    .max(200, "Your name must consist of at most 200 characters ")
    .required("Please enter a name"),
  ardescription: Yup.string()
    .min(3, "Your description must consist of at least 3 characters ")
    .max(200, "Your description must consist of at most 200 characters ")
    .required("Please enter a description"),
});

const AddHomeSliderForm = () => {
  const [progress, setProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);

  const [formIntilaData, setFormIntilaData] = useState({
    enname: "",
    endescription: "",
    arname: "",
    ardescription: "",
  });

  const handleFormSubmite = async (value, { resetForm }) => {
    setIsUploading(true);
    console.log(value);
    const sliderDataFormData = new FormData();
    sliderDataFormData.append("en[name]", value.enname);
    sliderDataFormData.append("en[description]", value.endescription);
    sliderDataFormData.append("ar[name]", value.arname);
    sliderDataFormData.append("ar[description]", value.ardescription);

    const token = JSON.parse(localStorage.getItem("userData")).token;
    await axios
      .request(`${process.env.REACT_APP_PUBLIC_API}/api/home-Slider/create-slider`, {
        method: "POST",
        maxBodyLength: Infinity,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: sliderDataFormData,
        onUploadProgress: (e) => {
          const uploadedRateData = Math.floor(
            Math.round(100 * e.loaded) / e.total
          );
          setProgress(uploadedRateData);
        },
      })
      .then((result) => {
        setIsUploading(false);
        if (result.data.message === "Data created successfully") {
          swal("Done!", result.data.message, "success");
          resetForm();
          setProgress(0);
          setIsUploading(false);
        } else {
          swal("Error", result.data.message, "error");
          setProgress(0);
          setIsUploading(false);
        }
      })
      .catch((error) => {
        swal("Oops", `${error}`, "error");
        setProgress(0);
        setIsUploading(false);
      });
  };



  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">Add Home Slider From</h4>
          </div>
          <div className="card-body">
            <div className="basic-form">
              <Formik
                initialValues={{
                  enname: formIntilaData.enname,
                  endescription: formIntilaData.endescription,

                  arname: formIntilaData.arname,
                  ardescription: formIntilaData.ardescription,
                }}
                validationSchema={createHomeSliders}
                onSubmit={handleFormSubmite}
              >
                {({
                  values,
                  errors,
                  setValues,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  handleReset,
                }) => (
                  <form onSubmit={handleSubmit}>
                    {/* Ebglish Inputs Data */}
                    <div className="row">
                      <div
                        className="card-header"
                        style={{
                          border: "transparent",
                        }}
                      >
                        <h4 className="card-title">Englich Data</h4>
                      </div>
                      {/* Englich Name  */}
                      <div
                        className={` col-lg-6 col-sm-12 form-group mb-3 ${
                          values.enname
                            ? errors.enname
                              ? "is-invalid"
                              : "is-valid"
                            : ""
                        }`}
                      >
                        <label className="text-label">
                          English Service Name
                        </label>
                        <div className="input-group">
                          <span className="input-group-text">
                            <i class="fa-solid fa-file-signature"></i>
                          </span>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter a english name.."
                            name="enname"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.enname}
                          />
                          <div
                            id="val-enname-error"
                            className="invalid-feedback animated fadeInUp"
                            style={{ display: "block" }}
                          >
                            {errors.enname && errors.enname}
                          </div>
                        </div>
                      </div>
                      {/* Englich Descriptions  */}
                      <div
                        className={`col-lg-6 col-sm-12 form-group mb-3 ${
                          values.endescription
                            ? errors.endescription
                              ? "is-invalid"
                              : "is-valid"
                            : ""
                        }`}
                      >
                        <label className="text-label">
                          English Service Description
                        </label>
                        <div className="input-group">
                          <span className="input-group-text">
                            <i class="fa-solid fa-audio-description"></i>
                          </span>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter a english description.."
                            name="endescription"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.endescription}
                          />
                          <div
                            id="val-endescription-error"
                            className="invalid-feedback animated fadeInUp"
                            style={{ display: "block" }}
                          >
                            {errors.endescription && errors.endescription}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* Arabic Inputs Data */}
                    <div className="row">
                      <div
                        className="card-header"
                        style={{
                          border: "transparent",
                        }}
                      >
                        <h4 className="card-title">Arabic Data</h4>
                      </div>
                      {/* Arabic Name  */}
                      <div
                        className={`col-lg-6 col-sm-12 form-group mb-3 ${
                          values.arname
                            ? errors.arname
                              ? "is-invalid"
                              : "is-valid"
                            : ""
                        }`}
                      >
                        <label className="text-label">
                          Arabic Service Name
                        </label>
                        <div className="input-group">
                          <span className="input-group-text">
                            <i class="fa-solid fa-file-signature"></i>
                          </span>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter a arabic name.."
                            name="arname"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.arname}
                          />
                          <div
                            id="val-arname-error"
                            className="invalid-feedback animated fadeInUp"
                            style={{ display: "block" }}
                          >
                            {errors.arname && errors.arname}
                          </div>
                        </div>
                      </div>
                      {/* Arabic Descriptions  */}
                      <div
                        className={`col-lg-6 col-sm-12 form-group mb-3 ${
                          values.ardescription
                            ? errors.ardescription
                              ? "is-invalid"
                              : "is-valid"
                            : ""
                        }`}
                      >
                        <label className="text-label">
                          Arabic Service Description
                        </label>
                        <div className="input-group">
                          <span className="input-group-text">
                            <i class="fa-solid fa-audio-description"></i>
                          </span>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter a arabic description.."
                            name="ardescription"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.ardescription}
                          />
                          <div
                            id="val-ardescription-error"
                            className="invalid-feedback animated fadeInUp"
                            style={{ display: "block" }}
                          >
                            {errors.ardescription && errors.ardescription}
                          </div>
                        </div>
                      </div>
                    </div>


                    <div className="row">
                      {isUploading && (
                        <>
                          <h5>uploading Data now please waite...</h5>

                          <div className="progress-box">
                            <div className="d-flex align-items-center justify-content-between">
                              <h5 className="mb-0 fs-14 font-w600">
                                Uploading Data now.., please don't refresh
                              </h5>
                              <span className="font-w600" id="progress-span">
                                {progress}%
                              </span>
                            </div>
                            <div className="progress ">
                              <div
                                className="progress-bar bg-primary"
                                id="progress-inner-width"
                                style={{
                                  width: `${progress}%`,
                                  height: "12px",
                                  borderRadius: "4px",
                                }}
                                role="progressbar"
                              ></div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>

                    {isUploading ? (
                      <>
                        <button className="btn me-2 btn-primary" disabled>
                          <CustomSpinner />
                        </button>
                      </>
                    ) : (
                      <>
                        <button type="submit" className="btn me-2 btn-primary">
                          Create
                        </button>
                      </>
                    )}
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddHomeSliderForm;

// // {/* <div className="row">
// // {/* Email  */}
// // <div
// //   className={`col-6 form-group mb-3 ${
// //     values.email
// //       ? errors.email
// //         ? "is-invalid"
// //         : "is-valid"
// //       : ""
// //   }`}
// // >
// //   <label className="text-label">email</label>
// //   <div className="input-group">
// //     <span className="input-group-text">
// //       <i class="fa-solid fa-envelope"></i>
// //     </span>
// //     <input
// //       type="email"
// //       className="form-control"
// //       placeholder="Enter a email.."
// //       name="email"
// //       onChange={handleChange}
// //       onBlur={handleBlur}
// //       value={values.email}
// //     />
// //     <div
// //       id="val-email-error"
// //       className="invalid-feedback animated fadeInUp"
// //       style={{ display: "block" }}
// //     >
// //       {errors.email && errors.email}
// //     </div>
// //   </div>
// // </div>
// // {/* Password  */}
// // <div
// //   className={`col-6 form-group mb-3 ${
// //     values.password
// //       ? errors.password
// //         ? "is-invalid"
// //         : "is-valid"
// //       : ""
// //   }`}
// // >
// //   <label className="text-label">Password *</label>
// //   <div className="input-group transparent-append mb-2">
// //     <span className="input-group-text">
// //       {" "}
// //       <i className="fa fa-lock" />{" "}
// //     </span>

// //     <input
// //       type={`${showPassword ? "text" : "password"}`}
// //       className="form-control"
// //       id="val-password1"
// //       name="password"
// //       onChange={handleChange}
// //       onBlur={handleBlur}
// //       value={values.password}
// //       placeholder="Choose a safe one.."
// //     />

// //     <div
// //       className="input-group-text "
// //       onClick={() => setShowPassword(!showPassword)}
// //     >
// //       {" "}
// //       {showPassword === false ? (
// //         <i className="fa fa-eye-slash" />
// //       ) : (
// //         <i className="fa fa-eye" />
// //       )}
// //     </div>
// //     <div
// //       id="val-username1-error"
// //       className="invalid-feedback animated fadeInUp"
// //       style={{ display: "block" }}
// //     >
// //       {errors.password && errors.password}
// //     </div>
// //   </div>
// // </div>
// // </div>
// // <div className="row">
// // {/* Mobile  */}
// // <div
// //   className={`col-6 form-group mb-3 ${
// //     values.mobile
// //       ? errors.mobile
// //         ? "is-invalid"
// //         : "is-valid"
// //       : ""
// //   }`}
// // >
// //   <label className="text-label">mobile</label>
// //   <div className="input-group">
// //     <span className="input-group-text">
// //       <i class="fa-solid fa-mobile"></i>
// //     </span>
// //     <input
// //       type="tel"
// //       maxLength={11}
// //       className="form-control"
// //       placeholder="Enter a mobile.."
// //       name="mobile"
// //       onChange={handleChange}
// //       onBlur={handleBlur}
// //       value={values.mobile}
// //     />
// //     <div
// //       id="val-mobile-error"
// //       className="invalid-feedback animated fadeInUp"
// //       style={{ display: "block" }}
// //     >
// //       {errors.mobile && errors.mobile}
// //     </div>
// //   </div>
// // </div>
// // {/* Role  */}
// // <div
// //   className={`col-6 form-group mb-3 ${
// //     values.role
// //       ? errors.role
// //         ? "is-invalid"
// //         : "is-valid"
// //       : ""
// //   }`}
// // >
// //   <label className="text-label">role</label>
// //   <div className="input-group transparent-append mb-2">
// //     <select
// //       className="form-control"
// //       name="role"
// //       onChange={handleChange}
// //       onBlur={handleBlur}
// //       value={values.role}
// //       placeholder="Chose User Role"
// //     >
// //       <option defaultValue={null}>Please select</option>
// //       <option value="admin">admin</option>
// //       <option value="user">Assestant</option>
// //     </select>
// //     <div
// //       id="val-role-error"
// //       className="invalid-feedback animated fadeInUp"
// //       style={{ display: "block" }}
// //     >
// //       {errors.role && errors.role}
// //     </div>
// //   </div>
// // </div>
// // </div> */}
