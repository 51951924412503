import { Fragment } from "react";
import PageTitle from "../../layouts/PageTitle";
import AddUserForm from "./component/AddUserForm";

export default function AddUser() {
  return (
    <>
      <Fragment>
      <PageTitle
        activeMenu="Add User"
        motherMenu="Users"
      />


        <AddUserForm />
      </Fragment>
    </>
  );
}
