import React from "react";

const Footer = () => {
	return (
		<div className="footer">
			<div className="copyright">
				<p>Copyright © Designed &amp; Developed by
					<a href="#" target="_blank"  rel="noreferrer">
						Top Growth
					</a>
					2022
				</p>
			</div>
		</div>
	);
};

export default Footer;
