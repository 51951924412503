// For Logged Out
export const TimerloggedOut = (timer) => {
    setTimeout(() => {
        loggedOut();
    }, timer);
};

export const loggedOut = () => {
  localStorage.removeItem("userData");
  window.location.href = "/";
};

export const checkLogin = () => {
  const userData = localStorage.getItem("userData");
  if (!userData) {
    return false;
  }

  const userDataObj = JSON.parse(userData);

  const expired = new Date(userDataObj.expiresInDate);
  const today = new Date();

  if (today > expired) {
    loggedOut();
    return false;
  }

  const timer = expired.getTime() - today.getTime();
  TimerloggedOut(timer);
  return true;
};
