import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Tab,
  Nav,
  Row,
  Col,
  Card,
  Dropdown,
  Button,
  Modal,
  Carousel,
  Accordion,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";
import { Formik } from "formik";
import * as Yup from "yup";
import { useRef } from "react";
import CustomSpinner from "../../Ui/Spinner";
import axios from "axios";

export default function AddImagesModlues({
  onFinshed,
  projectId,
  showNow,
  onHide,
}) {
  const [tempimg, setTempImg] = useState([]);
  const [progress, setProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);

  const fileRef = useRef(null);

  const handleFormSubmite = async (value, { resetForm }) => {
    console.log(value);
    setIsUploading(true);
    const projectFormData = new FormData();
    if (value.images.length !== 0) {
      Array.from(value.images).map((element) => {
        projectFormData.append("images", element);
      });
    } else {
      swal("Oops", `please Upload Images`, "error");
      setProgress(0);
      setIsUploading(false);
      return;
    }

    const token = JSON.parse(localStorage.getItem("userData")).token;
    await axios
      .request(
        `${process.env.REACT_APP_PUBLIC_API}/api/dashbord/project/addProjectImages/${projectId}`,
        {
          method: "POST",
          maxBodyLength: Infinity,
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
          data: projectFormData,
          onUploadProgress: (e) => {
            const uploadedRateData = Math.floor(
              Math.round(100 * e.loaded) / e.total
            );
            setProgress(uploadedRateData);
          },
        }
      )
      .then((result) => {
        setIsUploading(false);
        if (result.data.message === "Data Updated successfully") {
          swal("Done!", result.data.message, "success");
          resetForm();
          setTempImg([]);
          setProgress(0);
          setIsUploading(false);
          onHide();
          onFinshed();
        } else {
          swal("Error", result.data.message, "error");
          setProgress(0);
          setIsUploading(false);
        }
      })
      .catch((error) => {
        swal("Oops", `${error}`, "error");
        setProgress(0);
        setIsUploading(false);
      });
  };

  const openFileUploader = () => {
    fileRef.current.click();
  };

  return (
    <>
      <Modal className="fade bd-example-modal-lg" show={showNow} size="lg">
        <Modal.Header>
          <Modal.Title>Add Project Images</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => {
              setTempImg([]);
              setProgress(0);
              setIsUploading(false);
              onHide();
            }}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={{
              projectId: "",

              images: [],
              imagname: "",
            }}
            onSubmit={handleFormSubmite}
          >
            {({
              values,
              errors,
              setValues,
              handleChange,
              handleBlur,
              handleSubmit,
              handleReset,
            }) => (
              <form onSubmit={handleSubmit}>
                {/* Image Inputs Data */}
                <div className="row">
                  <div
                    className="card-header"
                    style={{
                      border: "transparent",
                    }}
                  >
                    <h4 className="card-title">Uploade images</h4>
                  </div>
                  {/* image  */}
                  <div className={`col-lg-12 col-sm-12 form-group mb-3`}>
                    <label
                      onClick={openFileUploader}
                      className="text-label"
                      style={{
                        cursor: "pointer",
                        width: "100%",
                        padding: "2rem",
                        backgroundColor: "#1f88d9",
                        opacity: "0.5",
                        border: "3px dashed blue",
                        borderRadius: "10px",
                        color: "#111",
                        fontSize: "1rem",
                        textAlign: "center",
                        overflow: "hidden",
                      }}
                    >
                      {values.imagname ? values.imagname : "  Upload Images"}
                    </label>
                    <div className="input-group">
                      <input
                        multiple
                        accept="image/x-png,image/jpeg,image/jpg"
                        type="file"
                        className="form-control"
                        name="image"
                        hidden
                        ref={fileRef}
                        onChange={(e) => {
                          setTempImg([]);

                          setValues({
                            ...values,
                            imagname: e.target.files.length + " files selected",
                            images: e.target.files,
                          });
                          Array.from(e.target.files).map((data) => {
                            setTempImg((preVal) => [
                              ...preVal,
                              URL.createObjectURL(data),
                            ]);
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className={`col-lg-12 col-sm-12 form-group mb-3`}>
                    {tempimg && (
                      <>
                        {tempimg.map((data, index) => {
                          return (
                            <img
                              className="m-1"
                              key={index}
                              src={data}
                              width={50}
                            />
                          );
                        })}
                      </>
                    )}
                  </div>
                </div>

                <div className="row">
                  {isUploading && (
                    <>
                      <h5>uploading video now please waite...</h5>

                      <div className="progress-box">
                        <div className="d-flex align-items-center justify-content-between">
                          <h5 className="mb-0 fs-14 font-w600">
                            Uploading Images now.., please don't refresh
                          </h5>
                          <span className="font-w600" id="progress-span">
                            {progress}%
                          </span>
                        </div>
                        <div className="progress ">
                          <div
                            className="progress-bar bg-primary"
                            id="progress-inner-width"
                            style={{
                              width: `${progress}%`,
                              height: "12px",
                              borderRadius: "4px",
                            }}
                            role="progressbar"
                          ></div>
                        </div>
                      </div>
                    </>
                  )}
                </div>

                {isUploading ? (
                  <>
                    <button className="btn me-2 btn-primary" disabled>
                      <CustomSpinner />
                    </button>
                  </>
                ) : (
                  <>
                    <button type="submit" className="btn me-2 btn-primary">
                      Submit
                    </button>
                  </>
                )}
              </form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
}
