import PageTitle from "../../layouts/PageTitle";
import { Dropdown, Modal, Nav, Tab } from "react-bootstrap";
import product2 from "../../../images/product/5.jpg";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { useSelector,useDispatch } from "react-redux";
import swal from "sweetalert";
import { getAllMembersApi } from "../../../store/Slicers/Team/getAllTeamMembersSlicer";
import IMG from "../Ui/IMG";
import { DeleteMemberApi } from "../../../store/Slicers/Team/deleteTeamMemberSlicer";
import { updateVisableTeamApi } from "../../../store/Slicers/Team/updateVisableTeamMemberSlicer";
import { updateTeamDataApi } from "../../../store/Slicers/Team/updateTeamMemberSlicer";
import CustomSpinner from "../Ui/Spinner";
import CustomGrowSpinner from "../Ui/CustomGrowSpinner";

const teamSchema = Yup.object().shape({
  enname: Yup.string()
    .min(3, "Your name must consist of at least 3 characters ")
    .max(50, "Your name must consist of at most 50 characters ")
    .required("Please enter a name"),
  entitle: Yup.string()
    .min(3, "Your title must consist of at least 3 characters ")
    .max(100, "Your title must consist of at most 100 characters ")
    .required("Please enter a title"),
  endescription: Yup.string()
    .min(3, "Your description must consist of at least 3 characters ")
    .max(100, "Your description must consist of at most 100 characters ")
    .required("Please enter a description"),

  arname: Yup.string()
    .min(3, "Your name must consist of at least 3 characters ")
    .max(50, "Your name must consist of at most 50 characters ")
    .required("Please enter a name"),
  artitle: Yup.string()
    .min(3, "Your title must consist of at least 3 characters ")
    .max(100, "Your title must consist of at most 100 characters ")
    .required("Please enter a title"),
  ardescription: Yup.string()
    .min(3, "Your description must consist of at least 3 characters ")
    .max(100, "Your description must consist of at most 100 characters ")
    .required("Please enter a description"),
});

const Card2 = ({ handleUpdate, handleDelete, handleVisable, data }) => {
  return (
    <>
      <div className="col-lg-12 col-xl-4">
        <div className="card">
          <div className="card-header border-0 pb-0">
            {data.visable ? (
              <>
                <h4
                  style={{
                    color: "green",
                  }}
                >
                  <>Visable</>
                </h4>
              </>
            ) : (
              <>
                <h4
                  style={{
                    color: "red",
                  }}
                >
                  <>Not Visable</>
                </h4>
              </>
            )}

            <Dropdown className="ms-auto">
              <Dropdown.Toggle
                as="a"
                className="btn-link i-false btn sharp tp-btn-light btn-dark"
              >
                <svg
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.0012 9.86C11.6544 9.86 11.3109 9.92832 10.9905 10.061C10.67 10.1938 10.3789 10.3883 10.1336 10.6336C9.88835 10.8788 9.6938 11.17 9.56107 11.4905C9.42834 11.8109 9.36002 12.1544 9.36002 12.5012C9.36002 12.848 9.42834 13.1915 9.56107 13.5119C9.6938 13.8324 9.88835 14.1236 10.1336 14.3688C10.3789 14.6141 10.67 14.8086 10.9905 14.9413C11.3109 15.0741 11.6544 15.1424 12.0012 15.1424C12.7017 15.1422 13.3734 14.8638 13.8687 14.3684C14.3639 13.873 14.642 13.2011 14.6418 12.5006C14.6417 11.8001 14.3632 11.1284 13.8678 10.6332C13.3724 10.138 12.7005 9.85984 12 9.86H12.0012ZM3.60122 9.86C3.25437 9.86 2.91092 9.92832 2.59048 10.061C2.27003 10.1938 1.97887 10.3883 1.73361 10.6336C1.48835 10.8788 1.2938 11.17 1.16107 11.4905C1.02834 11.8109 0.960022 12.1544 0.960022 12.5012C0.960022 12.848 1.02834 13.1915 1.16107 13.5119C1.2938 13.8324 1.48835 14.1236 1.73361 14.3688C1.97887 14.6141 2.27003 14.8086 2.59048 14.9413C2.91092 15.0741 3.25437 15.1424 3.60122 15.1424C4.30171 15.1422 4.97345 14.8638 5.46866 14.3684C5.96387 13.873 6.24198 13.2011 6.24182 12.5006C6.24166 11.8001 5.96324 11.1284 5.46781 10.6332C4.97237 10.138 4.30051 9.85984 3.60002 9.86H3.60122ZM20.4012 9.86C20.0544 9.86 19.7109 9.92832 19.3905 10.061C19.07 10.1938 18.7789 10.3883 18.5336 10.6336C18.2884 10.8788 18.0938 11.17 17.9611 11.4905C17.8283 11.8109 17.76 12.1544 17.76 12.5012C17.76 12.848 17.8283 13.1915 17.9611 13.5119C18.0938 13.8324 18.2884 14.1236 18.5336 14.3688C18.7789 14.6141 19.07 14.8086 19.3905 14.9413C19.7109 15.0741 20.0544 15.1424 20.4012 15.1424C21.1017 15.1422 21.7734 14.8638 22.2687 14.3684C22.7639 13.873 23.042 13.2011 23.0418 12.5006C23.0417 11.8001 22.7632 11.1284 22.2678 10.6332C21.7724 10.138 21.1005 9.85984 20.4 9.86H20.4012Z"
                    fill="#A098AE"
                  />
                </svg>
              </Dropdown.Toggle>
              <Dropdown.Menu
                className="dropdown-menu dropdown-menu-end"
                align="right"
              >
                <Dropdown.Item
                  onClick={() => {
                    handleVisable(data);
                  }}
                >
                  Publish
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    handleUpdate(data);
                  }}
                >
                  Edit
                </Dropdown.Item>
                <Dropdown.Item
                  style={{
                    color: "red",
                  }}
                  onClick={() => {
                    handleDelete(data._id);
                  }}
                >
                  Delete
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="card-body">
            <div className="row m-b-30">
              <div className="col-md-5 col-xxl-12">
                <div className="new-arrival-product mb-4 mb-xxl-4 mb-md-0">
                  <div className="new-arrivals-img-contnent">
                    <IMG url={data.image} title={data.en.name} />
                  </div>
                </div>
              </div>
              <div className="col-md-7 col-xxl-12">
                <div className="new-arrival-content position-relative socal-links">
                  <h5>Social Media Links</h5>
                  <ul
                    className="d-flex gap-1"
                    style={{
                      fontSize: "2rem",
                    }}
                  >
                    {data.social_links.fasebook && (
                      <li>
                        <a
                          className="px-1"
                          href={data.social_links.fasebook}
                          target="_blank"
                        >
                          <i class={`fa-brands fa-facebook`}></i>
                        </a>
                      </li>
                    )}
                    {data.social_links.instagram && (
                      <li>
                        <a
                          className="px-1"
                          href={data.social_links.instagram}
                          target="_blank"
                        >
                          <i className={`fa-brands fa-instagram`}></i>
                        </a>
                      </li>
                    )}
                    {data.social_links.linkedin && (
                      <li>
                        <a
                          className="px-1"
                          href={data.social_links.linkedin}
                          target="_blank"
                        >
                          <i className={`fa-brands fa-linkedin`}></i>
                        </a>
                      </li>
                    )}
                    {data.social_links.behance && (
                      <li>
                        <a
                          className="px-1"
                          href={data.social_links.behance}
                          target="_blank"
                        >
                          <i className={`fa-brands fa-behance`}></i>
                        </a>
                      </li>
                    )}
                  </ul>
                </div>

                <div className="new-arrival-content position-relative">
                  <Tab.Container defaultActiveKey={"english"}>
                    <Nav as="ul" className="nav-pills mb-4">
                      <Nav.Item>
                        <Nav.Link eventKey={"english"}>english</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey={"arabic"}>arabic</Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <Tab.Content>
                      <Tab.Pane eventKey={"english"}>
                        <h4>{data.en.name}</h4>
                        <p>
                          Title: <span className="item">{data.en.title}</span>
                        </p>
                        <p className="text-content">
                          Description:{" "}
                          <span className="item">{data.en.description}</span>
                        </p>
                      </Tab.Pane>
                      <Tab.Pane eventKey={"arabic"} dir="rtl">
                        <h4>{data.ar.name}</h4>
                        <p>
                          اللقب: <span className="item">{data.ar.title}</span>
                        </p>
                        <p className="text-content">
                          الوصف:{" "}
                          <span className="item">{data.ar.description}</span>
                        </p>
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default function DisplayAllTeam() {
  const { loading, error } = useSelector((s) => s.getAllTeamMembersSlicer);
  const { loading: updatedLoading, error: updatedError } = useSelector(
    (s) => s.updateTeamMemberSlicer
  );
  const { error: deletedError } = useSelector((s) => s.deleteTeamMemberSlicer);
  const dispatch = useDispatch();

  const [teamData, setTeamData] = useState([]);

  const [updateModal, setUpdateModal] = useState(false);
  const [formIntilaData, setFormIntilaData] = useState({
    enname: "",
    entitle: "",
    endescription: "",
    arname: "",
    artitle: "",
    ardescription: "",
    socialmedia: {
      facebookLink: "",
      instagramLink: "",
      behainceLink: "",
      linkedinLink: "",
    },
    image: "",
    imagname: "",
    id: "",
  });
  const showUpdateModel = () => {
    setUpdateModal(true);
  };
  const cloaseUpdateModel = () => {
    setUpdateModal(false);
    setFormIntilaData({
      enname: "",
      entitle: "",
      endescription: "",
      arname: "",
      artitle: "",
      ardescription: "",
      socialmedia: {
        facebookLink: "",
        instagramLink: "",
        behainceLink: "",
        linkedinLink: "",
      },
      image: "",
      imagname: "",
      id: "",
    });
  };

  const fileRef = useRef(null);

  const handleFormSubmite = (value, { resetForm }) => {

    dispatch(updateTeamDataApi(value)).then((res) => {
      if (res.payload.message === "Data Updated successfully") {
        swal("Updated!", res.payload.message, "success");
        resetForm();
        cloaseUpdateModel();
        getAllTeamMembers();
      } else {
        swal("Error!", updatedError, "error");
      }
    });
  };

  const openFileUploader = () => {
    fileRef.current.click();
  };

  const handleDataUpdate = (data) => {
    console.log(data);
    setFormIntilaData({
      id: data._id,
      enname: data.en.name,
      entitle: data.en.title,
      endescription: data.en.description,
      arname: data.ar.name,
      artitle: data.ar.title,
      ardescription: data.ar.description,
      socialmedia: {
        facebookLink: data.social_links.fasebook,
        instagramLink: data.social_links.instagram,
        behainceLink: data.social_links.behance,
        linkedinLink: data.social_links.linkedin,
      },
      image: "",
      imagname: "",
    });
    showUpdateModel();
  };

  const handleDelete = (dataId) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover user data again",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        dispatch(DeleteMemberApi(dataId)).then((res) => {
          if (res.payload.message === "Data Deleted successfully") {
            swal("Deleted!", res.payload.message, "success");
          } else {
            swal("Error!", deletedError, "error");
          }
          getAllTeamMembers();
        });
      } else {
        swal("The user data is safe!");
      }
    });
  };

  const getAllTeamMembers = () => {
    dispatch(getAllMembersApi()).then((res) => {
      if (res.payload.message === "Data fetched successfully") {
        setTeamData(res.payload.data);
      }
    });
  };

  const handleVisable = (data) => {
    dispatch(updateVisableTeamApi(data)).then((res) => {
      if (res.payload.message === "Data Updated successfully") {
        swal("Updated!", res.payload.message, "success");
      } else {
        swal("Error!", deletedError, "error");
      }
      getAllTeamMembers();
    });
  };

  useEffect(() => {
    getAllTeamMembers();
  }, []);

  return (
    <>
      <Fragment>
        <PageTitle activeMenu="My Team" motherMenu="Users" />

        {loading ? (
          <>
            <div className="col-12 d-flex justify-content-center gap-3">
              <CustomGrowSpinner />
              <CustomGrowSpinner />
              <CustomGrowSpinner />
            </div>
          </>
        ) : (
          <>
            {error ? (
              <>
                <div className="col-12 d-flex justify-content-center gap-3">
                  <h2>{error}</h2>
                </div>
              </>
            ) : (
              <>
                {teamData && (
                  <>
                    {teamData.length > 0 ? (
                      <>
                        <div className="row">
                          {teamData.map((teamData, index) => (
                            <Card2
                              key={index}
                              handleUpdate={handleDataUpdate}
                              handleDelete={handleDelete}
                              handleVisable={handleVisable}
                              data={teamData}
                            />
                          ))}
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="col-12 d-flex justify-content-center gap-3">
                          <h2>No Team Members Added Yet</h2>
                        </div>
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </>
        )}
      </Fragment>
      <Modal show={updateModal} size="lg">
        <Modal.Header closeButton onClick={cloaseUpdateModel}>
          Update Team Member Data
        </Modal.Header>
        <Modal.Body>
          <div className="basic-form">
            <Formik
              initialValues={{
                id: formIntilaData.id,
                enname: formIntilaData.enname,
                entitle: formIntilaData.entitle,
                endescription: formIntilaData.endescription,

                arname: formIntilaData.arname,
                artitle: formIntilaData.artitle,
                ardescription: formIntilaData.ardescription,

                facebookLink: formIntilaData.socialmedia.facebookLink,
                instagramLink: formIntilaData.socialmedia.instagramLink,
                behainceLink: formIntilaData.socialmedia.behainceLink,
                linkedinLink: formIntilaData.socialmedia.linkedinLink,

                image: formIntilaData.image,
                imagname: formIntilaData.imagname,
              }}
              validationSchema={teamSchema}
              onSubmit={handleFormSubmite}
            >
              {({
                values,
                errors,
                setValues,
                handleChange,
                handleBlur,
                handleSubmit,
                handleReset,
              }) => (
                <form onSubmit={handleSubmit}>
                  {/* Ebglish Inputs Data */}
                  <div className="row">
                    <div
                      className="card-header"
                      style={{
                        border: "transparent",
                      }}
                    >
                      <h4 className="card-title">Englich Data</h4>
                    </div>
                    {/* Englich Name  */}
                    <div
                      className={`col-6 form-group mb-3 ${
                        values.enname
                          ? errors.enname
                            ? "is-invalid"
                            : "is-valid"
                          : ""
                      }`}
                    >
                      <label className="text-label">
                        English Team Member Name
                      </label>
                      <div className="input-group">
                        <span className="input-group-text">
                          <i className="fa-solid fa-file-signature"></i>
                        </span>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter a english name.."
                          name="enname"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.enname}
                        />
                        <div
                          id="val-enname-error"
                          className="invalid-feedback animated fadeInUp"
                          style={{ display: "block" }}
                        >
                          {errors.enname && errors.enname}
                        </div>
                      </div>
                    </div>
                    {/* Englich title  */}
                    <div
                      className={`col-6 form-group mb-3 ${
                        values.entitle
                          ? errors.entitle
                            ? "is-invalid"
                            : "is-valid"
                          : ""
                      }`}
                    >
                      <label className="text-label">
                        English Team Mamber Title
                      </label>
                      <div className="input-group">
                        <span className="input-group-text">
                          <i className="fa-solid fa-audio-description"></i>
                        </span>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter a english title.."
                          name="entitle"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.entitle}
                        />
                        <div
                          id="val-endescription-error"
                          className="invalid-feedback animated fadeInUp"
                          style={{ display: "block" }}
                        >
                          {errors.entitle && errors.entitle}
                        </div>
                      </div>
                    </div>
                    {/* Englich description  */}
                    <div
                      className={`col-12 form-group mb-3 ${
                        values.endescription
                          ? errors.endescription
                            ? "is-invalid"
                            : "is-valid"
                          : ""
                      }`}
                    >
                      <label className="text-label">
                        English Team Member Description
                      </label>
                      <div className="input-group">
                        <span className="input-group-text">
                          <i className="fa-solid fa-audio-description"></i>
                        </span>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter a english description.."
                          name="endescription"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.endescription}
                        />
                        <div
                          id="val-endescription-error"
                          className="invalid-feedback animated fadeInUp"
                          style={{ display: "block" }}
                        >
                          {errors.endescription && errors.endescription}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Arabic Inputs Data */}
                  <div className="row">
                    <div
                      className="card-header"
                      style={{
                        border: "transparent",
                      }}
                    >
                      <h4 className="card-title">Arabic Data</h4>
                    </div>
                    {/* Arabic Name  */}
                    <div
                      className={`col-6 form-group mb-3 ${
                        values.arname
                          ? errors.arname
                            ? "is-invalid"
                            : "is-valid"
                          : ""
                      }`}
                    >
                      <label className="text-label">Arabic Service Name</label>
                      <div className="input-group">
                        <span className="input-group-text">
                          <i className="fa-solid fa-file-signature"></i>
                        </span>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter a arabic name.."
                          name="arname"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.arname}
                        />
                        <div
                          id="val-arname-error"
                          className="invalid-feedback animated fadeInUp"
                          style={{ display: "block" }}
                        >
                          {errors.arname && errors.arname}
                        </div>
                      </div>
                    </div>
                    {/* Arabic title  */}
                    <div
                      className={`col-6 form-group mb-3 ${
                        values.artitle
                          ? errors.artitle
                            ? "is-invalid"
                            : "is-valid"
                          : ""
                      }`}
                    >
                      <label className="text-label">
                        Arabic Team Member Title
                      </label>
                      <div className="input-group">
                        <span className="input-group-text">
                          <i className="fa-solid fa-audio-description"></i>
                        </span>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter a arabic title.."
                          name="artitle"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.artitle}
                        />
                        <div
                          id="val-ardescription-error"
                          className="invalid-feedback animated fadeInUp"
                          style={{ display: "block" }}
                        >
                          {errors.artitle && errors.artitle}
                        </div>
                      </div>
                    </div>
                    {/* Arabic description  */}
                    <div
                      className={`col-12 form-group mb-3 ${
                        values.ardescription
                          ? errors.ardescription
                            ? "is-invalid"
                            : "is-valid"
                          : ""
                      }`}
                    >
                      <label className="text-label">
                        Arabic Team Member Description
                      </label>
                      <div className="input-group">
                        <span className="input-group-text">
                          <i className="fa-solid fa-audio-description"></i>
                        </span>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter a arabic description.."
                          name="ardescription"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.ardescription}
                        />
                        <div
                          id="val-ardescription-error"
                          className="invalid-feedback animated fadeInUp"
                          style={{ display: "block" }}
                        >
                          {errors.ardescription && errors.ardescription}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Image Inputs Data */}
                  <div className="row">
                    <div
                      className="card-header"
                      style={{
                        border: "transparent",
                      }}
                    >
                      <h4 className="card-title">Uploade image</h4>
                    </div>
                    {/* image  */}
                    <div className={`col-6 form-group mb-3`}>
                      <label
                        onClick={openFileUploader}
                        className="text-label"
                        style={{
                          cursor: "pointer",
                          width: "100%",
                          padding: "2rem",
                          backgroundColor: "#1f88d9",
                          opacity: "0.5",
                          border: "3px dashed blue",
                          borderRadius: "10px",
                          color: "#111",
                          fontSize: "1rem",
                          textAlign: "center",
                          overflow: "hidden",
                        }}
                      >
                        {values.imagname ? values.imagname : "  Upload Image"}
                      </label>
                      <div className="input-group">
                        <input
                          type="file"
                          className="form-control"
                          name="image"
                          hidden
                          ref={fileRef}
                          onChange={(e) => {
                            setValues({
                              ...values,
                              imagname: e.target.files[0].name,
                              image: e.target.files[0],
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div
                      className="card-header"
                      style={{
                        border: "transparent",
                      }}
                    >
                      <h4 className="card-title">Social Media Links</h4>
                    </div>
                    {/* Facebook  */}
                    <div className={`col-6 form-group mb-3`}>
                      <label className="text-label">Facebook Link Acount</label>
                      <div className="input-group">
                        <span className="input-group-text">
                          <i className="fa-solid fa-facebook"></i>
                        </span>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter a facebook acount link"
                          name="facebookLink"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.facebookLink}
                        />
                      </div>
                    </div>
                    {/* Instagram  */}
                    <div className={`col-6 form-group mb-3`}>
                      <label className="text-label">
                        Instagram Link Acount
                      </label>
                      <div className="input-group">
                        <span className="input-group-text">
                          <i className="fa-solid fa-instagram"></i>
                        </span>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter a instagram acount link"
                          name="instagramLink"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.instagramLink}
                        />
                      </div>
                    </div>
                    {/* Behaince  */}
                    <div className={`col-6 form-group mb-3`}>
                      <label className="text-label">Behaince Link Acount</label>
                      <div className="input-group">
                        <span className="input-group-text">
                          <i className="fa-solid fa-behaince"></i>
                        </span>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter a behaince acount link"
                          name="behainceLink"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.behainceLink}
                        />
                      </div>
                    </div>
                    {/* Linkedin  */}
                    <div className={`col-6 form-group mb-3`}>
                      <label className="text-label">Linkedin Link Acount</label>
                      <div className="input-group">
                        <span className="input-group-text">
                          <i className="fa-solid fa-linkedin"></i>
                        </span>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter a linkedin acount link"
                          name="linkedinLink"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.linkedinLink}
                        />
                      </div>
                    </div>
                  </div>

                  {updatedLoading ? (
                    <>
                      <button className="btn me-2 btn-primary" disabled>
                        <CustomSpinner />
                      </button>
                    </>
                  ) : (
                    <>
                      <button type="submit" className="btn me-2 btn-primary">
                        Submit
                      </button>
                    </>
                  )}
                </form>
              )}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
