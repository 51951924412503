import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Tab,
  Nav,
  Row,
  Col,
  Card,
  Dropdown,
  Button,
  Modal,
  Carousel,
  Accordion,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";
import { Formik } from "formik";
import * as Yup from "yup";
import { useRef } from "react";
import CustomSpinner from "../../Ui/Spinner";
import axios from "axios";

export default function AddVideoModlues({
  onFinshed,
  projectId,
  showNow,
  onHide,
}) {
  const [progress, setProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);

  const handleFormSubmite = async (value, { resetForm }) => {
    setIsUploading(true);
    const projectFormData = new FormData();
    projectFormData.append("video_url", value.videoUrl);

    const token = JSON.parse(localStorage.getItem("userData")).token;
    await axios
      .request(
        `${process.env.REACT_APP_PUBLIC_API}/api/dashbord/project/addProjectVideo/${projectId}`,
        {
          method: "POST",
          maxBodyLength: Infinity,
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
          data: projectFormData,
          onUploadProgress: (e) => {
            const uploadedRateData = Math.floor(
              Math.round(100 * e.loaded) / e.total
            );
            setProgress(uploadedRateData);
          },
        }
      )
      .then((result) => {
        setIsUploading(false);
        if (result.data.message === "Data Updated successfully") {
          swal("Done!", result.data.message, "success");
          resetForm();
          setProgress(0);
          setIsUploading(false);
          onHide();
          onFinshed();
        } else {
          swal("Error", result.data.message, "error");
          setProgress(0);
          setIsUploading(false);
        }
      })
      .catch((error) => {
        swal("Oops", `${error}`, "error");
        setProgress(0);
        setIsUploading(false);
      });
  };

  return (
    <>
      <Modal className="fade bd-example-modal-lg" show={showNow} size="lg">
        <Modal.Header>
          <Modal.Title>Add Project Reels</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => {
              setProgress(0);
              setIsUploading(false);
              onHide();
            }}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={{
              projectId: "",
              videoUrl: "",
            }}
            onSubmit={handleFormSubmite}
          >
            {({
              values,
              errors,
              setValues,
              handleChange,
              handleBlur,
              handleSubmit,
              handleReset,
            }) => (
              <form onSubmit={handleSubmit}>
                {/* Image Inputs Data */}
                <div className="row">
                  <div
                    className={` col-lg-12 col-sm-12 form-group mb-3 ${
                      values.videoUrl
                        ? errors.videoUrl
                          ? "is-invalid"
                          : "is-valid"
                        : ""
                    }`}
                  >
                    <label className="text-label">Enter Reel URL</label>
                    <div className="input-group">
                      <span className="input-group-text">
                        <i className="fa-solid fa-file-signature"></i>
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter a reel url.."
                        name="videoUrl"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.videoUrl}
                      />
                      <div
                        id="val-videoUrl-error"
                        className="invalid-feedback animated fadeInUp"
                        style={{ display: "block" }}
                      >
                        {errors.videoUrl && errors.videoUrl}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  {isUploading && (
                    <>
                      <h5>uploading Data now please waite...</h5>

                      <div className="progress-box">
                        <div className="d-flex align-items-center justify-content-between">
                          <h5 className="mb-0 fs-14 font-w600">
                            Uploading Data now.., please don't refresh
                          </h5>
                          <span className="font-w600" id="progress-span">
                            {progress}%
                          </span>
                        </div>
                        <div className="progress ">
                          <div
                            className="progress-bar bg-primary"
                            id="progress-inner-width"
                            style={{
                              width: `${progress}%`,
                              height: "12px",
                              borderRadius: "4px",
                            }}
                            role="progressbar"
                          ></div>
                        </div>
                      </div>
                    </>
                  )}
                </div>

                {isUploading ? (
                  <>
                    <button className="btn me-2 btn-primary" disabled>
                      <CustomSpinner />
                    </button>
                  </>
                ) : (
                  <>
                    <button type="submit" className="btn me-2 btn-primary">
                      Submit
                    </button>
                  </>
                )}
              </form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
}
