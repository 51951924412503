import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { checkErrorMessage } from "../../../services/ErrorMessage";

export const DeleteMemberApi = createAsyncThunk("team/delete", async (_id) => {
  const token = JSON.parse(localStorage.getItem("userData")).token;
  const request = await fetch(
    `${process.env.REACT_APP_PUBLIC_API}/api/team/deleteById/${_id}`,
    {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  )
    .then((response) => response.json())
    .then((result) => result)
    .catch((error) => error);

  return request;
});

const deleteTeamMemberSlicer = createSlice({
  name: "deleteUser",
  initialState: {
    loading: false,
    data: null,
    error: null,
  },
  extraReducers: (builder) => {
    builder.addCase(DeleteMemberApi.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(DeleteMemberApi.fulfilled, (state, action) => {
      state.loading = false;

      if (action.payload.message === "Data Deleted successfully") {
        state.data = action.payload.message;
      } else {
        state.error = checkErrorMessage(action.payload.message);
      }
    });
    builder.addCase(DeleteMemberApi.rejected, (state, action) => {
      state.loading = false;
      state.error = checkErrorMessage(action.payload.message);
    });
  },
});

export default deleteTeamMemberSlicer.reducer;
