import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import swal from "sweetalert";
import { useDispatch, useSelector } from "react-redux";
import { getAllMessageApi } from "../../../../store/Slicers/Intership/getAllMessagesSlicer";
import CustomGrowSpinner from "../../Ui/CustomGrowSpinner";
import { DeleteMessageApi } from "../../../../store/Slicers/Intership/DeleteMessageSlicer";
import { getByIdMessageApi } from "../../../../store/Slicers/Intership/getByIdMessageSlicer";
import { getAllCarrersPoionsDataApi } from "../../../../store/Slicers/carrers/getAllCarrersSlicer";
import { Formik } from "formik";
import * as Yup from "yup";
import CustomSpinner from "../../Ui/Spinner";
import { createNewCarrerApi } from "../../../../store/Slicers/carrers/CreateCarrersSlicer";
import { deletePostionApi } from "../../../../store/Slicers/carrers/deletePostionsSlicer";
import { updatePostionApi } from "../../../../store/Slicers/carrers/updateCarrersSlicer";
import { updateVisabilityPostionApi } from "../../../../store/Slicers/carrers/updateVisabilityCarrersSlicer";

const createPostion = Yup.object().shape({
  enpostion: Yup.string()
    .min(3, "Your postion must consist of at least 3 characters ")
    .max(200, "Your postion must consist of at most 200 characters ")
    .required("Please enter a postion"),

  enloacation: Yup.string()
    .min(3, "Your loacation must consist of at least 3 characters ")
    .max(200, "Your loacation must consist of at most 50 characters ")
    .required("Please enter a loacation"),

  entype: Yup.string()
    .min(3, "Your type must consist of at least 3 characters ")
    .max(3000, "Your type must consist of at most 50 characters ")
    .required("Please enter a type"),

  arpostion: Yup.string()
    .min(3, "Your postion must consist of at least 3 characters ")
    .max(200, "Your postion must consist of at most 200 characters ")
    .required("Please enter a postion"),

  arloacation: Yup.string()
    .min(3, "Your loacation must consist of at least 3 characters ")
    .max(200, "Your loacation must consist of at most 50 characters ")
    .required("Please enter a loacation"),

  artype: Yup.string()
    .min(3, "Your type must consist of at least 3 characters ")
    .max(3000, "Your type must consist of at most 50 characters ")
    .required("Please enter a type"),
});

const PostionsDisplayTableData = () => {
  const { loading, error } = useSelector((s) => s.getAllCarrersSlicer);
  const { loading: create_loading } = useSelector((s) => s.getAllCarrersSlicer);
  const { loading: update_loading } = useSelector((s) => s.updateCarrersSlicer);
  const { loading: loadongDelete, error: errorDelete } = useSelector(
    (s) => s.deletePostionsSlicer
  );
  const dispatch = useDispatch();
  const [postions, setPostions] = useState([]);

  //Modal box
  const [addCard, setAddCard] = useState(false);
  const [updateCard, setUpdateCard] = useState(false);

  //Add data
  const [displayPostion, setDisplayPostion] = useState({
    en: {
      postion: "",
      location: "",
      type: "",
    },
    ar: {
      postion: "",
      location: "",
      type: "",
    },
    id: "",
  });

  //   // Add contact function
  const handleOpenMessage = (data) => {
    setDisplayPostion({
      en: {
        postion: data.en.postion,
        location: data.en.location,
        type: data.en.type,
      },
      ar: {
        postion: data.ar.postion,
        location: data.ar.location,
        type: data.ar.type,
      },
      id: data._id,
    });
    setUpdateCard(true);
  };

  //   // delete data
  const handleDeleteClick = (id) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this data",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        dispatch(deletePostionApi(id)).then((res) => {
          if (res.payload.message === "Postion deleted successfully") {
            swal("!!Successful", "Poof! Postion has been deleted!", "success");
            getAllPostions();
          } else {
            swal("Error!", errorDelete, "error");
          }
        });
      } else {
        swal("The data is safe!");
      }
    });
  };

  const handleFormUpdateSubmite = (values, { resetFrom }) => {
    const data = {
      data: {
        en: {
          postion: values.enpostion,
          location: values.enloacation,
          type: values.entype,
        },
        ar: {
          postion: values.arpostion,
          location: values.arloacation,
          type: values.artype,
        },
      },
      id: values.id,
    };

    dispatch(updatePostionApi(data)).then((res) => {
      if (res.payload.message === "Postion updated successfully") {
        swal("Success", res.payload.message, "success");
        setUpdateCard(false);
        getAllPostions();
        resetFrom();
        setDisplayPostion({
          en: {
            postion: "",
            location: "",
            type: "",
          },
          ar: {
            postion: "",
            location: "",
            type: "",
          },
          id: "",
        });
      } else {
        swal("!Error", res.payload.message, "error");
      }
    });
  };
  const handleFormSubmite = (values, { resetFrom }) => {
    console.log(values);

    const data = {
      en: {
        postion: values.enpostion,
        location: values.enloacation,
        type: values.entype,
      },
      ar: {
        postion: values.arpostion,
        location: values.arloacation,
        type: values.artype,
      },
    };

    dispatch(createNewCarrerApi(data)).then((res) => {
      if (res.payload.message === "Postion created successfully") {
        swal("Success", res.payload.message, "success");
        setAddCard(false);
        getAllPostions();
        resetFrom();
      } else {
        swal("!Error", res.payload.message, "error");
      }
    });
  };

  const updateVisability = (id) => {
    dispatch(updateVisabilityPostionApi(id)).then((res) => {
      if (res.payload.message === "Postion updated successfully") {
        swal("Success", res.payload.message, "success");
        getAllPostions();
      } else {
        swal("Error", res.payload.message, "error");
      }
    });
  };

  const getAllPostions = () => {
    dispatch(getAllCarrersPoionsDataApi()).then((res) => {
      if (res.payload.message === "Data fetched successfully") {
        setPostions(res.payload.data);
      }
    });
  };

  useEffect(() => {
    getAllPostions();
  }, []);

  return (
    <>
      {loading ? (
        <>
          <div className="col-12 d-flex justify-content-center gap-3">
            <CustomGrowSpinner />
            <CustomGrowSpinner />
            <CustomGrowSpinner />
          </div>
        </>
      ) : (
        <>
          {error ? (
            <>
              <div className="col-12 d-flex justify-content-center gap-3">
                <h2>{error}</h2>
              </div>
            </>
          ) : (
            <>
              <div className="col-12">
                <Modal
                  className="modal fade"
                  show={updateCard}
                  onHide={() => {
                    setUpdateCard(false);
                  }}
                  size="lg"
                >
                  <div className="" role="document">
                    <div className="">
                      <div className="modal-header">
                        <h4 className="modal-title fs-20">
                          Update Postion Data
                        </h4>
                        <button
                          type="button"
                          className="btn-close"
                          onClick={() => {
                            setUpdateCard(false);
                          }}
                          data-dismiss="modal"
                        >
                          <span></span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <div className="add-contact-box">
                          <div className="add-contact-content">
                            <Formik
                              initialValues={{
                                enpostion: displayPostion.en.postion,
                                enloacation: displayPostion.en.location,
                                entype: displayPostion.en.type,

                                arpostion: displayPostion.ar.postion,
                                arloacation: displayPostion.ar.location,
                                artype: displayPostion.ar.type,

                                id: displayPostion.id,
                              }}
                              validationSchema={createPostion}
                              onSubmit={handleFormUpdateSubmite}
                            >
                              {({
                                values,
                                errors,
                                setValues,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                handleReset,
                              }) => (
                                <form onSubmit={handleSubmit}>
                                  {/* English Inputs Data */}
                                  <div className="row">
                                    <div
                                      className="card-header"
                                      style={{
                                        border: "transparent",
                                      }}
                                    >
                                      <h4 className="card-title">
                                        Englich Data
                                      </h4>
                                    </div>
                                    {/* Englich postion  */}
                                    <div
                                      className={` col-lg-12 col-sm-12 form-group mb-3 ${
                                        values.enpostion
                                          ? errors.enpostion
                                            ? "is-invalid"
                                            : "is-valid"
                                          : ""
                                      }`}
                                    >
                                      <label className="text-label">
                                        English Postion Title
                                      </label>
                                      <div className="input-group">
                                        <span className="input-group-text">
                                          <i className="fa-solid fa-file-signature"></i>
                                        </span>
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Enter a english postion title.."
                                          name="enpostion"
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          value={values.enpostion}
                                        />
                                        <div
                                          id="val-enpostion-error"
                                          className="invalid-feedback animated fadeInUp"
                                          style={{ display: "block" }}
                                        >
                                          {errors.enpostion && errors.enpostion}
                                        </div>
                                      </div>
                                    </div>
                                    {/* Englich location  */}
                                    <div
                                      className={` col-lg-6 col-sm-12 form-group mb-3 ${
                                        values.enloacation
                                          ? errors.enloacation
                                            ? "is-invalid"
                                            : "is-valid"
                                          : ""
                                      }`}
                                    >
                                      <label className="text-label">
                                        English Postion Location
                                      </label>
                                      <div className="input-group">
                                        <span className="input-group-text">
                                          <i className="fa-solid fa-file-signature"></i>
                                        </span>
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Enter a english postion location.."
                                          name="enloacation"
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          value={values.enloacation}
                                        />
                                        <div
                                          id="val-enloacation-error"
                                          className="invalid-feedback animated fadeInUp"
                                          style={{ display: "block" }}
                                        >
                                          {errors.enloacation &&
                                            errors.enloacation}
                                        </div>
                                      </div>
                                    </div>
                                    {/* Englich type  */}
                                    <div
                                      className={` col-lg-6 col-sm-12 form-group mb-3 ${
                                        values.entype
                                          ? errors.entype
                                            ? "is-invalid"
                                            : "is-valid"
                                          : ""
                                      }`}
                                    >
                                      <label className="text-label">
                                        English Postion Type
                                      </label>
                                      <div className="input-group">
                                        <span className="input-group-text">
                                          <i className="fa-solid fa-file-signature"></i>
                                        </span>
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Enter a english postion type.."
                                          name="entype"
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          value={values.entype}
                                        />
                                        <div
                                          id="val-entype-error"
                                          className="invalid-feedback animated fadeInUp"
                                          style={{ display: "block" }}
                                        >
                                          {errors.entype && errors.entype}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  {/* Arabic Inputs Data */}
                                  <div className="row" dir="rtl">
                                    <div
                                      className="card-header"
                                      style={{
                                        border: "transparent",
                                      }}
                                    >
                                      <h4 className="card-title">
                                        البيانات بالعربي
                                      </h4>
                                    </div>
                                    {/* Arabic postion  */}
                                    <div
                                      className={` col-lg-12 col-sm-12 form-group mb-3 ${
                                        values.arpostion
                                          ? errors.arpostion
                                            ? "is-invalid"
                                            : "is-valid"
                                          : ""
                                      }`}
                                    >
                                      <label className="text-label">
                                        عنوان الوظيفة بالعربي
                                      </label>
                                      <div className="input-group">
                                        <span
                                          className="input-group-text"
                                          style={{
                                            borderRadius: "0.5rem",
                                            borderTopLeftRadius: "0",
                                            borderBottomLeftRadius: "0",
                                          }}
                                        >
                                          <i className="fa-solid fa-file-signature"></i>
                                        </span>
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="ادخل عنوان الوظيفة .."
                                          name="arpostion"
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          value={values.arpostion}
                                        />
                                        <div
                                          id="val-arpostion-error"
                                          className="invalid-feedback animated fadeInUp"
                                          style={{ display: "block" }}
                                        >
                                          {errors.arpostion && errors.arpostion}
                                        </div>
                                      </div>
                                    </div>
                                    {/* Arabic location  */}
                                    <div
                                      className={` col-lg-6 col-sm-12 form-group mb-3 ${
                                        values.arloacation
                                          ? errors.arloacation
                                            ? "is-invalid"
                                            : "is-valid"
                                          : ""
                                      }`}
                                    >
                                      <label className="text-label">
                                        موقع العمل بالعربي
                                      </label>
                                      <div className="input-group">
                                        <span
                                          className="input-group-text"
                                          style={{
                                            borderRadius: "0.5rem",
                                            borderTopLeftRadius: "0",
                                            borderBottomLeftRadius: "0",
                                          }}
                                        >
                                          <i className="fa-solid fa-file-signature"></i>
                                        </span>
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="ادخل موقع العمل .."
                                          name="arloacation"
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          value={values.arloacation}
                                        />
                                        <div
                                          id="val-arloacation-error"
                                          className="invalid-feedback animated fadeInUp"
                                          style={{ display: "block" }}
                                        >
                                          {errors.arloacation &&
                                            errors.arloacation}
                                        </div>
                                      </div>
                                    </div>
                                    {/* Arabic type  */}
                                    <div
                                      className={` col-lg-6 col-sm-12 form-group mb-3 ${
                                        values.artype
                                          ? errors.artype
                                            ? "is-invalid"
                                            : "is-valid"
                                          : ""
                                      }`}
                                    >
                                      <label className="text-label">
                                        نوع الوظيفة بالعربي
                                      </label>
                                      <div className="input-group">
                                        <span
                                          className="input-group-text"
                                          style={{
                                            borderRadius: "0.5rem",
                                            borderTopLeftRadius: "0",
                                            borderBottomLeftRadius: "0",
                                          }}
                                        >
                                          <i className="fa-solid fa-file-signature"></i>
                                        </span>
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="ادخل نوع الوظيفة.."
                                          name="artype"
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          value={values.artype}
                                        />
                                        <div
                                          id="val-artype-error"
                                          className="invalid-feedback animated fadeInUp"
                                          style={{ display: "block" }}
                                        >
                                          {errors.artype && errors.artype}
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  {update_loading ? (
                                    <>
                                      <button
                                        className="btn me-2 btn-primary"
                                        disabled
                                      >
                                        <CustomSpinner />
                                      </button>
                                    </>
                                  ) : (
                                    <>
                                      <button
                                        type="submit"
                                        className="btn me-2 btn-primary"
                                      >
                                        update
                                      </button>
                                    </>
                                  )}
                                  <button
                                    className="btn me-2 btn-danger"
                                    type="button"
                                    onClick={() => {
                                      setDisplayPostion({
                                        en: {
                                          postion: "",
                                          location: "",
                                          type: "",
                                        },
                                        ar: {
                                          postion: "",
                                          location: "",
                                          type: "",
                                        },
                                        id: "",
                                      });
                                      setUpdateCard(false);
                                    }}
                                  >
                                    Close
                                  </button>
                                </form>
                              )}
                            </Formik>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Modal>
                <Modal
                  className="modal fade"
                  show={addCard}
                  onHide={() => setAddCard(false)}
                  size="lg"
                >
                  <div className="" role="document">
                    <div className="">
                      <div className="modal-header">
                        <h4 className="modal-title fs-20">Add New Postion</h4>
                        <button
                          type="button"
                          className="btn-close"
                          onClick={() => {
                            //getAllMessages();
                            setAddCard(false);
                          }}
                          data-dismiss="modal"
                        >
                          <span></span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <div className="add-contact-box">
                          <div className="add-contact-content">
                            <Formik
                              initialValues={{
                                enpostion: "",
                                enloacation: "",
                                entype: "",

                                arpostion: "",
                                arloacation: "",
                                artype: "",
                              }}
                              validationSchema={createPostion}
                              onSubmit={handleFormSubmite}
                            >
                              {({
                                values,
                                errors,
                                setValues,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                handleReset,
                              }) => (
                                <form onSubmit={handleSubmit}>
                                  {/* English Inputs Data */}
                                  <div className="row">
                                    <div
                                      className="card-header"
                                      style={{
                                        border: "transparent",
                                      }}
                                    >
                                      <h4 className="card-title">
                                        Englich Data
                                      </h4>
                                    </div>
                                    {/* Englich postion  */}
                                    <div
                                      className={` col-lg-12 col-sm-12 form-group mb-3 ${
                                        values.enpostion
                                          ? errors.enpostion
                                            ? "is-invalid"
                                            : "is-valid"
                                          : ""
                                      }`}
                                    >
                                      <label className="text-label">
                                        English Postion Title
                                      </label>
                                      <div className="input-group">
                                        <span className="input-group-text">
                                          <i className="fa-solid fa-file-signature"></i>
                                        </span>
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Enter a english postion title.."
                                          name="enpostion"
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          value={values.enpostion}
                                        />
                                        <div
                                          id="val-enpostion-error"
                                          className="invalid-feedback animated fadeInUp"
                                          style={{ display: "block" }}
                                        >
                                          {errors.enpostion && errors.enpostion}
                                        </div>
                                      </div>
                                    </div>
                                    {/* Englich location  */}
                                    <div
                                      className={` col-lg-6 col-sm-12 form-group mb-3 ${
                                        values.enloacation
                                          ? errors.enloacation
                                            ? "is-invalid"
                                            : "is-valid"
                                          : ""
                                      }`}
                                    >
                                      <label className="text-label">
                                        English Postion Location
                                      </label>
                                      <div className="input-group">
                                        <span className="input-group-text">
                                          <i className="fa-solid fa-file-signature"></i>
                                        </span>
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Enter a english postion location.."
                                          name="enloacation"
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          value={values.enloacation}
                                        />
                                        <div
                                          id="val-enloacation-error"
                                          className="invalid-feedback animated fadeInUp"
                                          style={{ display: "block" }}
                                        >
                                          {errors.enloacation &&
                                            errors.enloacation}
                                        </div>
                                      </div>
                                    </div>
                                    {/* Englich type  */}
                                    <div
                                      className={` col-lg-6 col-sm-12 form-group mb-3 ${
                                        values.entype
                                          ? errors.entype
                                            ? "is-invalid"
                                            : "is-valid"
                                          : ""
                                      }`}
                                    >
                                      <label className="text-label">
                                        English Postion Type
                                      </label>
                                      <div className="input-group">
                                        <span className="input-group-text">
                                          <i className="fa-solid fa-file-signature"></i>
                                        </span>
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Enter a english postion type.."
                                          name="entype"
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          value={values.entype}
                                        />
                                        <div
                                          id="val-entype-error"
                                          className="invalid-feedback animated fadeInUp"
                                          style={{ display: "block" }}
                                        >
                                          {errors.entype && errors.entype}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  {/* Arabic Inputs Data */}
                                  <div className="row" dir="rtl">
                                    <div
                                      className="card-header"
                                      style={{
                                        border: "transparent",
                                      }}
                                    >
                                      <h4 className="card-title">
                                        البيانات بالعربي
                                      </h4>
                                    </div>
                                    {/* Arabic postion  */}
                                    <div
                                      className={` col-lg-12 col-sm-12 form-group mb-3 ${
                                        values.arpostion
                                          ? errors.arpostion
                                            ? "is-invalid"
                                            : "is-valid"
                                          : ""
                                      }`}
                                    >
                                      <label className="text-label">
                                        عنوان الوظيفة بالعربي
                                      </label>
                                      <div className="input-group">
                                        <span
                                          className="input-group-text"
                                          style={{
                                            borderRadius: "0.5rem",
                                            borderTopLeftRadius: "0",
                                            borderBottomLeftRadius: "0",
                                          }}
                                        >
                                          <i className="fa-solid fa-file-signature"></i>
                                        </span>
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="ادخل عنوان الوظيفة .."
                                          name="arpostion"
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          value={values.arpostion}
                                        />
                                        <div
                                          id="val-arpostion-error"
                                          className="invalid-feedback animated fadeInUp"
                                          style={{ display: "block" }}
                                        >
                                          {errors.arpostion && errors.arpostion}
                                        </div>
                                      </div>
                                    </div>
                                    {/* Arabic location  */}
                                    <div
                                      className={` col-lg-6 col-sm-12 form-group mb-3 ${
                                        values.arloacation
                                          ? errors.arloacation
                                            ? "is-invalid"
                                            : "is-valid"
                                          : ""
                                      }`}
                                    >
                                      <label className="text-label">
                                        موقع العمل بالعربي
                                      </label>
                                      <div className="input-group">
                                        <span
                                          className="input-group-text"
                                          style={{
                                            borderRadius: "0.5rem",
                                            borderTopLeftRadius: "0",
                                            borderBottomLeftRadius: "0",
                                          }}
                                        >
                                          <i className="fa-solid fa-file-signature"></i>
                                        </span>
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="ادخل موقع العمل .."
                                          name="arloacation"
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          value={values.arloacation}
                                        />
                                        <div
                                          id="val-arloacation-error"
                                          className="invalid-feedback animated fadeInUp"
                                          style={{ display: "block" }}
                                        >
                                          {errors.arloacation &&
                                            errors.arloacation}
                                        </div>
                                      </div>
                                    </div>
                                    {/* Arabic type  */}
                                    <div
                                      className={` col-lg-6 col-sm-12 form-group mb-3 ${
                                        values.artype
                                          ? errors.artype
                                            ? "is-invalid"
                                            : "is-valid"
                                          : ""
                                      }`}
                                    >
                                      <label className="text-label">
                                        نوع الوظيفة بالعربي
                                      </label>
                                      <div className="input-group">
                                        <span
                                          className="input-group-text"
                                          style={{
                                            borderRadius: "0.5rem",
                                            borderTopLeftRadius: "0",
                                            borderBottomLeftRadius: "0",
                                          }}
                                        >
                                          <i className="fa-solid fa-file-signature"></i>
                                        </span>
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="ادخل نوع الوظيفة.."
                                          name="artype"
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          value={values.artype}
                                        />
                                        <div
                                          id="val-artype-error"
                                          className="invalid-feedback animated fadeInUp"
                                          style={{ display: "block" }}
                                        >
                                          {errors.artype && errors.artype}
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  {create_loading ? (
                                    <>
                                      <button
                                        className="btn me-2 btn-primary"
                                        disabled
                                      >
                                        <CustomSpinner />
                                      </button>
                                    </>
                                  ) : (
                                    <>
                                      <button
                                        type="submit"
                                        className="btn me-2 btn-primary"
                                      >
                                        Submit
                                      </button>
                                    </>
                                  )}
                                </form>
                              )}
                            </Formik>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Modal>
                <div className="card">
                  <div className="card-body">
                    <div className="widget-heading d-flex justify-content-between align-items-center">
                      <h3 className="m-0">All psotions</h3>
                      <button
                        className="btn btn-primary btn-sm"
                        onClick={() => setAddCard(true)}
                      >
                        Add New Postion
                      </button>
                    </div>
                    <div className="w-100 table-responsive">
                      <div id="example_wrapper" className="dataTables_wrapper">
                        {postions && (
                          <>
                            {postions.length > 0 ? (
                              <>
                                <table
                                  id="example"
                                  className="display w-100 dataTable"
                                >
                                  <thead>
                                    <tr>
                                      <th>Postion</th>
                                      <th>Location</th>
                                      <th>Type</th>
                                      <th>visable</th>
                                      <th>Action</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {postions.map((postion, index) => (
                                      <>
                                        <tr key={index}>
                                          <td>{postion?.en?.postion}</td>
                                          <td>{postion?.en?.location}</td>
                                          <td>{postion?.en?.type}</td>

                                          <td>
                                            {postion?.visable ? (
                                              <>
                                                <span
                                                  variant="success light"
                                                  class="badge bg-success"
                                                >
                                                  Visable
                                                </span>
                                              </>
                                            ) : (
                                              <>
                                                <span
                                                  variant="success light"
                                                  class="badge bg-danger"
                                                >
                                                  Not Visable
                                                </span>
                                              </>
                                            )}
                                          </td>

                                          <td>
                                            <div className="d-flex">
                                              {loadongDelete ? (
                                                <>
                                                  <CustomSpinner />
                                                </>
                                              ) : (
                                                <>
                                                  <a
                                                    className="btn btn-secondary shadow btn-xs sharp me-2"
                                                    onClick={() =>
                                                      handleOpenMessage(postion)
                                                    }
                                                  >
                                                    <i class="fa-solid fa-envelope-open"></i>
                                                  </a>
                                                  <a
                                                    className="btn btn-danger shadow btn-xs sharp me-2"
                                                    onClick={() =>
                                                      handleDeleteClick(
                                                        postion._id
                                                      )
                                                    }
                                                  >
                                                    <i className="fa fa-trash"></i>
                                                  </a>
                                                  <a
                                                    className="btn btn-primary shadow btn-xs sharp"
                                                    onClick={() =>
                                                        updateVisability(
                                                        postion._id
                                                      )
                                                    }
                                                  >
                                                   <i className="fa-regular fa-eye" />
                                                  </a>
                                                </>
                                              )}
                                            </div>
                                          </td>
                                        </tr>
                                      </>
                                    ))}
                                  </tbody>
                                </table>
                              </>
                            ) : (
                              <>
                                <div className="col-12 d-flex justify-content-center gap-3">
                                  <h2>No Postions Added Yet</h2>
                                </div>
                              </>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};
export default PostionsDisplayTableData;
