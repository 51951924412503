import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { checkErrorMessage } from "../../../services/ErrorMessage";

export const createMessegeApi = createAsyncThunk(
  "messege/create",
  async (messegeData) => {
    const token = JSON.parse(localStorage.getItem("userData")).token;
    const request = await fetch(
      `${process.env.REACT_APP_PUBLIC_API}/api/user/message/send-messages`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          subject: messegeData.subject,
          messege: messegeData.messege,
        }),
      }
    )
      .then((res) => res.json())
      .then((result) => result)
      .catch((error) => error);
    return request;
  }
);

const messegesCreateSlicer = createSlice({
  name: "createMessage",
  initialState: {
    loading: false,
    data: null,
    error: null,
  },
  extraReducers: (builder) => {
    builder.addCase(createMessegeApi.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(createMessegeApi.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
    });

    builder.addCase(createMessegeApi.rejected, (state, action) => {
      state.loading = false;
      state.error = checkErrorMessage(action.payload.message);
    });
  },
});

export default messegesCreateSlicer.reducer;
