import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { checkErrorMessage } from "../../../services/ErrorMessage";

export const updateUserApi = createAsyncThunk(
  "user/update",
  async ({_id,value}) => {
    console.log(_id);
    console.log(value);
    let raw;
    if (value.password) {
      raw = {...value};
    } else {
      raw ={
        name: value.name,
        email: value.email,
        mobile: value.mobile,
        title:value.title ,
        role: value.role
      }
    }
    console.log(raw);
    const token = JSON.parse(localStorage.getItem("userData")).token;
    const request = await fetch(
      `${process.env.REACT_APP_PUBLIC_API}/api/user/updateById/${_id}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify(raw),
      }
    )
      .then((response) => response.json())
      .then((result) => result)
      .catch((error) => error);

    return request;
  }
);

const updateUserSlicer = createSlice({
  name: "deleteUser",
  initialState: {
    loading: false,
    data: null,
    error: null,
  },
  extraReducers: (builder) => {
    builder.addCase(updateUserApi.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateUserApi.fulfilled, (state, action) => {
      state.loading = false;
    
      if (action.payload.message === "User Updated successfully") {
        state.data = action.payload.message;
      } else {
          state.error = checkErrorMessage(action.payload.message);
          console.log(state.error);
      }
    });
    builder.addCase(updateUserApi.rejected, (state, action) => {
      state.loading = false;
      state.error = checkErrorMessage(action.payload.message);
    });
  },
});

export default updateUserSlicer.reducer;
