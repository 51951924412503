import { Fragment } from "react";
import PageTitle from "../../layouts/PageTitle";
import AddTeamMemberForm from "./component/AddTeamMemberForm";

export default function AddTeamMember() {
  return (
    <>
      <Fragment>
      <PageTitle
        activeMenu="Add Team Member"
        motherMenu="Users"
      />


        <AddTeamMemberForm />
      </Fragment>
    </>
  );
}
