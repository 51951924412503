import { Fragment } from "react";
import PageTitle from "../../layouts/PageTitle";
import AddProjectForm from "./component/AddProjectForm";

export default function AddProjects() {
  return (
    <>
      <Fragment>
      <PageTitle
        activeMenu="Add Project"
        motherMenu="Projects"
      />

       <AddProjectForm/>

      </Fragment>
    </>
  );
}
