import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Tab,
  Nav,
  Row,
  Col,
  Card,
  Dropdown,
  Button,
  Modal,
  Carousel,
  Accordion,
} from "react-bootstrap";

import "react-modal-video/css/modal-video.min.css";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import { getServiceByIdApi } from "../../../store/Slicers/CServices/getSingleServicesSlicer";
import { useEffect } from "react";
import CustomGrowSpinner from "../Ui/CustomGrowSpinner";
import IMG from "../Ui/IMG";
import { updateVisableServiceApi } from "../../../store/Slicers/CServices/updateVisableSingleServicesSlicer";
import swal from "sweetalert";
import { Formik } from "formik";
import * as Yup from "yup";

import CustomSpinner from "../Ui/Spinner";
import { useRef } from "react";
import { createServicesApi } from "../../../store/Slicers/CServices/createServiceSlicer";
import { updateServicesApi } from "../../../store/Slicers/CServices/updateServiceSlicer";
import { getProjectByIdApi } from "../../../store/Slicers/Project/getSingleProjectSlicer";
import avatarServiceImage from "../../../images/avatar/service-avatar-img.jpg";
import { updateVisableProjectApi } from "../../../store/Slicers/Project/updateVisableSingleProjectSlicer";
import axios from "axios";
import AccordinImageDisplay from "./component/AccordinImageDisplay";
import AddImagesModlues from "./component/AddImagesModlues";
import { deleteProjectSingleImageApi } from "../../../store/Slicers/Project/deleteImagesSlicer";
import { updateDsiplayLayoutProjectApi } from "../../../store/Slicers/Project/updateDisplayLayoutSingleProjectSlicer";
import AccordingVideoDisplay from "./component/AccordingVideoDisplay";
import AddVideoModlues from "./component/AddVideoModlues";
import { deleteProjectSingleVideoApi } from "../../../store/Slicers/Project/deleteVideoSlicer";
import { showInHomeFunc } from "../../../store/Slicers/Project/ShowInHomeSlicer";

const loginSchema = Yup.object().shape({
  release_date: Yup.string()
    .min(3, "Your relese date must consist of at least 3 characters ")
    .max(200, "Your relese date must consist of at most 200 characters ")
    .required("Please enter a relese date"),
  enclient: Yup.string()
    .min(3, "Your name must consist of at least 3 characters ")
    .max(200, "Your name must consist of at most 200 characters ")
    .required("Please enter a client name"),
  enname: Yup.string()
    .min(3, "Your name must consist of at least 3 characters ")
    .max(200, "Your name must consist of at most 200 characters ")
    .required("Please enter a name"),
  entitle: Yup.string()
    .min(3, "Your description must consist of at least 3 characters ")
    .max(200, "Your description must consist of at most 200 characters ")
    .required("Please enter a description"),
  endescription: Yup.string()
    .min(3, "Your phragraph must consist of at least 3 characters ")
    .max(3000, "Your phragraph must consist of at most 3000 characters ")
    .required("Please enter a phragraph"),
  arclient: Yup.string()
    .min(3, "Your name must consist of at least 3 characters ")
    .max(200, "Your name must consist of at most 200 characters ")
    .required("Please enter a client name"),
  arname: Yup.string()
    .min(3, "Your name must consist of at least 3 characters ")
    .max(200, "Your name must consist of at most 200 characters ")
    .required("Please enter a name"),
  artitle: Yup.string()
    .min(3, "Your description must consist of at least 3 characters ")
    .max(200, "Your description must consist of at most 200 characters ")
    .required("Please enter a description"),
  ardescription: Yup.string()
    .min(3, "Your phragraph must consist of at least 3 characters ")
    .max(3000, "Your phragraph must consist of at most 3000 characters ")
    .required("Please enter a phragraph"),
});

function CUSIMG({ url, title }) {
  return (
    <>
      {url && url !== "" ? (
        <>
          <img
            style={{ width: "100%" }}
            src={`${process.env.REACT_APP_PUBLIC_API}/${url}`}
            title={title ? title : "avatar service image"}
          />
        </>
      ) : (
        <>
          <img
            style={{ width: "100%" }}
            src={avatarServiceImage}
            title={"avatar service image"}
          />
        </>
      )}
    </>
  );
}

const SingleProjectDetails = () => {
  const [largeModal, setLargeModal] = useState(false);
  const [imageUploaderShow, setImageUploaderShow] = useState(false);
  const [videoUploaderShow, setVideoUploaderShow] = useState(false);

  const handleOnHide = () => {
    setImageUploaderShow(false);
  };
  const handleOnHideVideo = () => {
    setVideoUploaderShow(false);
  };

  const { projectId } = useParams();
  const { loading, error } = useSelector((s) => s.getSingleProjectSlicer);
  const { loading: updateLoading, error: updateError } = useSelector(
    (s) => s.updateServiceSlicer
  );
  const { loading: visableLoading, error: visableError } = useSelector(
    (s) => s.updateVisableSingleProjectSlicer
  );
  const { loading: showInHomeLoading, error: showInHomeError } = useSelector(
    (s) => s.showInHomeSlicer
  );
  const { loading: diplaylayoutLoading, error: diplaylayoutError } =
    useSelector((s) => s.updateDisplayLayoutSingleProjectSlicer);
  const { error: deletedImageError } = useSelector((s) => s.deleteImagesSlicer);

  const dispatch = useDispatch();
  const [project, setProject] = useState(null);
  const [tempimg, setTempImg] = useState([]);
  const [progress, setProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);

  const [formIntilaData, setFormIntilaData] = useState({
    projectId: projectId,
    release_date: "",

    enname: "",
    entitle: "",
    endescription: "",
    enclient: "",

    arname: "",
    artitle: "",
    ardescription: "",
    arclient: "",
    service : "",

    images: [],
    imagesnames: "",
  });

  const getSingleProject = () => {
    dispatch(getProjectByIdApi(projectId)).then((res) => {
      if (res.payload.message === "Data Fetched successfully") {
        setProject(res.payload.data);
      }
    });
  };

  const setVisability = () => {
    dispatch(updateVisableProjectApi(project)).then((res) => {
      if (res.payload.message === "Data Updated successfully") {
        swal("Good job!", "Data Updated successfully!", "success");
        getSingleProject();
      } else {
        swal("Error!", visableError, "error");
      }
    });
  };
  const setShowInHomePage = () => {
    dispatch(showInHomeFunc(project)).then((res) => {
      if (res.payload.message === "Data Updated successfully") {
        swal("Good job!", "Data Updated successfully!", "success");
        getSingleProject();
      } else {
        swal("Error!", showInHomeError, "error");
      }
    });
  };
  const updateLayout = (number) => {
    const data = {
      _id: project._id,
      diplay_layout: number,
    };

    dispatch(updateDsiplayLayoutProjectApi(data)).then((res) => {
      if (res.payload.message === "Data Updated successfully") {
        swal("Good job!", "Data Updated successfully!", "success");
        getSingleProject();
      } else {
        swal("Error!", visableError, "error");
      }
    });
  };

  const fileRef = useRef(null);
  const [projectCat , setProjectCat] = useState()
  const handleFormSubmite = async (value, { resetForm }) => {
    console.log(value);
    setIsUploading(true);
    const projectFormData = new FormData();
    projectFormData.append("release_date", value.release_date);

    projectFormData.append("en[name]", value.enname);
    projectFormData.append("en[title]", value.entitle);
    projectFormData.append("en[description]", value.endescription);
    projectFormData.append("en[client_name]", value.enclient);

    projectFormData.append("ar[name]", value.arname);
    projectFormData.append("ar[title]", value.artitle);
    projectFormData.append("ar[description]", value.ardescription);
    projectFormData.append("ar[client_name]", value.arclient);
    projectCat ? projectFormData.append("category", projectCat) :  projectFormData.append("category", null);
    // projectFormData.append("ar[client_name]", value.arclient);

    if (value.images != []) {
      Array.from(value.images).map((element) => {
        projectFormData.append("images", element);
      });
    }

    const token = JSON.parse(localStorage.getItem("userData")).token;
    await axios
      .request(
        `${process.env.REACT_APP_PUBLIC_API}/api/dashbord/project/updateProjectById/${value.projectId}`,
        {
          method: "POST",
          maxBodyLength: Infinity,
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
          data: projectFormData,
          onUploadProgress: (e) => {
            const uploadedRateData = Math.floor(
              Math.round(100 * e.loaded) / e.total
            );
            setProgress(uploadedRateData);
          },
        }
      )
      .then((result) => {
        setIsUploading(false);
        if (result.data.message === "Data Updated successfully") {
          swal("Done!", result.data.message, "success");
          resetForm();
          setTempImg([]);
          setProgress(0);
          setIsUploading(false);
          setLargeModal(false);
          getSingleProject();
        } else {
          swal("Error", result.data.message, "error");
          setProgress(0);
          setIsUploading(false);
        }
      })
      .catch((error) => {
        swal("Oops", `${error}`, "error");
        setProgress(0);
        setIsUploading(false);
      });
  };

  const openFileUploader = () => {
    fileRef.current.click();
  };

  const handleImageDelete = (imagePath) => {
    console.log(imagePath);
    const projectData = {
      projectId: projectId,
      image_path: imagePath,
    };
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover data again",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        dispatch(deleteProjectSingleImageApi(projectData)).then((res) => {
          if (res.payload.message === "Data Deleted successfully") {
            swal("Good job!", res.payload.message, "success");
            getSingleProject();
          } else {
            swal("Error!", deletedImageError, "error");
          }
        });
      } else {
        swal("The user data is safe!");
      }
    });
  };

  const handleVideoDelete = (video_url) => {
    const projectData = {
      projectId: projectId,
      video_url: video_url,
    };
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this data again",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        dispatch(deleteProjectSingleVideoApi(projectData)).then((res) => {
          if (res.payload.message === "Data Deleted successfully") {
            swal("Good job!", res.payload.message, "success");
            getSingleProject();
          } else {
            swal("Error!", deletedImageError, "error");
          }
        });
      } else {
        swal("The  data is safe!");
      }
    });
  };

  useEffect(() => {
    getSingleProject();
  }, []);
  const [categories , setCategories] = useState([]);
  const getAllCate = async () => {
    await fetch(`${process.env.REACT_APP_PUBLIC_API}/api/services/categoris`, {
      method: "GET",
    })
      .then((res) => {
        return res.json();
      })
      .then((ressult) => {
        setCategories(ressult.data);
      })
      .catch((error) => error);
  };

  useEffect(() => {
    getAllCate();
  }, []);
  useEffect(() => {
    console.log(formIntilaData)
  },[formIntilaData])
  return (
    <>
      <ol className="breadcrumb">
        <li className="breadcrumb-item active">
          <Link className="d-flex align-self-center" to={"/all-projects"}>
            <svg
              width="25"
              height="25"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.99981 12C8.99905 11.8684 9.02428 11.7379 9.07404 11.6161C9.12381 11.4942 9.19713 11.3834 9.28981 11.29L13.2898 7.28999C13.4781 7.10168 13.7335 6.9959 13.9998 6.9959C14.2661 6.9959 14.5215 7.10168 14.7098 7.28999C14.8981 7.47829 15.0039 7.73369 15.0039 7.99999C15.0039 8.26629 14.8981 8.52168 14.7098 8.70999L11.4098 12L14.6998 15.29C14.8636 15.4813 14.9492 15.7274 14.9395 15.979C14.9298 16.2307 14.8255 16.4695 14.6474 16.6475C14.4693 16.8256 14.2305 16.93 13.9789 16.9397C13.7272 16.9494 13.4811 16.8638 13.2898 16.7L9.28981 12.7C9.10507 12.5137 9.00092 12.2623 8.99981 12Z"
                fill="#374557"
              />
            </svg>
            Back
          </Link>
        </li>
      </ol>
      {loading ? (
        <>
          <div className="col-12 d-flex justify-content-center gap-3">
            <CustomGrowSpinner />
            <CustomGrowSpinner />
            <CustomGrowSpinner />
          </div>
        </>
      ) : (
        <>
          {error ? (
            <>
              <div className="col-12 d-flex justify-content-center gap-3">
                <h2>{error}</h2>
              </div>
            </>
          ) : (
            <>
              {project && (
                <>
                  {project != {} ? (
                    <>
                      <div className="row">
                        <div className="col-12">
                          <div>
                            <div className="card course-dedails-bx">
                              <div className="card-header border-0 pb-0">
                                {
                                  visableLoading || showInHomeLoading
                                  ?
                                  <CustomGrowSpinner />
                                  :
                                  <div className="d-flex flex-column">
                                    {project.visable ? (
                                      <>
                                        <h4
                                          style={{
                                            color: "green",
                                          }}
                                        >
                                          {(visableLoading) ? (
                                            <>
                                              <CustomGrowSpinner />
                                            </>
                                          ) : (
                                            <>Visable</>
                                          )}
                                        </h4>
                                      </>
                                    ) : (
                                      <>
                                        <h4
                                          style={{
                                            color: "red",
                                          }}
                                        >
                                          {(visableLoading) ? (
                                            <>
                                              <CustomGrowSpinner />
                                            </>
                                          ) : (
                                            <>Not Visable</>
                                          )}
                                        </h4>
                                      </>
                                    )}
                                    {project.is_work ? (
                                      <>
                                        <h4
                                          style={{
                                            color: "green",
                                          }}
                                        >
                                          {(showInHomeLoading) ? (
                                            <>
                                              <CustomGrowSpinner />
                                            </>
                                          ) : (
                                            <>Visable In  Home</>
                                          )}
                                        </h4>
                                      </>
                                    ) : (
                                      <>
                                        <h4
                                          style={{
                                            color: "red",
                                          }}
                                        >
                                          {(showInHomeLoading) ? (
                                            <>
                                              <CustomGrowSpinner />
                                            </>
                                          ) : (
                                            <>Not Visable In Home</>
                                          )}
                                        </h4>
                                      </>
                                    )}
                                  </div>
                                }

                                <Dropdown>
                                  <Dropdown.Toggle
                                    as="a"
                                    className="btn-link i-false btn sharp tp-btn-light btn-dark"
                                  >
                                    <svg
                                      width="24"
                                      height="25"
                                      viewBox="0 0 24 25"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M12.0012 9.86C11.6544 9.86 11.3109 9.92832 10.9905 10.061C10.67 10.1938 10.3789 10.3883 10.1336 10.6336C9.88835 10.8788 9.6938 11.17 9.56107 11.4905C9.42834 11.8109 9.36002 12.1544 9.36002 12.5012C9.36002 12.848 9.42834 13.1915 9.56107 13.5119C9.6938 13.8324 9.88835 14.1236 10.1336 14.3688C10.3789 14.6141 10.67 14.8086 10.9905 14.9413C11.3109 15.0741 11.6544 15.1424 12.0012 15.1424C12.7017 15.1422 13.3734 14.8638 13.8687 14.3684C14.3639 13.873 14.642 13.2011 14.6418 12.5006C14.6417 11.8001 14.3632 11.1284 13.8678 10.6332C13.3724 10.138 12.7005 9.85984 12 9.86H12.0012ZM3.60122 9.86C3.25437 9.86 2.91092 9.92832 2.59048 10.061C2.27003 10.1938 1.97887 10.3883 1.73361 10.6336C1.48835 10.8788 1.2938 11.17 1.16107 11.4905C1.02834 11.8109 0.960022 12.1544 0.960022 12.5012C0.960022 12.848 1.02834 13.1915 1.16107 13.5119C1.2938 13.8324 1.48835 14.1236 1.73361 14.3688C1.97887 14.6141 2.27003 14.8086 2.59048 14.9413C2.91092 15.0741 3.25437 15.1424 3.60122 15.1424C4.30171 15.1422 4.97345 14.8638 5.46866 14.3684C5.96387 13.873 6.24198 13.2011 6.24182 12.5006C6.24166 11.8001 5.96324 11.1284 5.46781 10.6332C4.97237 10.138 4.30051 9.85984 3.60002 9.86H3.60122ZM20.4012 9.86C20.0544 9.86 19.7109 9.92832 19.3905 10.061C19.07 10.1938 18.7789 10.3883 18.5336 10.6336C18.2884 10.8788 18.0938 11.17 17.9611 11.4905C17.8283 11.8109 17.76 12.1544 17.76 12.5012C17.76 12.848 17.8283 13.1915 17.9611 13.5119C18.0938 13.8324 18.2884 14.1236 18.5336 14.3688C18.7789 14.6141 19.07 14.8086 19.3905 14.9413C19.7109 15.0741 20.0544 15.1424 20.4012 15.1424C21.1017 15.1422 21.7734 14.8638 22.2687 14.3684C22.7639 13.873 23.042 13.2011 23.0418 12.5006C23.0417 11.8001 22.7632 11.1284 22.2678 10.6332C21.7724 10.138 21.1005 9.85984 20.4 9.86H20.4012Z"
                                        fill="#A098AE"
                                      />
                                    </svg>
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu
                                    className="dropdown-menu dropdown-menu-end"
                                    align="right"
                                  >
                                    <Dropdown.Item
                                      onClick={() => {
                                        setFormIntilaData({
                                          projectId: projectId,
                                          release_date: project.release_date,
                                          enname: project.en.name,
                                          entitle: project.en.title,
                                          endescription: project.en.description,
                                          enclient: project.en.client_name,
                                          arname: project.ar.name,
                                          artitle: project.ar.title,
                                          ardescription: project.ar.description,
                                          arclient: project.ar.client_name,
                                          service : project.serviceId,
                                          images: [],
                                          imagesnames: "",
                                        });
                                        setProjectCat(project.serviceId)
                                        setLargeModal(true);
                                      }}
                                    >
                                      Edit
                                    </Dropdown.Item>
                                    <Dropdown.Item onClick={setVisability}>
                                      {
                                        project.visable
                                        ?
                                        "Un Publish"
                                        :
                                        "Publish"
                                      }
                                    </Dropdown.Item>
                                    <Dropdown.Item onClick={setShowInHomePage}>
                                      {
                                        project.is_work
                                        ?
                                        "Hide From Home"
                                        :
                                        "Show In Home"
                                      }
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      onClick={() => setImageUploaderShow(true)}
                                    >
                                      Add Images
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      onClick={() => setVideoUploaderShow(true)}
                                    >
                                      Add Reels
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                              <div className="card-header border-0 pb-0 flex-column align-items-start">
                                <h2>Name : {project.en.name}</h2>

                                <h2>Relase Date : {project.release_date}</h2>
                                <h2>
                                  Display layout :
                                  {diplaylayoutLoading ? (
                                    <>
                                      <CustomGrowSpinner />
                                    </>
                                  ) : (
                                    <>
                                      {project.diplay_layout == 1 ? (
                                        <>
                                          <span>
                                            <button
                                              className="btn btn-primary mx-1"
                                              onClick={() => updateLayout(1)}
                                            >
                                              {project.diplay_layout}
                                            </button>

                                            <button
                                              className="btn btn-danger mx-1"
                                              onClick={() => updateLayout(2)}
                                            >
                                              2
                                            </button>
                                            <button
                                              className="btn btn-danger mx-1"
                                              onClick={() => updateLayout(4)}
                                            >
                                              4
                                            </button>
                                          </span>
                                        </>
                                      ) : (
                                        <>
                                          {project.diplay_layout == 2 ? (
                                            <>
                                              <span>
                                                <button
                                                  className="btn btn-danger mx-1"
                                                  onClick={() =>
                                                    updateLayout(1)
                                                  }
                                                >
                                                  1
                                                </button>

                                                <button
                                                  className="btn btn-primary mx-1"
                                                  onClick={() =>
                                                    updateLayout(2)
                                                  }
                                                >
                                                  {project.diplay_layout}
                                                </button>
                                                <button
                                                  className="btn btn-danger mx-1"
                                                  onClick={() =>
                                                    updateLayout(4)
                                                  }
                                                >
                                                  4
                                                </button>
                                              </span>
                                            </>
                                          ) : (
                                            <>
                                              <span>
                                                <button
                                                  className="btn btn-danger mx-1"
                                                  onClick={() =>
                                                    updateLayout(1)
                                                  }
                                                >
                                                  1
                                                </button>
                                                <button
                                                  className="btn btn-danger mx-1"
                                                  onClick={() =>
                                                    updateLayout(2)
                                                  }
                                                >
                                                  2
                                                </button>
                                                <button
                                                  className="btn btn-primary mx-1"
                                                  onClick={() =>
                                                    updateLayout(4)
                                                  }
                                                >
                                                  {project.diplay_layout}
                                                </button>
                                              </span>
                                            </>
                                          )}
                                        </>
                                      )}
                                    </>
                                  )}
                                </h2>
                              </div>
                              <div className="card-body pt-0">
                                <div className="course-details-tab style-2">
                                  <div className="custom-tab-1">
                                    <Tab.Container
                                      defaultActiveKey={"about-english"}
                                    >
                                      <Nav as="ul" className="nav-tabs">
                                        <Nav.Item as="li">
                                          <Nav.Link eventKey={"about-english"}>
                                            English Content
                                          </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item as="li">
                                          <Nav.Link eventKey={"about-arabic"}>
                                            Arabic Content
                                          </Nav.Link>
                                        </Nav.Item>
                                      </Nav>
                                      <Tab.Content className="pt-4">
                                        <Tab.Pane
                                          eventKey={"about-english"}
                                          style={{
                                            textAlign: "justify",
                                          }}
                                        >
                                          <h4 className="py-2">
                                            Client Name :
                                            <b>{project.en.client_name}</b>
                                          </h4>

                                          <h4>Project Name</h4>
                                          <p>{project.en.name}</p>
                                          <h4>Title</h4>
                                          <p>{project.en.title}</p>
                                          <h4>Description</h4>
                                          <p>{project.en.description}</p>
                                        </Tab.Pane>
                                        <Tab.Pane
                                          eventKey={"about-arabic"}
                                          dir="rtl"
                                          style={{
                                            textAlign: "justify",
                                          }}
                                        >
                                          <h4 className="py-2">
                                            اسم العميل :{" "}
                                            <b>{project.ar.client_name}</b>
                                          </h4>
                                          <h4>اسم المشروع </h4>
                                          <p>{project.ar.name}</p>
                                          <h4>العنوان</h4>
                                          <p>{project.ar.title}</p>
                                          <h4>وصف المشروع </h4>
                                          <p>{project.ar.description}</p>
                                        </Tab.Pane>
                                      </Tab.Content>
                                    </Tab.Container>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <Col>
                          <div className="row">
                            <Card className="col-lg-6 col-sm-12">
                              <Card.Body className="p-4">
                                <h4>Main image</h4>
                                <CUSIMG
                                  url={project.cover_image}
                                  className="d-block w-100"
                                  title={project.en.name}
                                />
                              </Card.Body>
                            </Card>

                            <div className="col-lg-6 col-sm-12 custome-accordion">
                              {" "}
                              {/* videos */}
                              <Accordion
                                className="accordion"
                                defaultActiveKey="0"
                              >
                                <Accordion.Item className="card" eventKey={`0`}>
                                  <Accordion.Header
                                    as="h2"
                                    className="accordion-header border-0"
                                  >
                                    <span className="acc-heading">Videos</span>
                                    <span className="ms-auto">
                                      ({project.videos.length})
                                    </span>
                                  </Accordion.Header>
                                  <Accordion.Collapse
                                    eventKey={`0`}
                                    id="collapseOne"
                                  >
                                    <div className="accordion-body card-body pt-0">
                                      {project.videos.map((url, i) => (
                                        <AccordingVideoDisplay
                                          key={i}
                                          videoUrl={url}
                                          index={i + 1}
                                          handleDelete={handleVideoDelete}
                                        />
                                      ))}
                                    </div>
                                  </Accordion.Collapse>
                                </Accordion.Item>
                              </Accordion>
                              {/* Images */}
                              <Accordion
                                className="accordion"
                                defaultActiveKey="0"
                              >
                                <Accordion.Item className="card" eventKey={`0`}>
                                  <Accordion.Header
                                    as="h2"
                                    className="accordion-header border-0"
                                  >
                                    <span className="acc-heading">Images</span>
                                    <span className="ms-auto">
                                      ({project.images.length})
                                    </span>
                                  </Accordion.Header>
                                  <Accordion.Collapse
                                    eventKey={`0`}
                                    id="collapseOne"
                                  >
                                    <div className="accordion-body card-body pt-0">
                                      {project.images.map((carousel, i) => (
                                        <AccordinImageDisplay
                                          key={i}
                                          imageUrl={carousel}
                                          imageName={project.en.name}
                                          handleDelete={handleImageDelete}
                                        />
                                      ))}
                                    </div>
                                  </Accordion.Collapse>
                                </Accordion.Item>
                              </Accordion>
                            </div>
                          </div>
                        </Col>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="col-12 d-flex justify-content-center gap-3">
                        <h2>Data Not Found </h2>
                      </div>
                    </>
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
      <Modal className="fade bd-example-modal-lg" show={largeModal} size="lg">
        <Modal.Header>
          <Modal.Title>Update Project</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setLargeModal(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={{
              projectId: projectId,

              release_date: formIntilaData.release_date,
              enname: formIntilaData.enname,
              entitle: formIntilaData.entitle,
              endescription: formIntilaData.endescription,
              enclient: formIntilaData.enclient,
              arname: formIntilaData.arname,
              artitle: formIntilaData.artitle,
              ardescription: formIntilaData.ardescription,
              arclient: formIntilaData.arclient,
              service: formIntilaData.service,
              images: [],
              imagname: "",
            }}
            validationSchema={loginSchema}
            onSubmit={handleFormSubmite}
          >
            {({
              values,
              errors,
              setValues,
              handleChange,
              handleBlur,
              handleSubmit,
              handleReset,
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div
                    className="card-header"
                    style={{
                      border: "transparent",
                    }}
                  >
                    <h4 className="card-title">Client Data</h4>
                  </div>
                  {/* Englich Name  */}
                  <div
                    className={` col-lg-6 col-sm-12 form-group mb-3 ${
                      values.enclient
                        ? errors.enclient
                          ? "is-invalid"
                          : "is-valid"
                        : ""
                    }`}
                  >
                    <label className="text-label">English Client Name</label>
                    <div className="input-group">
                      <span className="input-group-text">
                        <i className="fa-solid fa-file-signature"></i>
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter a english client name.."
                        name="enclient"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.enclient}
                      />
                      <div
                        id="val-enclient-error"
                        className="invalid-feedback animated fadeInUp"
                        style={{ display: "block" }}
                      >
                        {errors.enclient && errors.enclient}
                      </div>
                    </div>
                  </div>
                  {/* Arabic Name  */}
                  <div
                    className={`col-lg-6 col-sm-12 form-group mb-3 ${
                      values.arclient
                        ? errors.arclient
                          ? "is-invalid"
                          : "is-valid"
                        : ""
                    }`}
                  >
                    <label className="text-label">Arabic Client Name</label>
                    <div className="input-group">
                      <span className="input-group-text">
                        <i className="fa-solid fa-audio-description"></i>
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter a english client name .."
                        name="arclient"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.arclient}
                      />
                      <div
                        id="val-arclient-error"
                        className="invalid-feedback animated fadeInUp"
                        style={{ display: "block" }}
                      >
                        {errors.arclient && errors.arclient}
                      </div>
                    </div>
                  </div>
                  {/* Relase Date */}
                  <div
                    className={`col-lg-6 col-sm-12 form-group mb-3 ${
                      values.release_date
                        ? errors.release_date
                          ? "is-invalid"
                          : "is-valid"
                        : ""
                    }`}
                  >
                    <label className="text-label">Release date</label>
                    <div className="input-group">
                      <span className="input-group-text">
                        <i className="fa-solid fa-audio-description"></i>
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Relesed Date Ex Desamper 2022"
                        name="release_date"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.release_date}
                      />
                      <div
                        id="val-release_date-error"
                        className="invalid-feedback animated fadeInUp"
                        style={{ display: "block" }}
                      >
                        {errors.release_date && errors.release_date}
                      </div>
                    </div>
                  </div>
                  <div
                    className={`col-lg-6 col-sm-12 form-group mb-3 ${
                      values.categoryId
                        ? errors.categoryId
                          ? "is-invalid"
                          : "is-valid"
                        : ""
                    }`}
                  >
                    <label className="text-label">
                      Select Service Of Project
                    </label>
                    <div className="input-group transparent-append mb-2">
                      <select
                        className="form-control"
                        name="serviceId"
                        onChange={(e) => setProjectCat(e.target.value)}
                        onBlur={handleBlur}
                        // value={values.categoryId}
                        value={projectCat}
                        placeholder="Chose Project Service"
                      >
                        <option value={null}>
                          Without Category
                        </option>
                        {categories.map((data, index) => (
                          <option key={index} value={data._id}>
                            {data.en.name}
                          </option>
                        ))}
                      </select>
                      <div
                        id="val-categoryId-error"
                        className="invalid-feedback animated fadeInUp"
                        style={{ display: "block" }}
                      >
                        {errors.categoryId && errors.categoryId}
                      </div>
                    </div>
                  </div>












                </div>
                {/* Ebglish Inputs Data */}
                <div className="row">
                  <div
                    className="card-header"
                    style={{
                      border: "transparent",
                    }}
                  >
                    <h4 className="card-title">Englich Data</h4>
                  </div>
                  {/* Englich Name  */}
                  <div
                    className={` col-lg-6 col-sm-12 form-group mb-3 ${
                      values.enname
                        ? errors.enname
                          ? "is-invalid"
                          : "is-valid"
                        : ""
                    }`}
                  >
                    <label className="text-label">English Project Name</label>
                    <div className="input-group">
                      <span className="input-group-text">
                        <i className="fa-solid fa-file-signature"></i>
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter a english name.."
                        name="enname"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.enname}
                      />
                      <div
                        id="val-enname-error"
                        className="invalid-feedback animated fadeInUp"
                        style={{ display: "block" }}
                      >
                        {errors.enname && errors.enname}
                      </div>
                    </div>
                  </div>
                  {/* Englich Title  */}
                  <div
                    className={`col-lg-6 col-sm-12 form-group mb-3 ${
                      values.entitle
                        ? errors.entitle
                          ? "is-invalid"
                          : "is-valid"
                        : ""
                    }`}
                  >
                    <label className="text-label">English Project Title</label>
                    <div className="input-group">
                      <span className="input-group-text">
                        <i className="fa-solid fa-audio-description"></i>
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter a english title.."
                        name="entitle"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.entitle}
                      />
                      <div
                        id="val-entitle-error"
                        className="invalid-feedback animated fadeInUp"
                        style={{ display: "block" }}
                      >
                        {errors.entitle && errors.entitle}
                      </div>
                    </div>
                  </div>
                  {/* Englich Descriptions  */}
                  <div
                    className={`col-12 form-group mb-3 ${
                      values.endescription
                        ? errors.endescription
                          ? "is-invalid"
                          : "is-valid"
                        : ""
                    }`}
                  >
                    <label className="text-label">
                      English Project Description
                    </label>
                    <div className="input-group">
                      <textarea
                        type="text"
                        className="form-control"
                        placeholder="Enter a english description.."
                        name="endescription"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.endescription}
                        rows={8}
                        style={{ resize: "none" }}
                      ></textarea>

                      <div
                        id="val-endescription-error"
                        className="invalid-feedback animated fadeInUp"
                        style={{ display: "block" }}
                      >
                        {errors.endescription && errors.endescription}
                      </div>
                    </div>
                  </div>
                </div>
                {/* Arabic Inputs Data */}
                <div className="row" dir="rtl">
                  <div
                    className="card-header"
                    style={{
                      border: "transparent",
                    }}
                  >
                    <h4 className="card-title">البيانات بالعربي</h4>
                  </div>
                  {/* Arabic Name  */}
                  <div
                    className={`col-lg-6 col-sm-12 form-group mb-3 ${
                      values.arname
                        ? errors.arname
                          ? "is-invalid"
                          : "is-valid"
                        : ""
                    }`}
                  >
                    <label className="text-label">
                      اسم المشروع باللغة العربية
                    </label>
                    <div className="input-group">
                      <span
                        className="input-group-text"
                        style={{
                          borderRadius: "0.5rem",
                          borderTopLeftRadius: "0",
                          borderBottomLeftRadius: "0",
                        }}
                      >
                        <i className="fa-solid fa-file-signature"></i>
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter a arabic name.."
                        name="arname"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.arname}
                      />
                      <div
                        id="val-arname-error"
                        className="invalid-feedback animated fadeInUp"
                        style={{ display: "block" }}
                      >
                        {errors.arname && errors.arname}
                      </div>
                    </div>
                  </div>
                  {/* Arabic Title  */}
                  <div
                    className={`col-lg-6 col-sm-12 form-group mb-3 ${
                      values.artitle
                        ? errors.artitle
                          ? "is-invalid"
                          : "is-valid"
                        : ""
                    }`}
                  >
                    <label className="text-label">
                      عنوان قصير للمشروع باللغة العربية
                    </label>
                    <div className="input-group">
                      <span
                        className="input-group-text"
                        style={{
                          borderRadius: "0.5rem",
                          borderTopLeftRadius: "0",
                          borderBottomLeftRadius: "0",
                        }}
                      >
                        <i className="fa-solid fa-audio-description"></i>
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter a arabic title.."
                        name="artitle"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.artitle}
                      />
                      <div
                        id="val-artitle-error"
                        className="invalid-feedback animated fadeInUp"
                        style={{ display: "block" }}
                      >
                        {errors.artitle && errors.artitle}
                      </div>
                    </div>
                  </div>
                  {/* Arabic Description  */}
                  <div
                    className={`col-12 form-group mb-3 ${
                      values.ardescription
                        ? errors.ardescription
                          ? "is-invalid"
                          : "is-valid"
                        : ""
                    }`}
                  >
                    <label className="text-label">
                      اكتب وصف تفصيلي للمسشروع باللغة العربية
                    </label>
                    <div className="input-group">
                      <textarea
                        type="text"
                        className="form-control"
                        placeholder="Enter a arabic description.."
                        name="ardescription"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.ardescription}
                        rows={8}
                        style={{ resize: "none" }}
                      ></textarea>
                      <div
                        id="val-ardescription-error"
                        className="invalid-feedback animated fadeInUp"
                        style={{ display: "block" }}
                      >
                        {errors.ardescription && errors.ardescription}
                      </div>
                    </div>
                  </div>
                </div>
                {/* Image Inputs Data */}
                <div className="row">
                  <div
                    className="card-header"
                    style={{
                      border: "transparent",
                    }}
                  >
                    <h4 className="card-title">Uploade images</h4>
                  </div>
                  {/* image  */}
                  <div className={`col-lg-6 col-sm-12 form-group mb-3`}>
                    <label
                      onClick={openFileUploader}
                      className="text-label"
                      style={{
                        cursor: "pointer",
                        width: "100%",
                        padding: "2rem",
                        backgroundColor: "#1f88d9",
                        opacity: "0.5",
                        border: "3px dashed blue",
                        borderRadius: "10px",
                        color: "#111",
                        fontSize: "1rem",
                        textAlign: "center",
                        overflow: "hidden",
                      }}
                    >
                      {values.imagname ? values.imagname : "  Upload Images"}
                    </label>
                    <div className="input-group">
                      <input
                        multiple
                        accept="image/x-png,image/jpeg,image/jpg"
                        type="file"
                        className="form-control"
                        name="image"
                        hidden
                        ref={fileRef}
                        onChange={(e) => {
                          setTempImg([]);

                          setValues({
                            ...values,
                            imagname: e.target.files.length + " files selected",
                            images: e.target.files,
                          });
                          Array.from(e.target.files).map((data) => {
                            setTempImg((preVal) => [
                              ...preVal,
                              URL.createObjectURL(data),
                            ]);
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className={`col-lg-6 col-sm-12 form-group mb-3`}>
                    {tempimg && (
                      <>
                        {tempimg.map((data, index) => {
                          return (
                            <img
                              className="m-1"
                              key={index}
                              src={data}
                              width={50}
                            />
                          );
                        })}
                      </>
                    )}
                  </div>
                </div>

                <div className="row">
                  {isUploading && (
                    <>
                      <h5>uploading video now please waite...</h5>

                      <div className="progress-box">
                        <div className="d-flex align-items-center justify-content-between">
                          <h5 className="mb-0 fs-14 font-w600">
                            Uploading Images now.., please don't refresh
                          </h5>
                          <span className="font-w600" id="progress-span">
                            {progress}%
                          </span>
                        </div>
                        <div className="progress ">
                          <div
                            className="progress-bar bg-primary"
                            id="progress-inner-width"
                            style={{
                              width: `${progress}%`,
                              height: "12px",
                              borderRadius: "4px",
                            }}
                            role="progressbar"
                          ></div>
                        </div>
                      </div>
                    </>
                  )}
                </div>

                {isUploading ? (
                  <>
                    <button className="btn me-2 btn-primary" disabled>
                      <CustomSpinner />
                    </button>
                  </>
                ) : (
                  <>
                    <button type="submit" className="btn me-2 btn-primary">
                      Submit
                    </button>
                  </>
                )}
              </form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
      <AddImagesModlues
        onFinshed={getSingleProject}
        projectId={projectId}
        showNow={imageUploaderShow}
        onHide={handleOnHide}
      />
      <AddVideoModlues
        onFinshed={getSingleProject}
        projectId={projectId}
        showNow={videoUploaderShow}
        onHide={handleOnHideVideo}
      />
    </>
  );
};
export default SingleProjectDetails;
