import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { checkErrorMessage } from "../../../services/ErrorMessage";

export const editHomeDataApi = createAsyncThunk(
  "homeVideo/updateDatavisability",
  async (homeVideoDataId) => {
    const token = JSON.parse(localStorage.getItem("userData")).token;
    const request = await fetch(
      `${process.env.REACT_APP_PUBLIC_API}/api/home_video/update-home-video-visability/${homeVideoDataId}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((response) => response.json())
      .then((result) => result)
      .catch((error) => {
        checkErrorMessage(error);
        return error;
      });

    return request;
  }
);

const editHomeVideoSlicer = createSlice({
  name: "editHomeVideo",
  initialState: {
    loading: false,
    data: null,
    error: null,
  },
  extraReducers: (builder) => {
    builder.addCase(editHomeDataApi.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(editHomeDataApi.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload.message === "Data updated successfully") {
        state.data = action.payload.data;
      } else {
        state.error = checkErrorMessage(action.payload.message);
      }
    });
    builder.addCase(editHomeDataApi.rejected, (state, action) => {
      state.loading = false;
      state.error = checkErrorMessage(action.error);
    });
  },
});

export default editHomeVideoSlicer.reducer;
