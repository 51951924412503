import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { checkErrorMessage } from "../../../services/ErrorMessage";

export const deleteProjectSingleImageApi = createAsyncThunk(
  "project/deleteImage",
  async (projectData) => {
    console.log(projectData);

    const token = JSON.parse(localStorage.getItem("userData")).token;

    
    const request = await fetch(
      `${process.env.REACT_APP_PUBLIC_API}/api/dashbord/project/removeImage/${projectData.projectId}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          image_path: projectData.image_path,
        }),
      }
    )
      .then((res) => res.json())
      .then((result) => result)
      .catch((error) => error);
    return request;
  }
);

const deleteImagesSlicer = createSlice({
  name: "deleteProjectImage",
  initialState: {
    loading: false,
    data: null,
    error: null,
  },
  extraReducers: (builder) => {
    builder.addCase(deleteProjectSingleImageApi.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(deleteProjectSingleImageApi.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload.message === "Data Deleted successfully") {
        state.data = action.payload;
      } else {
        state.error = checkErrorMessage(action.payload.message);
      }
    });

    builder.addCase(deleteProjectSingleImageApi.rejected, (state, action) => {
      state.loading = false;
      state.error = checkErrorMessage(action.payload.message);
    });
  },
});

export default deleteImagesSlicer.reducer;
