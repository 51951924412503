import React, { Fragment, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import swal from "sweetalert";
import CustomSpinner from "../../Ui/Spinner";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { useRef } from "react";
import { createServicesApi } from "../../../../store/Slicers/CServices/createServiceSlicer";
import axios from "axios";

const loginSchema = Yup.object().shape({
  category: Yup.string()
    .min(3, "Category name must consist of at least 3 characters ")
    .max(100, "Categoryname must consist of at most 100 characters ")
    .required("Please enter a category")
    .matches(/^\S*$/, "category name must not have spaces"),
  enname: Yup.string()
    .min(3, "Your name must consist of at least 3 characters ")
    .max(200, "Your name must consist of at most 200 characters ")
    .required("Please enter a name"),
  endescription: Yup.string()
    .min(3, "Your description must consist of at least 3 characters ")
    .max(200, "Your description must consist of at most 200 characters ")
    .required("Please enter a description"),
  enphragraph: Yup.string()
    .min(3, "Your phragraph must consist of at least 3 characters ")
    .max(3000, "Your phragraph must consist of at most 3000 characters ")
    .required("Please enter a phragraph"),
  arname: Yup.string()
    .min(3, "Your name must consist of at least 3 characters ")
    .max(200, "Your name must consist of at most 200 characters ")
    .required("Please enter a name"),
  ardescription: Yup.string()
    .min(3, "Your description must consist of at least 3 characters ")
    .max(200, "Your description must consist of at most 200 characters ")
    .required("Please enter a description"),
  arphragraph: Yup.string()
    .min(3, "Your phragraph must consist of at least 3 characters ")
    .max(3000, "Your phragraph must consist of at most 3000 characters ")
    .required("Please enter a phragraph"),
});

const AddServiceForm = () => {
  const { loading, error } = useSelector((s) => s.createServiceSlicer);
  const dispatch = useDispatch();
  const [progress, setProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [tempimg, setTempImg] = useState("");
  const [tempimg2, setTempImg2] = useState("");

  const fileRef = useRef(null);
  const fileRef2 = useRef(null);

  const [formIntilaData, setFormIntilaData] = useState({
    category: "",
    enname: "",
    endescription: "",
    enphragraph: "",

    arname: "",
    ardescription: "",
    arphragraph: "",
  });

  const handleFormSubmite = async (value, { resetForm }) => {
    setIsUploading(true);
    console.log(value);
    // const serviceFormData = new FormData();
    // serviceFormData.append("category", value.category);
    // serviceFormData.append("en[name]", value.enname);
    // serviceFormData.append("en[description]", value.endescription);
    // serviceFormData.append("en[phragraph]", value.enphragraph);
    // serviceFormData.append("ar[name]", value.arname);
    // serviceFormData.append("ar[description]", value.ardescription);
    // serviceFormData.append("ar[phragraph]", value.arphragraph);
    // serviceFormData.append("image", value.image);
    // serviceFormData.append("image", value.home_image);


    const dataService = {
      category : value.category,
      en:{
        name : value.enname,
        description : value.endescription,
        phragraph : value.enphragraph,
      },
      ar:{
        name : value.arname,
        description : value.ardescription,
        phragraph : value.arphragraph,
      }
    }


    const token = JSON.parse(localStorage.getItem("userData")).token;
    await axios
      .request(`${process.env.REACT_APP_PUBLIC_API}/api/services/create`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: JSON.stringify(dataService),
        onUploadProgress: (e) => {
          const uploadedRateData = Math.floor(
            Math.round(100 * e.loaded) / e.total
          );
          setProgress(uploadedRateData);
        },
      })
      .then((result) => {
        setIsUploading(false);
        if (result.data.message === "Service created successfully") {
          swal("Done!", result.data.message, "success");
          resetForm();
          setTempImg("");
          setTempImg2("");
          setProgress(0);
          setIsUploading(false);
        } else {
          swal("Error", result.data.message, "error");
          setProgress(0);
          setIsUploading(false);
        }
      })
      .catch((error) => {
        swal("Oops", `${error}`, "error");
        setProgress(0);
        setIsUploading(false);
      });
  };

  const openFileUploader = () => {
    fileRef.current.click();
  };
  const openFileUploader2 = () => {
    fileRef2.current.click();
  };

  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">Add Service From</h4>
          </div>
          <div className="card-body">
            <div className="basic-form">
              <Formik
                initialValues={{
                  category: formIntilaData.category,
                  enname: formIntilaData.enname,
                  endescription: formIntilaData.endescription,
                  enphragraph: formIntilaData.enphragraph,

                  arname: formIntilaData.arname,
                  ardescription: formIntilaData.ardescription,
                  arphragraph: formIntilaData.arphragraph,

                
                }}
                validationSchema={loginSchema}
                onSubmit={handleFormSubmite}
              >
                {({
                  values,
                  errors,
                  setValues,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  handleReset,
                }) => (
                  <form onSubmit={handleSubmit}>
                    {/* Catefory Name */}
                    <div className="row">
                      <div
                        className="card-header"
                        style={{
                          border: "transparent",
                        }}
                      >
                        <h4 className="card-title">Catgory Name</h4>
                      </div>
                      {/* Englich Name  */}
                      <div
                        className={` col-lg-12 col-sm-12 form-group mb-3 ${
                          values.category
                            ? errors.category
                              ? "is-invalid"
                              : "is-valid"
                            : ""
                        }`}
                      >
                        <label className="text-label">
                          Service Category Name ( Wright unique name with no
                          spaces )
                        </label>
                        <div className="input-group">
                          <span className="input-group-text">
                            <i class="fa-solid fa-file-signature"></i>
                          </span>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter a unique name.."
                            name="category"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.category}
                          />
                          <div
                            id="val-category-error"
                            className="invalid-feedback animated fadeInUp"
                            style={{ display: "block" }}
                          >
                            {errors.category && errors.category}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* Ebglish Inputs Data */}
                    <div className="row">
                      <div
                        className="card-header"
                        style={{
                          border: "transparent",
                        }}
                      >
                        <h4 className="card-title">Englich Data</h4>
                      </div>
                      {/* Englich Name  */}
                      <div
                        className={` col-lg-6 col-sm-12 form-group mb-3 ${
                          values.enname
                            ? errors.enname
                              ? "is-invalid"
                              : "is-valid"
                            : ""
                        }`}
                      >
                        <label className="text-label">
                          English Service Name
                        </label>
                        <div className="input-group">
                          <span className="input-group-text">
                            <i class="fa-solid fa-file-signature"></i>
                          </span>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter a english name.."
                            name="enname"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.enname}
                          />
                          <div
                            id="val-enname-error"
                            className="invalid-feedback animated fadeInUp"
                            style={{ display: "block" }}
                          >
                            {errors.enname && errors.enname}
                          </div>
                        </div>
                      </div>
                      {/* Englich Descriptions  */}
                      <div
                        className={`col-lg-6 col-sm-12 form-group mb-3 ${
                          values.endescription
                            ? errors.endescription
                              ? "is-invalid"
                              : "is-valid"
                            : ""
                        }`}
                      >
                        <label className="text-label">
                          English Service Description
                        </label>
                        <div className="input-group">
                          <span className="input-group-text">
                            <i class="fa-solid fa-audio-description"></i>
                          </span>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter a english description.."
                            name="endescription"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.endescription}
                          />
                          <div
                            id="val-endescription-error"
                            className="invalid-feedback animated fadeInUp"
                            style={{ display: "block" }}
                          >
                            {errors.endescription && errors.endescription}
                          </div>
                        </div>
                      </div>
                      {/* Englich Phragraph  */}
                      <div
                        className={`col-12 form-group mb-3 ${
                          values.enphragraph
                            ? errors.enphragraph
                              ? "is-invalid"
                              : "is-valid"
                            : ""
                        }`}
                      >
                        <label className="text-label">
                          English Service Phragraph
                        </label>
                        <div className="input-group">
                          <textarea
                            type="text"
                            className="form-control"
                            placeholder="Enter a english phragraph.."
                            name="enphragraph"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.enphragraph}
                            rows={8}
                            style={{ resize: "none" }}
                          ></textarea>

                          <div
                            id="val-enphragraph-error"
                            className="invalid-feedback animated fadeInUp"
                            style={{ display: "block" }}
                          >
                            {errors.enphragraph && errors.enphragraph}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* Arabic Inputs Data */}
                    <div className="row">
                      <div
                        className="card-header"
                        style={{
                          border: "transparent",
                        }}
                      >
                        <h4 className="card-title">Arabic Data</h4>
                      </div>
                      {/* Arabic Name  */}
                      <div
                        className={`col-lg-6 col-sm-12 form-group mb-3 ${
                          values.arname
                            ? errors.arname
                              ? "is-invalid"
                              : "is-valid"
                            : ""
                        }`}
                      >
                        <label className="text-label">
                          Arabic Service Name
                        </label>
                        <div className="input-group">
                          <span className="input-group-text">
                            <i class="fa-solid fa-file-signature"></i>
                          </span>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter a arabic name.."
                            name="arname"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.arname}
                          />
                          <div
                            id="val-arname-error"
                            className="invalid-feedback animated fadeInUp"
                            style={{ display: "block" }}
                          >
                            {errors.arname && errors.arname}
                          </div>
                        </div>
                      </div>
                      {/* Arabic Descriptions  */}
                      <div
                        className={`col-lg-6 col-sm-12 form-group mb-3 ${
                          values.ardescription
                            ? errors.ardescription
                              ? "is-invalid"
                              : "is-valid"
                            : ""
                        }`}
                      >
                        <label className="text-label">
                          Arabic Service Description
                        </label>
                        <div className="input-group">
                          <span className="input-group-text">
                            <i class="fa-solid fa-audio-description"></i>
                          </span>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter a arabic description.."
                            name="ardescription"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.ardescription}
                          />
                          <div
                            id="val-ardescription-error"
                            className="invalid-feedback animated fadeInUp"
                            style={{ display: "block" }}
                          >
                            {errors.ardescription && errors.ardescription}
                          </div>
                        </div>
                      </div>
                      {/* Arabic Phragraph  */}
                      <div
                        className={`col-12 form-group mb-3 ${
                          values.arphragraph
                            ? errors.arphragraph
                              ? "is-invalid"
                              : "is-valid"
                            : ""
                        }`}
                      >
                        <label className="text-label">
                          Arabic Service Phragraph
                        </label>
                        <div className="input-group">
                          <textarea
                            type="text"
                            className="form-control"
                            placeholder="Enter a arabic phragraph.."
                            name="arphragraph"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.arphragraph}
                            rows={8}
                            style={{ resize: "none" }}
                          ></textarea>
                          <div
                            id="val-arphragraph-error"
                            className="invalid-feedback animated fadeInUp"
                            style={{ display: "block" }}
                          >
                            {errors.arphragraph && errors.arphragraph}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* Image Inputs Data */}
                    {/* <div className="row">
                      <div
                        className="card-header"
                        style={{
                          border: "transparent",
                        }}
                      >
                        <h4 className="card-title">Uploade image</h4>
                      </div>
                      <div className={`col-6 form-group mb-3`}>
                        <label
                          onClick={openFileUploader}
                          className="text-label"
                          style={{
                            cursor: "pointer",
                            width: "100%",
                            padding: "2rem",
                            backgroundColor: "#1f88d9",
                            opacity: "0.5",
                            border: "3px dashed blue",
                            borderRadius: "10px",
                            color: "#111",
                            fontSize: "1rem",
                            textAlign: "center",
                            overflow: "hidden",
                          }}
                        >
                          {values.imagname ? values.imagname : "  Upload Main Image"}
                        </label>
                        <div className="input-group">
                          <input
                            type="file"
                            className="form-control"
                            name="image"
                            hidden
                            ref={fileRef}
                            onChange={(e) => {
                              setTempImg("");
                              setValues({
                                ...values,
                                imagname: e.target.files[0].name,
                                image: e.target.files[0],
                              });
                              setTempImg(
                                URL.createObjectURL(e.target.files[0])
                              );
                            }}
                          />
                        </div>
                      </div>
                      <div className={`col-6 form-group mb-3`}>
                        <label
                          onClick={openFileUploader2}
                          className="text-label"
                          style={{
                            cursor: "pointer",
                            width: "100%",
                            padding: "2rem",
                            backgroundColor: "#1f88d9",
                            opacity: "0.5",
                            border: "3px dashed blue",
                            borderRadius: "10px",
                            color: "#111",
                            fontSize: "1rem",
                            textAlign: "center",
                            overflow: "hidden",
                          }}
                        >
                          {values.home_imagename ? values.home_imagename : " Upload Home Image"}
                        </label>
                        <div className="input-group">
                          <input
                            type="file"
                            className="form-control"
                            name="image"
                            hidden
                            ref={fileRef2}
                            onChange={(e) => {
                              setTempImg2("");
                              setValues({
                                ...values,
                                home_imagename: e.target.files[0].name,
                                home_image: e.target.files[0],
                              });
                              setTempImg2(
                                URL.createObjectURL(e.target.files[0])
                              );
                            }}
                          />
                        </div>
                      </div>
                      <div className={`col-lg-12 col-sm-12 form-group mb-3`}>
                        {tempimg && (
                          <img classname="m-1" src={tempimg} width={50} />
                        )}
                        {tempimg2 && (
                          <img classname="m-1" src={tempimg2} width={50} />
                        )}
                      </div>
                    </div> */}
                    <div className="row">
                      {isUploading && (
                        <>
                          <h5>uploading video now please waite...</h5>

                          <div className="progress-box">
                            <div className="d-flex align-items-center justify-content-between">
                              <h5 className="mb-0 fs-14 font-w600">
                                Uploading Images now.., please don't refresh
                              </h5>
                              <span className="font-w600" id="progress-span">
                                {progress}%
                              </span>
                            </div>
                            <div className="progress ">
                              <div
                                className="progress-bar bg-primary"
                                id="progress-inner-width"
                                style={{
                                  width: `${progress}%`,
                                  height: "12px",
                                  borderRadius: "4px",
                                }}
                                role="progressbar"
                              ></div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>

                    {isUploading ? (
                      <>
                        <button className="btn me-2 btn-primary" disabled>
                          <CustomSpinner />
                        </button>
                      </>
                    ) : (
                      <>
                        <button type="submit" className="btn me-2 btn-primary">
                          Submit
                        </button>
                      </>
                    )}
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddServiceForm;
