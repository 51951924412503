import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { checkErrorMessage } from "../../../services/ErrorMessage";

export const createUserApi = createAsyncThunk(
  "user/create",
  async (userCredintials) => {
    const token = JSON.parse(localStorage.getItem("userData")).token;

    const request = await fetch(
      `${process.env.REACT_APP_PUBLIC_API}/api/user/create`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`,
        },
        body: JSON.stringify(userCredintials),
      }
    )
      .then((res) => res.json())
      .then((result) => result)
      .catch((err) => err);

    return request;
  }
);

const createUserSlicer = createSlice({
  name: "create",
  initialState: {
    loading: false,
    user: null,
    error: null,
  },
  extraReducers: (builder) => {
    builder.addCase(createUserApi.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createUserApi.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload.message === "User created successfully") {
        state.user = action.payload.message;
      } else {
        state.error = checkErrorMessage(action.payload.message);
      }
    });
    builder.addCase(createUserApi.rejected, (state, action) => {
      state.loading = false;
      state.error = checkErrorMessage(action.payload.message);
    });
  },
});

export default createUserSlicer.reducer;
