import React, { Component, useState } from "react";
import Rte from "./Summernote/Rte";
import PageTitle from "../../layouts/PageTitle";
import { Formik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";
import { createMessegeApi } from "../../../store/Slicers/messeges/messegesCreateSlicer";
import { Spinner } from "react-bootstrap";
const messegeSchema = Yup.object().shape({
  subject: Yup.string().required("Please enter a subject"),
});
const Newslist = () => {
  const {loading: loadingSendMessege} = useSelector(
    (s) => s.messegesCreateSlicer
  );
  const [clear, setClear] = useState(false);
  const [messageContent, setMessageContent] = useState();
  const dispatch = useDispatch();
  const handleSummrizeSubmitt = (e) => {
    setClear(false);
    setMessageContent(e);
    console.log(e);
  };

  const handleFormSubmite = (value, { resetForm }) => {
    const messageData = {
      subject: value.subject,
      messege: messageContent,
    };
    dispatch(createMessegeApi(messageData)).then((res) => {
      if (res.payload && res.payload.status === "success") {
        console.log(res.payload);
        swal("Done!", res.payload.message, "success");
        setClear(true);
        resetForm();
      } else {
        swal("Error!", res.payload.message, "error");
      }
    });
    console.log(messageData);
  };

  return (
    <div className="h-80">
      <PageTitle activeMenu="Summernote" motherMenu="Form" />
      <div className="row">
        <div className="col-xl-12 col-xxl-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Summernote Editor</h4>
            </div>
            <div className="card-body">
              <Formik
                initialValues={{
                  subject: "",
                }}
                validationSchema={messegeSchema}
                onSubmit={handleFormSubmite}
              >
                {({
                  values,
                  errors,
                  setValues,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  handleReset,
                }) => (
                  <form onSubmit={handleSubmit}>
                    {/* Catefory Name */}
                    <div className="row">
                      <div
                        className="card-header"
                        style={{
                          border: "transparent",
                        }}
                      >
                        <h4 className="card-title">Message Data</h4>
                      </div>

                      <div
                        className={` col-lg-12 col-sm-12 form-group mb-3 ${
                          values.subject
                            ? errors.subject
                              ? "is-invalid"
                              : "is-valid"
                            : ""
                        }`}
                      >
                        <label className="text-label">
                          Enter Email Subject
                        </label>
                        <div className="input-group">
                          <span className="input-group-text">
                            <i class="fa-solid fa-file-signature"></i>
                          </span>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter a subject.."
                            name="subject"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.subject}
                          />
                          <div
                            id="val-subject-error"
                            className="invalid-feedback animated fadeInUp"
                            style={{ display: "block" }}
                          >
                            {errors.subject && errors.subject}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="summernote">
                      <Rte
                        handleSubmit={handleSummrizeSubmitt}
                        targetClear={clear}
                      />
                    </div>
                    <div className="card-footer m-1 gap-2 d-flex ">
                    {loadingSendMessege?<>
                      <button className="btn btn-primary" disabled>
                        <Spinner  animation="border" variant="primary"/>
                      </button>
                    </>:<>
                    <button className="btn btn-primary" type="submit">
                        Send Message
                      </button>
                    </>}
                      
                      <button
                        className="btn btn-danger "
                        onClick={() => setClear(true)}
                      >
                        Clear
                      </button>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Newslist;
