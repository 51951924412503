import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import swal from "sweetalert";
import { useDispatch, useSelector } from "react-redux";

import { Formik } from "formik";
import * as Yup from "yup";

import CustomGrowSpinner from "../../Ui/CustomGrowSpinner";
import CustomSpinner from "../../Ui/Spinner";

import { getAllUserApi } from "../../../../store/Slicers/Users/getAllUsersSlicer";
import { DeleteUserApi } from "../../../../store/Slicers/Users/deleteUserSlicer";
import { updateUserApi } from "../../../../store/Slicers/Users/updateUserSlicer";
import { getAllMessegesApi } from "../../../../store/Slicers/messeges/getAllMessegesSlicer";

const loginSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, "Your name must consist of at least 3 characters ")
    .max(100, "Your name must consist of at most 100 characters ")
    .required("Please enter a name"),
  title: Yup.string()
    .min(3, "Your title must consist of at least 3 characters ")
    .max(200, "Your title must consist of at most 200 characters ")
    .required("Please enter a title"),
  email: Yup.string()
    .min(3, "Your email is to short ")
    .email()
    .required("Please enter a email"),
  password: Yup.string()
    .min(8, "Your password must be at least 8 characters")
    .max(200, "Your password must be at most 200 characters"),
  mobile: Yup.number()
    .moreThan(11, "phone number must be 11 degite")
    .required("Please provide a mobile number"),
  role: Yup.string().required("Please select role"),
});

const DisplayAllMessagesTableData = () => {
  const { loading, error } = useSelector((s) => s.getAllMessegesSlicer);
  const { loading: loadongUpdate, error: errorUpdate } = useSelector(
    (s) => s.updateUserSlicer
  );
  const { loading: loadongDelete, error: errorDelete } = useSelector(
    (s) => s.deleteUserSlicer
  );

  const dispatch = useDispatch();
  const [message, setMessage] = useState([]);
  const [addCard, setAddCard] = useState(false);
  const [displayMessage, setDisplayMessage] = useState({
    subject: "",
    sendto: [],
    message: "",
  });
  const divRef = useRef(null);

  // Handle Get All User Data
  const getAllMessages = () => {
    dispatch(getAllMessegesApi()).then((res) => {
      if (res.payload && res.payload.status === "success") {
        setMessage(res.payload.data);
      }
    });
  };
  // Handle Update Data
  const handleUpdate = (content) => {
    setAddCard(true);

    function getText() {
      var divContainer = document.createElement("div");
      divContainer.innerHTML = content.messege;
      return divContainer.textContent || divContainer.innerText || "";
    }

    setDisplayMessage({
      subject: content.subject,
      sendto: content.sendto,
      message: getText(),
    });

    // messBody.innerHTML = content.messege;
  };

  useEffect(() => {
    getAllMessages();
  }, []);

  return (
    <>
      {loading ? (
        <>
          <div className="col-12 d-flex justify-content-center gap-3">
            <CustomGrowSpinner />
            <CustomGrowSpinner />
            <CustomGrowSpinner />
          </div>
        </>
      ) : (
        <>
          {error ? (
            <>
              <div className="col-12 d-flex justify-content-center gap-3">
                <h2>{error}</h2>
              </div>
            </>
          ) : (
            <>
              <Modal className="modal fade" show={addCard} onHide={setAddCard}>
                <div className="" role="document">
                  <div className="">
                    <div className="modal-header">
                      <h4 className="modal-title fs-20">Read Messages</h4>
                      <button
                        type="button"
                        className="btn-close"
                        onClick={() => {
                          setAddCard(false);
                        }}
                        data-dismiss="modal"
                      >
                        <span></span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <div className="add-contact-box">
                        <div className="add-contact-content">
                          <div className="form-group mb-3">
                            <label className="text-black font-w500">
                              Subject
                            </label>
                            <div className="contact-name">
                              <input
                                type="text"
                                className="form-control"
                                value={displayMessage.subject}
                                disabled
                              />
                            </div>
                          </div>

                          <div className="form-group mb-3">
                            <label className="text-black font-w500">
                              Send to{" "}
                              {displayMessage.sendto &&
                                displayMessage.sendto.length}
                            </label>
                            <div className="contact-name">
                              <p>
                                {displayMessage.sendto &&
                                  displayMessage.sendto.map((email, index) => (
                                    <>
                                      <span
                                        key={index}
                                        variant="success light"
                                        className="badge bg-success"
                                      >
                                        {email}
                                      </span>
                                    </>
                                  ))}
                              </p>
                            </div>
                          </div>
                          <div ref={divRef} className="form-group mb-3">
                            <label className="text-black font-w500">
                              Message
                            </label>
                            <div>
                              {displayMessage.message && displayMessage.message}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>
              {message && (
                <>
                  {message.length > 0 ? (
                    <>
                      <div className="col-12">
                        <div className="card">
                          <div className="card-body">
                            <div className="w-100 table-responsive">
                              <div
                                id="example_wrapper"
                                className="dataTables_wrapper"
                              >
                                <table
                                  id="example"
                                  className="display w-100 dataTable"
                                >
                                  <thead>
                                    <tr>
                                      <th>##</th>
                                      <th>Subject</th>
                                      <th>Sent To</th>

                                      <th>Action</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {message.map((content, index) => (
                                      <tr key={index}>
                                        <>
                                          <td>
                                            {index + 1} {")"}{" "}
                                          </td>
                                          <td>{content.subject}</td>

                                          <td>
                                            <Link to={"#"}>
                                              {content.sendto.length}
                                            </Link>
                                          </td>
                                          <td>
                                            <div className="d-flex">
                                              <a
                                                className="btn btn-secondary shadow btn-xs sharp me-2"
                                                onClick={() =>
                                                  handleUpdate(content)
                                                }
                                              >
                                                <i class="fa-solid fa-pen"></i>
                                              </a>
                                            </div>
                                          </td>
                                        </>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="col-12 d-flex justify-content-center gap-3">
                        <h2>No Messages Sended</h2>
                      </div>
                    </>
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};
export default DisplayAllMessagesTableData;
