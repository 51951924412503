export default function AccordingVideoDisplay({
  videoUrl,
  index,
  handleDelete,
}) {
  const handleWatch = () => {
    window.open(videoUrl, "_blank");
  };

  return (
    <>
      <div className="custom-btn d-flex align-items-center gap-2 mt-3">
        <button className="btn btn-primary" onClick={handleWatch}>
          {" "}
          Watch
        </button>
        <button
          className="btn btn-danger"
          onClick={() => handleDelete(videoUrl)}
        >
          Delete
        </button>
      </div>
    </>
  );
}
