import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { checkErrorMessage } from "../../../services/ErrorMessage";

export const updateServicesApi = createAsyncThunk(
  "services/update",
  async (serviceData) => {

    const serviceFormData = new FormData();
    serviceFormData.append('en[name]',serviceData.enname);
    serviceFormData.append('en[description]',serviceData.endescription);
    serviceFormData.append('en[phragraph]',serviceData.enphragraph);
    serviceFormData.append('ar[name]',serviceData.arname);
    serviceFormData.append('ar[description]',serviceData.ardescription);
    serviceFormData.append('ar[phragraph]',serviceData.arphragraph);
    serviceFormData.append('image',serviceData.image);

    const token = JSON.parse(localStorage.getItem("userData")).token;
    const request = await fetch(
      `${process.env.REACT_APP_PUBLIC_API}/api/services/updateServiceById/${serviceData.id}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: serviceFormData,
      }
    )
      .then((res) => res.json())
      .then((result) => result)
      .catch((error) => error);
    return request;
  }
);

const updateServiceSlicer = createSlice({
  name: "updateService",
  initialState: {
    loading: false,
    data: null,
    error: null,
  },
  extraReducers: (builder) => {
    builder.addCase(updateServicesApi.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(updateServicesApi.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload.message === "Data Updated successfully") {
        state.data = action.payload.data;
      } else {
        state.error = checkErrorMessage(action.payload.message);
      }
    });

    builder.addCase(updateServicesApi.rejected, (state, action) => {
      state.loading = false;
      state.error = checkErrorMessage(action.payload.message);
    });
  },
});

export default updateServiceSlicer.reducer;
