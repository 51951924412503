import { Fragment } from "react";
import PageTitle from "../../layouts/PageTitle";
import DisplayAllMessagesTableData from "./Summernote/DisplayAllMessagesTableData";

export default function DisplayAllMessages() {
  return (
    <>
      <Fragment>
      <PageTitle
        activeMenu="All Messages"
        motherMenu="Messages"
      />

        <DisplayAllMessagesTableData />
      </Fragment>
    </>
  );
}
