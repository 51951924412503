import React, { Fragment, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { createUserApi } from "../../../../store/Slicers/Users/createUser";
import swal from "sweetalert";
import CustomSpinner from "../../Ui/Spinner";

const loginSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, "Your name must consist of at least 3 characters ")
    .max(100, "Your name must consist of at most 100 characters ")
    .required("Please enter a name"),
  title: Yup.string()
    .min(3, "Your title must consist of at least 3 characters ")
    .max(200, "Your title must consist of at most 200 characters ")
    .required("Please enter a title"),
  email: Yup.string()
    .min(3, "Your email is to short ")
    .email()
    .required("Please enter a email"),
  password: Yup.string()
    .min(8, "Your password must be at least 8 characters")
    .max(200, "Your password must be at most 200 characters")
    .required("Please provide a password"),
  mobile: Yup.number()
    .moreThan(11, "phone number must be 11 degite")
    .required("Please provide a mobile number"),
  role: Yup.string().required("Please select role"),
});

const AddUserForm = () => {
  const { loading } = useSelector((s) => s.createUserSlicer);
  const dispatch = useDispatch();

  const [formIntilaData, setFormIntilaData] = useState({
    name: "",
    title: "",
    email: "",
    mobile: "",
    role: "",
    password: "",
  });

  const handleFormSubmite = (value,{resetForm }) => {
    dispatch(createUserApi(value)).then((res) => {
      if (res.payload.message === "User created successfully") {
        swal("User Created Successfuly!", {
          icon: "success",
        });
        resetForm();
      } else {
        swal("Error!", res.payload.message, "error");
      }
    });
  };

  const [showPassword, setShowPassword] = useState(false);
  return (
    <div className="row">
      {/* <div className="col-lg-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Form Validation</h4>
            </div>
            <div className="card-body">
              <div className="form-validation">
                <form
                  className="form-valide"
                  action="#"
                  method="post"
                  onSubmit={(e) => e.preventDefault()}
                >
                  <div className="row">
                    <div className="col-xl-6">
                      <div className="form-group mb-3 row">
                        <label
                          className="col-lg-4 col-form-label"
                          htmlFor="val-username"
                        >
                          Username
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-6">
                          <input
                            type="text"
                            className="form-control"
                            id="val-username"
                            name="val-username"
                            placeholder="Enter a username.."
                          />
                        </div>
                      </div>
                      <div className="form-group mb-3 row">
                        <label
                          className="col-lg-4 col-form-label"
                          htmlFor="val-email"
                        >
                          Email <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-6">
                          <input
                            type="text"
                            className="form-control"
                            id="val-email"
                            name="val-email"
                            placeholder="Your valid email.."
                          />
                        </div>
                      </div>
                      <div className="form-group mb-3 row">
                        <label
                          className="col-lg-4 col-form-label"
                          htmlFor="val-password"
                        >
                          Password
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-6">
                          <input
                            type="password"
                            className="form-control"
                            id="val-password"
                            name="val-password"
                            placeholder="Choose a safe one.."
                          />
                        </div>
                      </div>
                      <div className="form-group mb-3 row">
                        <label
                          className="col-lg-4 col-form-label"
                          htmlFor="val-confirm-password"
                        >
                          Confirm Password{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-6">
                          <input
                            type="password"
                            className="form-control"
                            id="val-confirm-password"
                            name="val-confirm-password"
                            placeholder="..and confirm it!"
                          />
                        </div>
                      </div>
                      <div className="form-group mb-3 row">
                        <label
                          className="col-lg-4 col-form-label"
                          htmlFor="val-suggestions"
                        >
                          Suggestions <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-6">
                          <textarea
                            className="form-control"
                            id="val-suggestions"
                            name="val-suggestions"
                            rows="5"
                            placeholder="What would you like to see?"
                          ></textarea>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-6">
                      <div className="form-group mb-3 row">
                        <label
                          className="col-lg-4 col-form-label"
                          htmlFor="val-skill"
                        >
                          Best Skill
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-6">
                          <select
                            className="form-control"
                            id="val-skill"
                            name="val-skill"
                          >
                            <option value="">Please select</option>
                            <option value="html">HTML</option>
                            <option value="css">CSS</option>
                            <option value="javascript">JavaScript</option>
                            <option value="angular">Angular</option>
                            <option value="angular">React</option>
                            <option value="vuejs">Vue.js</option>
                            <option value="ruby">Ruby</option>
                            <option value="php">PHP</option>
                            <option value="asp">ASP.NET</option>
                            <option value="python">Python</option>
                            <option value="mysql">MySQL</option>
                          </select>
                        </div>
                      </div>
                      <div className="form-group mb-3 row">
                        <label
                          className="col-lg-4 col-form-label"
                          htmlFor="val-currency"
                        >
                          Currency
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-6">
                          <input
                            type="text"
                            className="form-control"
                            id="val-currency"
                            name="val-currency"
                            placeholder="$21.60"
                          />
                        </div>
                      </div>
                      <div className="form-group mb-3 row">
                        <label
                          className="col-lg-4 col-form-label"
                          htmlFor="val-website"
                        >
                          Website
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-6">
                          <input
                            type="text"
                            className="form-control"
                            id="val-website"
                            name="val-website"
                            placeholder="http://example.com"
                          />
                        </div>
                      </div>
                      <div className="form-group mb-3 row">
                        <label
                          className="col-lg-4 col-form-label"
                          htmlFor="val-phoneus"
                        >
                          Phone (US)
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-6">
                          <input
                            type="text"
                            className="form-control"
                            id="val-phoneus"
                            name="val-phoneus"
                            placeholder="212-999-0000"
                          />
                        </div>
                      </div>
                      <div className="form-group mb-3 row">
                        <label
                          className="col-lg-4 col-form-label"
                          htmlFor="val-digits"
                        >
                          Digits <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-6">
                          <input
                            type="text"
                            className="form-control"
                            id="val-digits"
                            name="val-digits"
                            placeholder="5"
                          />
                        </div>
                      </div>
                      <div className="form-group mb-3 row">
                        <label
                          className="col-lg-4 col-form-label"
                          htmlFor="val-number"
                        >
                          Number <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-6">
                          <input
                            type="text"
                            className="form-control"
                            id="val-number"
                            name="val-number"
                            placeholder="5.0"
                          />
                        </div>
                      </div>
                      <div className="form-group mb-3 row">
                        <label
                          className="col-lg-4 col-form-label"
                          htmlFor="val-range"
                        >
                          Range [1, 5]
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-6">
                          <input
                            type="text"
                            className="form-control"
                            id="val-range"
                            name="val-range"
                            placeholder="4"
                          />
                        </div>
                      </div>
                      <div className="form-group mb-3 row">
                        <label className="col-lg-4 col-form-label">
                          <Link to="form-validation-jquery">
                            Terms &amp; Conditions
                          </Link>{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-8">
                          <label
                            className="form-check css-control-primary css-checkbox"
                            htmlFor="val-terms"
                          >
                            <input
                              type="checkbox"
                              className="form-check-input me-2"
                              id="val-terms"
                              name="val-terms"
                              value="1"
                            />
                            <span className="css-control-indicator"></span> I
                            agree to the terms
                          </label>
                        </div>
                      </div>
                      <div className="form-group mb-3 row">
                        <div className="col-lg-8 ms-auto">
                          <button type="submit" className="btn btn-primary">
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div> */}
      <div className="col-lg-12">
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">Add User Forms</h4>
          </div>
          <div className="card-body">
            <div className="basic-form">
              <Formik
                initialValues={{
                  name: formIntilaData.name,
                  title: formIntilaData.title,
                  email: formIntilaData.email,
                  mobile: formIntilaData.mobile,
                  role: formIntilaData.role,
                  password: formIntilaData.password,
                }}
                validationSchema={loginSchema}
                onSubmit={handleFormSubmite}
              >
                {({
                  values,
                  errors,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  handleReset,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      {/* Name  */}
                      <div
                        className={`col-6 form-group mb-3 ${
                          values.name
                            ? errors.name
                              ? "is-invalid"
                              : "is-valid"
                            : ""
                        }`}
                      >
                        <label className="text-label">name</label>
                        <div className="input-group">
                          <span className="input-group-text">
                            <i className="fa fa-user" />
                          </span>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter a name.."
                            name="name"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.name}
                          />
                          <div
                            id="val-name-error"
                            className="invalid-feedback animated fadeInUp"
                            style={{ display: "block" }}
                          >
                            {errors.name && errors.name}
                          </div>
                        </div>
                      </div>
                      {/* Title  */}
                      <div
                        className={`col-6 form-group mb-3 ${
                          values.title
                            ? errors.title
                              ? "is-invalid"
                              : "is-valid"
                            : ""
                        }`}
                      >
                        <label className="text-label">title</label>
                        <div className="input-group">
                          <span className="input-group-text">
                            <i class="fa-brands fa-cuttlefish"></i>
                          </span>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter a Title.."
                            name="title"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.title}
                          />
                          <div
                            id="val-title-error"
                            className="invalid-feedback animated fadeInUp"
                            style={{ display: "block" }}
                          >
                            {errors.title && errors.title}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      {/* Email  */}
                      <div
                        className={`col-6 form-group mb-3 ${
                          values.email
                            ? errors.email
                              ? "is-invalid"
                              : "is-valid"
                            : ""
                        }`}
                      >
                        <label className="text-label">email</label>
                        <div className="input-group">
                          <span className="input-group-text">
                            <i class="fa-solid fa-envelope"></i>
                          </span>
                          <input
                            type="email"
                            className="form-control"
                            placeholder="Enter a email.."
                            name="email"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.email}
                          />
                          <div
                            id="val-email-error"
                            className="invalid-feedback animated fadeInUp"
                            style={{ display: "block" }}
                          >
                            {errors.email && errors.email}
                          </div>
                        </div>
                      </div>
                      {/* Password  */}
                      <div
                        className={`col-6 form-group mb-3 ${
                          values.password
                            ? errors.password
                              ? "is-invalid"
                              : "is-valid"
                            : ""
                        }`}
                      >
                        <label className="text-label">Password *</label>
                        <div className="input-group transparent-append mb-2">
                          <span className="input-group-text">
                            {" "}
                            <i className="fa fa-lock" />{" "}
                          </span>

                          <input
                            type={`${showPassword ? "text" : "password"}`}
                            className="form-control"
                            id="val-password1"
                            name="password"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.password}
                            placeholder="Choose a safe one.."
                          />

                          <div
                            className="input-group-text "
                            onClick={() => setShowPassword(!showPassword)}
                          >
                            {" "}
                            {showPassword === false ? (
                              <i className="fa fa-eye-slash" />
                            ) : (
                              <i className="fa fa-eye" />
                            )}
                          </div>
                          <div
                            id="val-username1-error"
                            className="invalid-feedback animated fadeInUp"
                            style={{ display: "block" }}
                          >
                            {errors.password && errors.password}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      {/* Mobile  */}
                      <div
                        className={`col-6 form-group mb-3 ${
                          values.mobile
                            ? errors.mobile
                              ? "is-invalid"
                              : "is-valid"
                            : ""
                        }`}
                      >
                        <label className="text-label">mobile</label>
                        <div className="input-group">
                          <span className="input-group-text">
                            <i class="fa-solid fa-mobile"></i>
                          </span>
                          <input
                            type="tel"
                            maxLength={11}
                            className="form-control"
                            placeholder="Enter a mobile.."
                            name="mobile"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.mobile}
                          />
                          <div
                            id="val-mobile-error"
                            className="invalid-feedback animated fadeInUp"
                            style={{ display: "block" }}
                          >
                            {errors.mobile && errors.mobile}
                          </div>
                        </div>
                      </div>
                      {/* Role  */}
                      <div
                        className={`col-6 form-group mb-3 ${
                          values.role
                            ? errors.role
                              ? "is-invalid"
                              : "is-valid"
                            : ""
                        }`}
                      >
                        <label className="text-label">role</label>
                        <div className="input-group transparent-append mb-2">
                          <select
                            className="form-control"
                            name="role"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.role}
                            placeholder="Chose User Role"
                          >
                            <option defaultValue={null}>Please select</option>
                            <option value="admin">admin</option>
                            <option value="user">Assestant</option>
                          </select>
                          <div
                            id="val-role-error"
                            className="invalid-feedback animated fadeInUp"
                            style={{ display: "block" }}
                          >
                            {errors.role && errors.role}
                          </div>
                        </div>
                      </div>
                    </div>
                    {loading ? (
                      <>
                        <button className="btn me-2 btn-primary" disabled>
                          <CustomSpinner />
                        </button>
                      </>
                    ) : (
                      <>
                        <button type="submit" className="btn me-2 btn-primary">
                          Submit
                        </button>
                      </>
                    )}
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddUserForm;
