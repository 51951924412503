export const checkErrorMessage = (res) => {
  if (res === "Not Authenticated") {
    return "You are not logged in";
  }
  if (res === "Internal Server Error") {
    return "Some thing went wrong in server, please try again later";
  }
  if (res === "Unauthorized") {
    return "You are not Allowed to do this action";
  }
  if (res === "Email Already Exist") {
    return res;
  }
  if (res === "Service Data Not Exist") {
    return res;
  }
  if (res === "Category Name Already Exist") {
    return res;
  }
  if (res === "Invaild Email or Email Dose not Existe") {
    return res;
  }
  if (res === "Invaild Password") {
    return res;
  }
  if (res === "You can't delete yourself") {
    return res;
  }
  if (res === "User not found") {
    return res;
  }
  if (res === "Message not found") {
    return res;
  }
  if (res === "Service Data Not Found") {
    return res;
  }
  if (res === "Data Not Found") {
    return res;
  }
  if (res === "Invalid request parameters") {
    return res;
  }
  if (res === "No image provided, Please insert valid image(png, jpg, jpeg)") {
    return res;
  }
  if (res === "Message failed to send") {
    return res;
  }

  return "Some thing went wrong, please try again later";
};
