import { Fragment } from "react";
import PageTitle from "../../layouts/PageTitle";
import DisplayAllUserTableData from "./component/DisplayAllUserTableData";

export default function DisplayAllUsers() {
  return (
    <>
      <Fragment>
      <PageTitle
        activeMenu="All Users"
        motherMenu="Users"
      />

        <DisplayAllUserTableData />
      </Fragment>
    </>
  );
}
