import CUSIMG from "./CUSIMG";

import '../project-style.css'

export default function AccordinImageDisplay({
  imageUrl,
  imageName,
  handleDelete,
}) {
  return (
    <>
      <div className="custom-acc-images acc-courses">
        <div className="acc-content">
          <div className="acc-image">
            <CUSIMG
              url={imageUrl}
              className="d-block w-100"
              title={imageName}
            />
          </div>
          <button className="custom-trach-btn" onClick={()=>handleDelete(imageUrl)}>
            <i className="fa fa-trash"></i>
          </button>
        </div>
      </div>
    </>
  );
}
