import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { checkErrorMessage } from "../../../services/ErrorMessage";

export const showInHomeFunc = createAsyncThunk(
  "project/updateVisable",
  async (project) => {
    const token = JSON.parse(localStorage.getItem("userData")).token;

    const request = await fetch(
      `${process.env.REACT_APP_PUBLIC_API}/api/dashbord/project/updateWork/${project._id}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type":"application/json"
        },
        body:JSON.stringify({
          is_work: !project.is_work
        })
      }
    )
      .then((res) => res.json())
      .then((result) => result)
      .catch((error) => error);
    return request;
  }
);

const showInHomeSlicer = createSlice({
  name: "updateVisable",
  initialState: {
    loading: false,
    data: null,
    error: null,
  },
  extraReducers: (builder) => {
    builder.addCase(showInHomeFunc.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(showInHomeFunc.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload.message === "Data Updated successfully") {
        state.data = action.payload;
      } else {
        state.error = checkErrorMessage(action.payload.message);
      }
    });

    builder.addCase(showInHomeFunc.rejected, (state, action) => {
      state.loading = false;
      state.error = checkErrorMessage(action.payload.message);
    });
  },
});

export default showInHomeSlicer.reducer;
