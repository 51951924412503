import React, { useState } from "react";
import {  useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  loadingToggleAction,
  loginAction,
} from "../../store/actions/AuthActions";

//
import loginbg from "../../images/bg-1.jpg";
import logo from "../../images/log.png";
import logofull from "../../images/logo-full.png";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { LoginApi } from "../../store/Slicers/Users/loginSlicer";
import CustomSpinner from "../components/Ui/Spinner";
import CustomAlert from "../components/Ui/CustomAlert";

function Login(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { loading, error } = useSelector((s) => s.LoginSlicer);

  const [email, setEmail] = useState("hassan@test.com");
  let errorsObj = { email: "", password: "" };
  const [errors, setErrors] = useState(errorsObj);
  const [password, setPassword] = useState("12345678");


  const handleForm = (e) => {
    e.preventDefault();
    const userData = {
      email,
      password,
    };
    dispatch(LoginApi(userData)).then((res) => {
      if (res.payload.message === "LoggedIn") {
        history.push("/");
        window.location.reload();
      }
    });
  };

  return (
    <div
      className="login-main-page"
      style={{
        backgroundImage: "url(" + loginbg + ")",
        backgroundSize: "cover",
      }}
    >
      <div className="login-wrapper">
        <div className="container h-100">
          <div className="row h-100 align-items-center justify-contain-center">
            <div className="col-xl-12 mt-3">
              <div className="card">
                <div className="card-body p-0">
                  <div className="row m-0">
                    <div className="col-xl-6 col-md-6 sign text-center">
                      <div>
                        <div className="text-center mb-5 mt-2 ">
                          <Link to="#">
                            <img
                              width="150"
                              src={logofull}
                              alt="TopGrowth Logo"
                              style={{ position: "relative", zIndex: "99" }}
                            />
                          </Link>
                        </div>
                        <img src={logo} className="education-img"></img>
                      </div>
                    </div>
                    <div className="col-xl-6 col-md-6">
                      <div className="sign-in-your">
                        <div className="text-center my-5">
                          {/* <Link to="#">
                            <img
                              width="200"
                              src={logofull}
                              alt="TopGrowth Logo"
                            />
                          </Link> */}
                        </div>
                        <h4 className="fs-20 font-w800 text-black">
                          Sign in your account
                        </h4>
                        <span>
                          Welcome back! Login To Start <b> Doing Mageic</b>
                        </span>

                        <form onSubmit={handleForm}>
                          <div className="mb-3">
                            <label className="mb-1">
                              <strong>Email</strong>
                            </label>
                            <input
                              type="email"
                              className="form-control"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
							  required
                            />
                            {errors.email && (
                              <div className="text-danger fs-12">
                                {errors.email}
                              </div>
                            )}
                          </div>
                          <div className="mb-3">
                            <label className="mb-1">
                              <strong>Password</strong>
                            </label>
                            <input
                              type="password"
                              className="form-control"
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
							  required
                            />
                            {errors.password && (
                              <div className="text-danger fs-12">
                                {errors.password}
                              </div>
                            )}
                          </div>
                          <div className="text-center">
                            {loading ? (
                              <>
                                <button
                                  disabled
                                  className="btn btn-primary btn-block"
                                >
                                  <CustomSpinner />
                                </button>
                              </>
                            ) : (
                              <>
                                <button
                                  type="submit"
                                  className="btn btn-primary btn-block"
                                >
                                  Sign Me In
                                </button>
                              </>
                            )}
                            <div className="mt-3">
                              {error && (
                                <CustomAlert
                                  type={"danger"}
                                  message={error}
                                />
                              )}
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;

// const mapStateToProps = (state) => {
//     return {
//         errorMessage: state.auth.errorMessage,
//         successMessage: state.auth.successMessage,
//         showLoading: state.auth.showLoading,
//     };
// };
// export default connect(mapStateToProps)(Login);
