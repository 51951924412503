import { Fragment } from "react";
import PageTitle from "../../layouts/PageTitle";
import AddServiceForm from "./components/AddServiceForm";

export default function AddServices() {
  return (
    <>
      <Fragment>
      <PageTitle
        activeMenu="Add Service"
        motherMenu="Services"
      />
    <AddServiceForm />
      </Fragment>
    </>
  );
}
