import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { checkErrorMessage } from "../../../services/ErrorMessage";

export const updateVisabilityPostionApi = createAsyncThunk(
  "carrers/updateVisability",
  async (id) => {
    const token = JSON.parse(localStorage.getItem("userData")).token;
    const request = await fetch(
      `${process.env.REACT_APP_PUBLIC_API}/api/dashbord/carrers/update-visability/${id}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((response) => response.json())
      .then((result) => result)
      .catch((error) => error);

    return request;
  }
);


const updateVisabilityCarrersSlicer = createSlice({
  name: "updateVisability",
  initialState: {
    loading: false,
    data: null,
    error: null,

    create_loading: false,
    create_response: null,
    create_error: null,
  },
  extraReducers: (builder) => {
    // get all carrers
    builder.addCase(updateVisabilityPostionApi.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateVisabilityPostionApi.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload.message === "Postion updated successfully") {
        state.data = action.payload.data;
      } else {
        state.error = checkErrorMessage(action.payload.message);
      }
    });
    builder.addCase(updateVisabilityPostionApi.rejected, (state, action) => {
      state.loading = false;
      state.error = checkErrorMessage(action.payload.message);
    });
   
  },
});

export default updateVisabilityCarrersSlicer.reducer;
