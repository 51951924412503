import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { nanoid } from "nanoid";
import swal from "sweetalert";
import pic1 from "../../../../images/profile/small/pic1.jpg";
import { useDispatch, useSelector } from "react-redux";
import { getAllMessageApi } from "../../../../store/Slicers/Contact/getAllMessagesSlicer";
import CustomSpinner from "../../Ui/Spinner";
import CustomGrowSpinner from "../../Ui/CustomGrowSpinner";
import { DeleteMessageApi } from "../../../../store/Slicers/Contact/DeleteMessageSlicer";
import { getByIdMessageApi } from "../../../../store/Slicers/Contact/getByIdMessageSlicer";
// import Editable from "./Editable";
// import Editable from "./Editable";

const MessageContacTable = () => {
  const { loading, error } = useSelector((s) => s.getAllMessageSlicer);
  const { error:getByIdError } = useSelector((s) => s.getByIdMessageSlicer);
  const { loading: loadongDelete, error: errorDelete } = useSelector(
    (s) => s.getAllMessageSlicer
  );
  const dispatch = useDispatch();
  const [messages, setMessages] = useState([]);

  const getAllMessages = () => {
    dispatch(getAllMessageApi()).then((res) => {
      if (res.payload.message === "Data fetched successfully") {
        setMessages(res.payload.data);
      }
    });
  };

  //Modal box
  const [addCard, setAddCard] = useState(false);

  //Add data
  const [displayMessage, setDisplayMessage] = useState({
    name: "",
    email: "",
    message: "",
  });

  // Add contact function
  const handleOpenMessage = (contentId) => {
    dispatch(getByIdMessageApi(contentId)).then((res) => {
		if (res.payload.message === "Data fetched successfully") {
			setDisplayMessage(res.payload.data);
			setAddCard(true);
		}else{
			swal("Error!", getByIdError, "error");
		}
	});

   
  };

  // delete data
  const handleDeleteClick = (contentId) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this message",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        dispatch(DeleteMessageApi(contentId)).then((res) => {
          if (res.payload.message === "Message Deleted successfully") {
            swal("Poof! Message has been deleted!", {
              icon: "success",
            });
          } else {
            swal("Error!", errorDelete, "error");
          }
          getAllMessages();
        });
      } else {
        swal("The Message is safe!");
      }
    });
  };

  useEffect(() => {
    getAllMessages();
  }, []);

  return (
    <>
      {loading ? (
        <>
          <div className="col-12 d-flex justify-content-center gap-3">
            <CustomGrowSpinner />
            <CustomGrowSpinner />
            <CustomGrowSpinner />
          </div>
        </>
      ) : (
        <>
          {error ? (
            <>
              <div className="col-12 d-flex justify-content-center gap-3">
                <h2>{error}</h2>
              </div>
            </>
          ) : (
            <>
              {messages && (
                <>
                  {messages.length > 0 ? (
                    <>
                      {" "}
                      <div className="col-12">
                        <Modal
                          className="modal fade"
                          show={addCard}
                          onHide={setAddCard}
                        >
                          <div className="" role="document">
                            <div className="">
                              <div className="modal-header">
                                <h4 className="modal-title fs-20">
                                  Read Messages
                                </h4>
                                <button
                                  type="button"
                                  className="btn-close"
                                  onClick={() => {
									getAllMessages();
									setAddCard(false)
									}}
                                  data-dismiss="modal"
                                >
                                  <span></span>
                                </button>
                              </div>
                              <div className="modal-body">
                                <div className="add-contact-box">
                                  <div className="add-contact-content">
                                    <div className="form-group mb-3">
                                      <label className="text-black font-w500">
                                        Name
                                      </label>
                                      <div className="contact-name">
                                        <input
                                          type="text"
                                          className="form-control"
                                          value={displayMessage.name}
                                          disabled
                                        />
                                      </div>
                                    </div>

                                    <div className="form-group mb-3">
                                      <label className="text-black font-w500">
                                        Email
                                      </label>
                                      <div className="contact-name">
                                        <input
                                          type="email"
                                          className="form-control"
                                          value={displayMessage.email}
										  disabled
                                        />
                                      </div>
                                    </div>
                                    <div className="form-group mb-3">
                                      <label className="text-black font-w500">
                                        Message
                                      </label>
                                      <div className="contact-name">
                                        <textarea
                                          disabled
                                          className="form-control"
                                          value={displayMessage.message}
                                          rows={8}
                                          style={{
                                            resize: "none",
                                          }}
                                        ></textarea>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Modal>
                        <div className="card">
                          <div className="card-body">
                            <div className="w-100 table-responsive">
                              <div
                                id="example_wrapper"
                                className="dataTables_wrapper"
                              >
                                <table
                                  id="example"
                                  className="display w-100 dataTable"
                                >
                                  <thead>
                                    <tr>
                                      <th>Name</th>
                                      <th>#Created At</th>
                                      <th>Email</th>
                                      <th>state</th>
                                      <th>Action</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {messages.map((content, index) => (
                                      <tr key={index}>
                                        <>
                                          {content.opend ? (
                                            <>
                                              <td>{content.name}</td>
                                              <td>{content.createdAt}</td>
                                              <td>
                                                <Link to={"#"}>
                                                  {content.email}
                                                </Link>
                                              </td>
                                            </>
                                          ) : (
                                            <>
                                              <td>
                                                <strong>{content.name}</strong>
                                              </td>
                                              <td>
                                                <strong>
                                                  {content.createdAt}
                                                </strong>
                                              </td>
                                              <td>
                                                <Link to={"#"}>
                                                  <strong>
                                                    {content.email}
                                                  </strong>
                                                </Link>
                                              </td>
                                            </>
                                          )}

                                          <td>
                                            {content.opend ? (
                                              <>
                                                <span
                                                  variant="success light"
                                                  class="badge bg-success"
                                                >
                                                  Opend
                                                </span>
                                              </>
                                            ) : (
                                              <span
                                                variant="danger light"
                                                class="badge bg-danger"
                                              >
                                                Not Opend
                                              </span>
                                            )}
                                          </td>

                                          <td>
                                            <div className="d-flex">
                                              <a
                                                className="btn btn-secondary shadow btn-xs sharp me-2"
                                                onClick={() =>
                                                  handleOpenMessage(content._id)
                                                }
                                              >
                                                <i class="fa-solid fa-envelope-open"></i>
                                              </a>
                                              <a
                                                className="btn btn-danger shadow btn-xs sharp"
                                                onClick={() =>
                                                  handleDeleteClick(content._id)
                                                }
                                              >
                                                <i className="fa fa-trash"></i>
                                              </a>
                                            </div>
                                          </td>
                                        </>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="col-12 d-flex justify-content-center gap-3">
                        <h2>No Meassages</h2>
                      </div>
                    </>
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};
export default MessageContacTable;
