import { Fragment } from "react";
import PageTitle from "../../layouts/PageTitle";
import PostionsDisplayTableData from "./components/PostionsDisplayTableData";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

export default function CarrersDisplayAll() {
  return (
    <>
      <Fragment>
        <PageTitle activeMenu="Postionst" motherMenu="Carres" />
       
        <PostionsDisplayTableData />
      </Fragment>
    </>
  );
}
