import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "swiper/css";

import { useDispatch, useSelector } from "react-redux";
import CustomGrowSpinner from "../Ui/CustomGrowSpinner";
import IMG from "../Ui/IMG";
import swal from "sweetalert";
import { getAllProjectsApi } from "../../../store/Slicers/Project/getAllProjetcsSlicer";
import { deleteProjectByIdApi } from "../../../store/Slicers/Project/deteteProjectSlicer";

function DisplayProjects() {
  const { loading, error } = useSelector((state) => state.getAllProjetcsSlicer);
  const { loading: deleteLoading, error: deleteError } = useSelector(
    (state) => state.deteteProjectSlicer
  );
  const dispatch = useDispatch();
  const [projects, setProjects] = useState([]);

  const getAllProjects = () => {
    dispatch(getAllProjectsApi()).then((res) => {
      if (res.payload.message === "Data fetched successfully") {
        setProjects(res.payload.data);
      }
    });
  };

  const deteteService = (projectId) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover data again",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        dispatch(deleteProjectByIdApi(projectId)).then((res) => {
          if (res.payload.message === "Data Deleted successfully") {
            swal("Good job!", res.payload.message, "success");
            getAllProjects();
          } else {
            swal("Error!", deleteError, "error");
          }
        });
      } else {
        swal("The user data is safe!");
      }
    });
  };
  useEffect(() => {
    //get All Services
    getAllProjects();
  }, []);

  return (
    <>
      {loading ? (
        <>
          <div className="col-12 d-flex justify-content-center gap-3">
            <CustomGrowSpinner />
            <CustomGrowSpinner />
            <CustomGrowSpinner />
          </div>
        </>
      ) : (
        <>
          {error ? (
            <>
              <div className="col-12 d-flex justify-content-center gap-3">
                <h2>{error}</h2>
              </div>
            </>
          ) : (
            <>
              <div className="widget-heading d-flex justify-content-between align-items-center">
                <h3 className="m-0">All Projects</h3>
                <Link to={"/add-projects"} className="btn btn-primary btn-sm">
                  Add New Project
                </Link>
              </div>
              {projects && (
                <>
                  {projects.length > 0 ? (
                    <>
                      <div className="row">
                        {projects.map((data, index) => (
                          <div className="col-xl-4 col-md-6" key={index}>
                            <div>
                              <div className="card all-crs-wid">
                                <div className="card-body">
                                  <div className="courses-bx">
                                    <div className="dlab-media">
                                      <IMG url={data.cover_image} />
                                    </div>
                                    <div className="dlab-info">
                                      <div className="dlab-title d-flex justify-content-between">
                                        <div>
                                          <h4>
                                            <Link
                                              to={`./project-details/${data._id}`}
                                            >
                                              {data.en.name}
                                            </Link>
                                          </h4>
                                          <p className="m-0">{data.en.title}</p>
                                        </div>
                                      </div>

                                      <div className="d-flex justify-content-between content align-items-center mt-2">
                                        <Link
                                          to={`./project-details/${data._id}`}
                                          className="btn btn-primary btn-sm"
                                        >
                                          View
                                        </Link>
                                        <button
                                          onClick={() =>
                                            deteteService(data._id)
                                          }
                                          className="btn btn-danger btn-sm"
                                        >
                                          Delete
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="col-12 d-flex justify-content-center gap-3">
                        <h2>No Projects Add </h2>
                      </div>
                    </>
                  )}
                </>
              )}
            </>
          )}
        </>
      )}

      {/* pagenation  */}
      <>
        {/*
       <div className="pagination-down">
        <div className="d-flex align-items-center justify-content-between flex-wrap">
          <h4 className="sm-mb-0 mb-3">
            Showing <span>1-6 </span>from <span>100 </span>data
          </h4>
          <ul>
            <li>
              <Link to={"#"}>
                <i className="fas fa-chevron-left"></i>
              </Link>
            </li>
            <li>
              <Link to={"#"} className="active">
                1
              </Link>
            </li>
            <li>
              <Link to={"#"}>2</Link>
            </li>
            <li>
              <Link to={"#"}>3</Link>
            </li>
            <li>
              <Link to={"#"}>
                <i className="fas fa-chevron-right"></i>
              </Link>
            </li>
          </ul>
        </div>
      </div> 
      */}
      </>
    </>
  );
}
export default DisplayProjects;
