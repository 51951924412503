import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { checkErrorMessage } from "../../../services/ErrorMessage";

export const updateHomeSLiderApi = createAsyncThunk(
  "home-slider/update",
  async (sliderData) => {

    const sliderDataFormData = new FormData();
    sliderDataFormData.append('en[name]',sliderData.enname);
    sliderDataFormData.append('en[description]',sliderData.endescription);
    sliderDataFormData.append('ar[name]',sliderData.arname);
    sliderDataFormData.append('ar[description]',sliderData.ardescription);

    const token = JSON.parse(localStorage.getItem("userData")).token;
    const request = await fetch(
      `${process.env.REACT_APP_PUBLIC_API}/api/home-Slider/update-slider/${sliderData.id}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: sliderDataFormData,
      }
    )
      .then((res) => res.json())
      .then((result) => result)
      .catch((error) => error);
    return request;
  }
);

const updateHomeSliderSlicer = createSlice({
  name: "updateHomeSlider",
  initialState: {
    loading: false,
    data: null,
    error: null,
  },
  extraReducers: (builder) => {
    builder.addCase(updateHomeSLiderApi.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(updateHomeSLiderApi.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload.message === "Data updated successfully") {
        state.data = action.payload.data;
      } else {
        state.error = checkErrorMessage(action.payload.message);
      }
    });

    builder.addCase(updateHomeSLiderApi.rejected, (state, action) => {
      state.loading = false;
      state.error = checkErrorMessage(action.payload.message);
    });
  },
});

export default updateHomeSliderSlicer.reducer;
