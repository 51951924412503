import React, { Fragment, useRef, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { createMemberApi } from "../../../../store/Slicers/Team/createTeamMember";
import swal from "sweetalert";
import CustomSpinner from "../../Ui/Spinner";

const teamSchema = Yup.object().shape({
  enname: Yup.string()
    .min(3, "Your name must consist of at least 3 characters ")
    .max(50, "Your name must consist of at most 50 characters ")
    .required("Please enter a name"),
  entitle: Yup.string()
    .min(3, "Your title must consist of at least 3 characters ")
    .max(100, "Your title must consist of at most 100 characters ")
    .required("Please enter a title"),
  endescription: Yup.string()
    .min(3, "Your description must consist of at least 3 characters ")
    .max(100, "Your description must consist of at most 100 characters ")
    .required("Please enter a description"),

  arname: Yup.string()
    .min(3, "Your name must consist of at least 3 characters ")
    .max(50, "Your name must consist of at most 50 characters ")
    .required("Please enter a name"),
  artitle: Yup.string()
    .min(3, "Your title must consist of at least 3 characters ")
    .max(100, "Your title must consist of at most 100 characters ")
    .required("Please enter a title"),
  ardescription: Yup.string()
    .min(3, "Your description must consist of at least 3 characters ")
    .max(100, "Your description must consist of at most 100 characters ")
    .required("Please enter a description"),
});

const AddTeamMemberForm = () => {
  const { loading, error } = useSelector((s) => s.createTeamMember);
  const dispatch = useDispatch();

  const fileRef = useRef(null);

  const [formIntilaData, setFormIntilaData] = useState({
    enname: "",
    entitle: "",
    endescription: "",

    arname: "",
    artitle: "",
    ardescription: "",

    socialmedia: {
      facebookLink: "",
      instagramLink: "",
      behainceLink: "",
      linkedinLink: "",
    },
    image: "",
    imagname: "",
  });

  const handleFormSubmite = (value, { resetForm }) => {
    console.log(value);
    dispatch(createMemberApi(value)).then((res) => {
      if (res.payload.message === "Member created successfully") {
        swal("Done!", res.payload.message, "success");
        resetForm();
      } else {
        swal("Error!", error, "error");
      }
    });
  };

  const openFileUploader = () => {
    fileRef.current.click();
  };

  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">Add Team Member From</h4>
          </div>
          <div className="card-body">
            <div className="basic-form">
              <Formik
                initialValues={{
                  enname: formIntilaData.enname,
                  entitle: formIntilaData.entitle,
                  endescription: formIntilaData.endescription,

                  arname: formIntilaData.arname,
                  artitle: formIntilaData.artitle,
                  ardescription: formIntilaData.ardescription,

                  facebookLink: formIntilaData.socialmedia.facebookLink,
                  instagramLink: formIntilaData.socialmedia.instagramLink,
                  behainceLink: formIntilaData.socialmedia.behainceLink,
                  linkedinLink: formIntilaData.socialmedia.linkedinLink,

                  image: formIntilaData.image,
                  imagname: formIntilaData.imagname,
                }}
                validationSchema={teamSchema}
                onSubmit={handleFormSubmite}
              >
                {({
                  values,
                  errors,
                  setValues,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  handleReset,
                }) => (
                  <form onSubmit={handleSubmit}>
                    {/* Ebglish Inputs Data */}
                    <div className="row">
                      <div
                        className="card-header"
                        style={{
                          border: "transparent",
                        }}
                      >
                        <h4 className="card-title">Englich Data</h4>
                      </div>
                      {/* Englich Name  */}
                      <div
                        className={`col-6 form-group mb-3 ${
                          values.enname
                            ? errors.enname
                              ? "is-invalid"
                              : "is-valid"
                            : ""
                        }`}
                      >
                        <label className="text-label">
                          English Team Member Name
                        </label>
                        <div className="input-group">
                          <span className="input-group-text">
                            <i className="fa-solid fa-file-signature"></i>
                          </span>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter a english name.."
                            name="enname"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.enname}
                          />
                          <div
                            id="val-enname-error"
                            className="invalid-feedback animated fadeInUp"
                            style={{ display: "block" }}
                          >
                            {errors.enname && errors.enname}
                          </div>
                        </div>
                      </div>
                      {/* Englich title  */}
                      <div
                        className={`col-6 form-group mb-3 ${
                          values.entitle
                            ? errors.entitle
                              ? "is-invalid"
                              : "is-valid"
                            : ""
                        }`}
                      >
                        <label className="text-label">
                          English Team Mamber Title
                        </label>
                        <div className="input-group">
                          <span className="input-group-text">
                            <i className="fa-solid fa-audio-description"></i>
                          </span>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter a english title.."
                            name="entitle"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.entitle}
                          />
                          <div
                            id="val-endescription-error"
                            className="invalid-feedback animated fadeInUp"
                            style={{ display: "block" }}
                          >
                            {errors.entitle && errors.entitle}
                          </div>
                        </div>
                      </div>
                      {/* Englich description  */}
                      <div
                        className={`col-12 form-group mb-3 ${
                          values.endescription
                            ? errors.endescription
                              ? "is-invalid"
                              : "is-valid"
                            : ""
                        }`}
                      >
                        <label className="text-label">
                          English Team Member Description
                        </label>
                        <div className="input-group">
                          <span className="input-group-text">
                            <i className="fa-solid fa-audio-description"></i>
                          </span>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter a english description.."
                            name="endescription"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.endescription}
                          />
                          <div
                            id="val-endescription-error"
                            className="invalid-feedback animated fadeInUp"
                            style={{ display: "block" }}
                          >
                            {errors.endescription && errors.endescription}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* Arabic Inputs Data */}
                    <div className="row">
                      <div
                        className="card-header"
                        style={{
                          border: "transparent",
                        }}
                      >
                        <h4 className="card-title">Arabic Data</h4>
                      </div>
                      {/* Arabic Name  */}
                      <div
                        className={`col-6 form-group mb-3 ${
                          values.arname
                            ? errors.arname
                              ? "is-invalid"
                              : "is-valid"
                            : ""
                        }`}
                      >
                        <label className="text-label">
                          Arabic Service Name
                        </label>
                        <div className="input-group">
                          <span className="input-group-text">
                            <i className="fa-solid fa-file-signature"></i>
                          </span>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter a arabic name.."
                            name="arname"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.arname}
                          />
                          <div
                            id="val-arname-error"
                            className="invalid-feedback animated fadeInUp"
                            style={{ display: "block" }}
                          >
                            {errors.arname && errors.arname}
                          </div>
                        </div>
                      </div>
                      {/* Arabic title  */}
                      <div
                        className={`col-6 form-group mb-3 ${
                          values.artitle
                            ? errors.artitle
                              ? "is-invalid"
                              : "is-valid"
                            : ""
                        }`}
                      >
                        <label className="text-label">
                          Arabic Team Member Title
                        </label>
                        <div className="input-group">
                          <span className="input-group-text">
                            <i className="fa-solid fa-audio-description"></i>
                          </span>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter a arabic title.."
                            name="artitle"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.artitle}
                          />
                          <div
                            id="val-ardescription-error"
                            className="invalid-feedback animated fadeInUp"
                            style={{ display: "block" }}
                          >
                            {errors.artitle && errors.artitle}
                          </div>
                        </div>
                      </div>
                      {/* Arabic description  */}
                      <div
                        className={`col-12 form-group mb-3 ${
                          values.ardescription
                            ? errors.ardescription
                              ? "is-invalid"
                              : "is-valid"
                            : ""
                        }`}
                      >
                        <label className="text-label">
                          Arabic Team Member Description
                        </label>
                        <div className="input-group">
                          <span className="input-group-text">
                            <i className="fa-solid fa-audio-description"></i>
                          </span>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter a arabic description.."
                            name="ardescription"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.ardescription}
                          />
                          <div
                            id="val-ardescription-error"
                            className="invalid-feedback animated fadeInUp"
                            style={{ display: "block" }}
                          >
                            {errors.ardescription && errors.ardescription}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* Image Inputs Data */}
                    <div className="row">
                      <div
                        className="card-header"
                        style={{
                          border: "transparent",
                        }}
                      >
                        <h4 className="card-title">Uploade image</h4>
                      </div>
                      {/* image  */}
                      <div className={`col-6 form-group mb-3`}>
                        <label
                          onClick={openFileUploader}
                          className="text-label"
                          style={{
                            cursor: "pointer",
                            width: "100%",
                            padding: "2rem",
                            backgroundColor: "#1f88d9",
                            opacity: "0.5",
                            border: "3px dashed blue",
                            borderRadius: "10px",
                            color: "#111",
                            fontSize: "1rem",
                            textAlign: "center",
                            overflow: "hidden",
                          }}
                        >
                          {values.imagname ? values.imagname : "  Upload Image"}
                        </label>
                        <div className="input-group">
                          <input
                            type="file"
                            className="form-control"
                            name="image"
                            hidden
                            ref={fileRef}
                            onChange={(e) => {
                              setValues({
                                ...values,
                                imagname: e.target.files[0].name,
                                image: e.target.files[0],
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    {/* Socail Media Data */}
                    <div className="row">
                      <div
                        className="card-header"
                        style={{
                          border: "transparent",
                        }}
                      >
                        <h4 className="card-title">Social Media Links</h4>
                      </div>
                      {/* Facebook  */}
                      <div className={`col-6 form-group mb-3`}>
                        <label className="text-label">
                          Facebook Link Acount
                        </label>
                        <div className="input-group">
                          <span className="input-group-text">
                          <i className={`fa-brands fa-facebook`}></i>
                          </span>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter a facebook acount link"
                            name="facebookLink"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.facebookLink}
                          />
                        </div>
                      </div>
                      {/* Instagram  */}
                      <div className={`col-6 form-group mb-3`}>
                        <label className="text-label">
                          Instagram Link Acount
                        </label>
                        <div className="input-group">
                          <span className="input-group-text">
                            <i className="fa-brands fa-instagram"></i>
                          </span>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter a instagram acount link"
                            name="instagramLink"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.instagramLink}
                          />
                        </div>
                      </div>
                      {/* Behaince  */}
                      <div className={`col-6 form-group mb-3`}>
                        <label className="text-label">
                          Behaince Link Acount
                        </label>
                        <div className="input-group">
                          <span className="input-group-text">
                            <i className="fa-brands fa-behance"></i>
                          </span>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter a behaince acount link"
                            name="behainceLink"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.behainceLink}
                          />
                        </div>
                      </div>
                      {/* Linkedin  */}
                      <div className={`col-6 form-group mb-3`}>
                        <label className="text-label">
                          Linkedin Link Acount
                        </label>
                        <div className="input-group">
                          <span className="input-group-text">
                            <i className="fa-brands fa-linkedin"></i>
                          </span>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter a linkedin acount link"
                            name="linkedinLink"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.linkedinLink}
                          />
                        </div>
                      </div>
                    </div>

                    {loading ? (
                      <>
                        <button className="btn me-2 btn-primary" disabled>
                          <CustomSpinner />
                        </button>
                      </>
                    ) : (
                      <>
                        <button type="submit" className="btn me-2 btn-primary">
                          Submit
                        </button>
                      </>
                    )}
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddTeamMemberForm;
