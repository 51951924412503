import React, { useState } from "react";

import { Button, Modal } from "react-bootstrap";
import swal from "sweetalert";
import { Formik } from "formik";
import { useRef } from "react";
import CustomSpinner from "../../Ui/Spinner";
import axios from "axios";
import * as Yup from "yup";
const imageUpdate = Yup.object().shape({
  image: Yup.mixed().required("Please enter a image"),
});

export default function AddHomeSlidersImageModule({
  onFinshed,
  sliderId,
  showNow,
  onHide,
}) {
  const [tempimg, setTempImg] = useState([]);
  const [progress, setProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const fileRef = useRef(null);

  const handleFormSubmite = async (value, { resetForm }) => {
    console.log(value);
    console.log(sliderId);

    setIsUploading(true);
    const sliderDataFormData = new FormData();
    if (value.image) {
      sliderDataFormData.append("image", value.image);
    } else {
      swal("Oops", `please Upload Images`, "error");
      setProgress(0);
      setIsUploading(false);
      return;
    }
    const token = JSON.parse(localStorage.getItem("userData")).token;
    await axios
      .request(
        `${process.env.REACT_APP_PUBLIC_API}/api/home-Slider/updateImage-slider/${sliderId}`,
        {
          method: "POST",
          maxBodyLength: Infinity,
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
          data: sliderDataFormData,
          onUploadProgress: (e) => {
            const uploadedRateData = Math.floor(
              Math.round(100 * e.loaded) / e.total
            );
            setProgress(uploadedRateData);
          },
        }
      )
      .then((result) => {
        setIsUploading(false);
        if (result.data.message === "Data Updated successfully") {
          swal("Done!", result.data.message, "success");
          resetForm();
          setTempImg([]);
          setProgress(0);
          setIsUploading(false);
          onHide();
          onFinshed();
        } else {
          swal("Error", result.data.message, "error");
          setProgress(0);
          setIsUploading(false);
        }
      })
      .catch((error) => {
        swal("Oops", `${error}`, "error");
        setProgress(0);
        setIsUploading(false);
      });
  };

  const openFileUploader = () => {
    fileRef.current.click();
  };

  return (
    <>
      <Modal className="fade bd-example-modal-lg" show={showNow} size="lg">
        <Modal.Header>
          <Modal.Title>Update Services Images </Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => {
              setTempImg([]);
              setProgress(0);
              setIsUploading(false);
              onHide();
            }}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={{
              image: "",
              imagname: "",
            }}
            validationSchema={imageUpdate}
            onSubmit={handleFormSubmite}
          >
            {({
              values,
              errors,
              setValues,
              handleSubmit,
              handleChange,
              handleBlur,
            }) => (
              <form onSubmit={handleSubmit}>
                {/* Image Inputs Data */}
                <div className="row">
                  {/* image  */}
                  <div
                    className={`col-12 form-group mb-3 ${
                      values.image
                        ? errors.image
                          ? "is-invalid"
                          : "is-valid"
                        : ""
                    }`}
                  >
                    <label
                      onClick={openFileUploader}
                      className="text-label"
                      style={{
                        cursor: "pointer",
                        width: "100%",
                        padding: "2rem",
                        backgroundColor: "#1f88d9",
                        opacity: "0.5",
                        border: "3px dashed blue",
                        borderRadius: "10px",
                        color: "#111",
                        fontSize: "1rem",
                        textAlign: "center",
                        overflow: "hidden",
                      }}
                    >
                      {values.imagname ? values.imagname : "  Upload Images"}
                    </label>
                    <div className="input-group">
                      <input
                        accept="image/x-png,image/jpeg,image/jpg"
                        type="file"
                        className="form-control"
                        name="image"
                        hidden
                        ref={fileRef}
                        onChange={(e) => {
                          setTempImg("");
                          setValues({
                            imagname: e.target.files[0].name,
                            image: e.target.files[0],
                          });
                          setTempImg(URL.createObjectURL(e.target.files[0]));
                        }}
                      />
                      <div
                        id="val-image-error"
                        classname="invalid-feedback animated fadeInUp"
                        style={{ display: "block", color: "#dc3545" }}
                      >
                        {errors.image && errors.image}
                      </div>
                    </div>
                  </div>
                  <div className={`col-lg-12 col-sm-12 form-group mb-3`}>
                    {tempimg && (
                      <>
                        <img className="m-1" src={tempimg} width={50} />
                      </>
                    )}
                  </div>
                </div>

                <div className="row">
                  {isUploading && (
                    <>
                      <h5>uploading video now please waite...</h5>

                      <div className="progress-box">
                        <div className="d-flex align-items-center justify-content-between">
                          <h5 className="mb-0 fs-14 font-w600">
                            Uploading Images now.., please don't refresh
                          </h5>
                          <span className="font-w600" id="progress-span">
                            {progress}%
                          </span>
                        </div>
                        <div className="progress ">
                          <div
                            className="progress-bar bg-primary"
                            id="progress-inner-width"
                            style={{
                              width: `${progress}%`,
                              height: "12px",
                              borderRadius: "4px",
                            }}
                            role="progressbar"
                          ></div>
                        </div>
                      </div>
                    </>
                  )}
                </div>

                {isUploading ? (
                  <>
                    <button className="btn me-2 btn-primary" disabled>
                      <CustomSpinner />
                    </button>
                  </>
                ) : (
                  <>
                    <button type="submit" className="btn me-2 btn-primary">
                      Submit
                    </button>
                  </>
                )}
              </form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
}
