import { Fragment } from "react";
import PageTitle from "../../layouts/PageTitle";
import AddHomeSliderForm from "./components/AddHomeSliderForm";

export default function AddHomeSlider() {
  return (
    <>
      <Fragment>
        <PageTitle activeMenu="Add Home Slider" motherMenu="Home Slider" />
        <AddHomeSliderForm />
      </Fragment>
    </>
  );
}
