
import Alert from 'react-bootstrap/Alert';

export default function CustomAlert({type,message}) {
  return (
    <>

      <Alert variant={type}>{message}</Alert>
    </>
  );
}
