import { Fragment } from "react";
import PageTitle from "../../layouts/PageTitle";
import MessageIntershipTable from "./component/MessageIntershipTable";

export default function Intership() {
  return (
    <>
      <Fragment>
        <PageTitle activeMenu="Internship" motherMenu="Forms" />
        <MessageIntershipTable />
      </Fragment>
    </>
  );
}
