import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { checkErrorMessage } from "../../../services/ErrorMessage";

export const getByIdMessageApi = createAsyncThunk(
  "intership/getById",
  async (_id) => {
    const token = JSON.parse(localStorage.getItem("userData")).token;
    const request = await fetch(
      `${process.env.REACT_APP_PUBLIC_API}/api/dashbord/intership/getMessageById/${_id}`,
      {
        method: "GET",
        headers: {
          "Authorization": `Bearer ${token}`
        }
      }
    )
      .then((response) => response.json())
      .then((result) => result)
      .catch((error) => error);

    return request;
  }
);

const getByIdMessageSlicer = createSlice({
  name: "getById",
  initialState: {
    loading: false,
    data: null,
    error: null,
  },
  extraReducers: (builder) => {
    builder.addCase(getByIdMessageApi.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getByIdMessageApi.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload.message === "Data fetched successfully") {
        state.data = action.payload.data;
      } else {
        state.error = checkErrorMessage(action.payload.message);
      }
    });
    builder.addCase(getByIdMessageApi.rejected, (state, action) => {
      state.loading = false;
      state.error = checkErrorMessage(action.payload.message);
    });
  },
});

export default getByIdMessageSlicer.reducer;
